import React, { useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { EquipementType } from '../../../../utils/interface/EquipementType'
import { LogementType } from '../../../../utils/interface/LogementType'
import { QuartierType } from '../../../../utils/interface/QuartierType';
import Select from 'react-select';

import { CKEditor } from 'ckeditor4-react';

// const actions = [
//     { label: "Add", value: 1 },
//     { label: "Edit", value: 2 },
//     { label: "Delete", value: 3 }
// ];
interface Props {
    adress: LogementType | any,
    designation: LogementType | any,
    description: LogementType | any,
    nombrePersonneMax: LogementType | any,
    nombrePieceTotal: LogementType | any,
    quartier: LogementType | any,
    setAdress: any,
    setDesignation: any
    setDescription: any,
    setNombrePersonneMax: any,
    setNombrePieceTotal: any,
    setQuartier: any,
    equipement: LogementType[] | any,
    setEquipement: any,
    quartiers: QuartierType | any,
    equipements: EquipementType | any,
    createLogement: any,
    uploadImage: any,
    image: any,
    loyer: any,
    setLoyer: any,
    contact: any,
    propri: any,
    setContact: any,
    setPropri: any,
    removeImageNew: any
}

export const AddLogement: React.FC<Props> = ({
    adress,
    designation,
    description,
    nombrePersonneMax,
    nombrePieceTotal,
    quartier,
    equipement,
    setAdress,
    setDesignation,
    setDescription,
    setNombrePersonneMax,
    setNombrePieceTotal,
    setQuartier,
    setEquipement,
    quartiers,
    equipements,
    createLogement,
    uploadImage,
    image,
    loyer,
    setLoyer,
    contact,
    propri,
    setContact,
    setPropri,
    removeImageNew
}) => {

    let options: [] | any = [];

    const [eq, setEq] = useState<any[]>([]);

    const handleChange = (event: any) => {
        setEquipement(event.target.value);
    };
    
    equipements.map((data: any) => {
        options.push({value: data.id, label: data.designation});
    });

    const handler = (event: any) => {
        const value = event;
        setEquipement(value);
    }
    
    return (
        <div>
            <Form noValidate>
                        
                        <input type="file" onChange={uploadImage} multiple/>
                        <br/><br />
                        <div className='imgBox_ mb-4'>
                        {
                            image && image.map((img: any) => {
                                return(
                                    
                                    <img onDoubleClick={(e: any) => removeImageNew(e, img && img.name)} key={img.base64} className='_imgS' src={img.base64} alt={img.name} />
                                    
                                )
                            })
                        }
                        </div>
                        
                        <div className="row">
                            <Form.Group className='col-12 col-md-3 mb-4'>
                                <Form.Label>Quartier</Form.Label>
                                
                                <InputGroup hasValidation> 
                                    <Form.Select value={quartier} onChange={(e: any)=> setQuartier(e.target.value)} className="form-control" id="exampleFormControlSelect1">
                                        <option value="">Selectionner une quartier</option>
                                        {
                                            quartiers.map((cart: any) => (
                                                <option key={cart.id} value={cart.id}>{cart.designation}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please choose a email adress.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>

                                    

                            <Form.Group className='col-12 col-md-3 mb-4'>
                                <Form.Label>Adresse</Form.Label>
                                <InputGroup hasValidation>
                                    
                                    <Form.Control
                                        type="text"
                                        placeholder="Adresse du logemente"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        value={adress}
                                        onChange={(e: any) => setAdress(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Erreur.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className='col-12 col-md-3 mb-4'>
                                <Form.Label>Loyer par jour</Form.Label>
                                <InputGroup hasValidation>
                                    
                                    <Form.Control
                                        type="text"
                                        placeholder="Loyer"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        value={loyer}
                                        onChange={(e: any) => setLoyer(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Erreur.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group className='col-12 col-md-3 mb-4'>
                                <Form.Label>Designation</Form.Label>
                                <InputGroup hasValidation>
                                    
                                    <Form.Control
                                        type="text"
                                        placeholder="Designation"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        value={designation}
                                        onChange={(e: any) => setDesignation(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Erreur.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </div>

                        <div className="row">
                            <Form.Group className='col-12 col-md-3 mb-4'>
                                <Form.Label>Nombre pièce</Form.Label>
                                <InputGroup hasValidation>
                                    
                                    <Form.Control
                                        type="number"
                                        placeholder="Nombre des piècese"
                                        aria-describedby="inputGroupPrepend"
                                        value={nombrePieceTotal}
                                        onChange={(e: any) => setNombrePieceTotal(e.target.value)}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Erreur.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group className='col-12 col-md-3 mb-4'>
                                <Form.Label>Nombre personne</Form.Label>
                                <InputGroup hasValidation>
                                    
                                    <Form.Control
                                        type="number"
                                        placeholder="Nombre des personnes"
                                        aria-describedby="inputGroupPrepend"
                                        value={nombrePersonneMax}
                                        onChange={(e: any) => setNombrePersonneMax(e.target.value)}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Erreur.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className='col-12 col-md-3 mb-4'>
                                <Form.Label>Nom du proprietaire</Form.Label>
                                <InputGroup hasValidation>
                                    
                                    <Form.Control
                                        type="text"
                                        placeholder="Nom du proprietaire"
                                        aria-describedby="inputGroupPrepend"
                                        value={propri}
                                        onChange={(e: any) => setPropri(e.target.value)}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Erreur.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group className='col-12 col-md-3 mb-4'>
                                <Form.Label>Contact du proprietaire</Form.Label>
                                <InputGroup hasValidation>
                                    
                                    <Form.Control
                                        type="text"
                                        placeholder="Contact du proprietaire"
                                        aria-describedby="inputGroupPrepend"
                                        value={contact}
                                        onChange={(e: any) => setContact(e.target.value)}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Erreur.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </div>

                        <Form.Group className='col-12 mb-4'>
                            <Form.Label>Equipement</Form.Label>
                            
                            <Select isMulti options={ options } value={equipement}  onChange={handler} />
                        </Form.Group>

                        <div className="row px-2">
                            <div className="mb-4">
                                <Form.Label>Liste des equipements</Form.Label>
                                <div className="listEq">
                                    {
                                        eq && eq.map((it, i) => (
                                            <label key={i} htmlFor="">{it}</label>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <Form.Group className='col-12 col-md-12 mb-4'>
                                <Form.Label>Description</Form.Label>
                                <CKEditor
                                    // editor={ClassicEditor}
                                    data={description}
                                    onChange={(ev: any) => {
                                        const data = ev.editor.getData();
                                        setDescription(data);
                                    }}
                                    // onChange={(event: any, editor: any) => {
                                    //     const data = editor.getData();
                                    //     setContenu(data);
                                    // }}
                                />
                                {/* <InputGroup hasValidation>
                                    
                                    <Form.Control
                                        as="textarea" rows={4}
                                        placeholder="Description"
                                        aria-describedby="inputGroupPrepend"
                                        value={description}
                                        onChange={(e: any) => setDescription(e.target.value)}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Erreur.
                                    </Form.Control.Feedback>
                                </InputGroup> */}
                            </Form.Group>
                        </div>
                        

                        

                       

                        <div className="row">
                            
                        </div>
                        
                    </Form>
        </div>
    )
}

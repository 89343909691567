import React from 'react'
import { DeleteComfirmation } from '../../../DeleteComfirmation/DeleteComfirmation'

export const ListClients: React.FC = () => {

    const deleteArticle = () => {
    }
  return (
    <section className="content">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 mb-4">
                    <div className="d-md-flex justify-content-between">
                        <div className="input-group input-group-lg _inputForm w-25">
                            <input type="text" name="table_search" className="form-control float-right input-lg _inputSearch" placeholder="Search" />

                            <div className="input-group-append">
                                <button type="submit" className="btn btn-default">
                                    <i className="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <div className="modal fade" id="modal-del">
                            <div className="modal-dialog modal-del">
                                <DeleteComfirmation actionOK={deleteArticle}/>
                            </div>
                        </div>
                        {/* <button className="btn btn-primary btn-lg float-left _btnAdd">
                            <i className='fas fa-plus pr-2'></i>
                            Ajouter article
                        </button> */}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        
                        <div className="card-body table-responsive p-0">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">ID</th>
                                        <th scope="col">Client</th>
                                        <th scope="col">Adresse</th>
                                        <th scope="col">Numero cate bancaire</th>
                                        <th scope="col">Date de paiement</th>
                                        {/* <th scope="col">Status</th> */}
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">L1</th>
                                        <td>Clet 1</td>
                                        <td>Lot IIA154</td>
                                        <td>424242 424242 424242 42</td>
                                        <td>02/08/2022</td>
                                        {/* <td>En cours</td> */}
                                        <td>
                                            <div>
                                                <button type="button" className="btn btn-sm" data-toggle="modal" data-target="#modal-del" data-placement="top" title="Supprimer">
                                                    <i className='fas fa-trash text-danger'></i>
                                                </button>
                                                <button type="button" className="btn btn-sm" data-toggle="tooltip" data-placement="top" title="Modifier">
                                                    <i className='fas fa-edit text-success'></i>
                                                </button>
                                                <button type="button" className="btn btn-sm" data-toggle="tooltip" data-placement="top" title="Consulter">
                                                    <i className='fas fa-eye text-primary'></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">L2</th>
                                        <td>Clet 1</td>
                                        <td>Lot IIA154</td>
                                        <td>424242 424242 424242 42</td>
                                        <td>02/08/2022</td>
                                        {/* <td>En cours</td> */}
                                        <td>
                                            <div>
                                                <button className="btn btn-sm" data-toggle="modal" data-target="#modal-del" data-placement="top" title="Supprimer">
                                                    <i className='fas fa-trash text-danger'></i>
                                                </button>
                                                <button className="btn btn-sm" data-toggle="tooltip" data-placement="top" title="Modifier">
                                                    <i className='fas fa-edit text-success'></i>
                                                </button>
                                                <button className="btn btn-sm" data-toggle="tooltip" data-placement="top" title="Consulter">
                                                    <i className='fas fa-eye text-primary'></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">L3</th>
                                        <td>Clet 1</td>
                                        <td>Lot IIA154</td>
                                        <td>424242 424242 424242 42</td>
                                        <td>02/08/2022</td>
                                        {/* <td>En cours</td> */}
                                        <td>
                                            <div>
                                                <button className="btn btn-sm" data-toggle="modal" data-target="#modal-del" data-placement="top" title="Supprimer">
                                                    <i className='fas fa-trash text-danger'></i>
                                                </button>
                                                <button className="btn btn-sm" data-toggle="tooltip" data-placement="top" title="Modifier">
                                                    <i className='fas fa-edit text-success'></i>
                                                </button>
                                                <button className="btn btn-sm" data-toggle="tooltip" data-placement="top" title="Consulter">
                                                    <i className='fas fa-eye text-primary'></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { DetailLogementCompo } from '../../Components/LogementComponent/DetailLogementCompo';
import { LogementContext } from '../../Context/AppContext';
import { baseUrl } from '../../services/Services';
import { Authorization } from '../../utils/headers/Authorization';
import { LogementType } from '../../utils/interface/LogementType';

export const DetailsLogement = () => {

  const [logement, setLogement] = useState<LogementType[] | any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const {id} = useParams<any>();

  useEffect(() => {
    setLoading(true);
    const getAllLogements = async() => await axios.get(`${baseUrl}api/logement/${id}`, {headers: Authorization})
      .then((response: any) => {

        setLoading(false);
        setLogement(response.data.data.rows);

    }).catch((error: any) => {

      setLoading(false);
      console.log(error);

    });
    getAllLogements()
  }, []);

  console.log(logement)
  return (
    <LogementContext.Provider value={logement}>
      <DetailLogementCompo/>
    </LogementContext.Provider>
  )
}

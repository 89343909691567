import React, { useContext } from 'react'
import { ArticleContext } from '../../Context/AppContext';
import { ArticleType } from '../../utils/interface/ArticleType';
import ReactLoading from 'react-loading';
import { ArticleDetailCompo } from './DetailComponent/ArticleDetailCompo'
interface Props {
    loading: boolean
}
export const DetailsArticleComponent: React.FC<Props> = ({loading})=> {
    const article: ArticleType | any = useContext(ArticleContext);
    return (
        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Details de l'article <b>{article && article.titre}</b></h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="index.html">Articles</a></li>
                                    <li className="breadcrumb-item active">Article v1</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    loading ? (
                        <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0}/>
                    ):(
                        <ArticleDetailCompo article={article}/>
                    )
                }
            </div>
        </div>
    )
}

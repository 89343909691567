import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { EquipementType } from '../../../../utils/interface/EquipementType'
interface Props {
    designation: EquipementType | any,
    description: EquipementType | any,
    setDesignation: any,
    setDescription: any,
    createEquipement: any
    setLoading: any
}
export const AddEquipement:React.FC<Props> = ({
    designation,
    description,
    setDesignation,
    setDescription,
    createEquipement,
    setLoading
}) => {
  return (
    <div>
        <div className="modal-content">
            <div className="modal-header">
                <h4 className="modal-title">Ajouter equipement</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Form noValidate>
                        
                    <Form.Group controlId="validationCustomUsername" className='mb-4'>
                        <Form.Label>Designation</Form.Label>
                        <InputGroup hasValidation>
                            
                            <Form.Control
                                type="text"
                                placeholder="Designation"
                                aria-describedby="inputGroupPrepend"
                                required
                                value={designation}
                                onChange={(e: any) => setDesignation(e.target.value)}
                            />
                            {/* <InputGroup.Text id="inputGroupPrepend">
                                <FaMailBulk/>
                            </InputGroup.Text> */}
                            <Form.Control.Feedback type="invalid">
                                Please choose a email adress.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="validationCustomUsername" className='mb-4'>
                        <Form.Label>Description</Form.Label>
                        <InputGroup hasValidation>
                            
                            <Form.Control
                                type="text"
                                placeholder="Description"
                                aria-describedby="inputGroupPrepend"
                                required
                                value={description}
                                onChange={(e: any) => setDescription(e.target.value)}
                            />
                            {/* <InputGroup.Text id="inputGroupPrepend">
                                <FaKey/>
                            </InputGroup.Text> */}
                            <Form.Control.Feedback type="invalid">
                                Please choose a email adress.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Form>
            </div>
            <div className="modal-footer justify-content-between">
                <button type="button" className="btn btn-default" data-dismiss="modal">Fermer</button>
                <button type="button" className="btn btn-primary" onClick={createEquipement}>Enregistrer</button>
            </div>
        </div>
    </div>
  )
}

import axios from 'axios'
import React, { useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { baseUrl } from '../../../../services/Services'
import { VilleType } from '../../../../utils/interface/LogementType'
interface Props {
    designation: string | any,
    vil: string | any,
    setDesignation: any
    villes: VilleType | any,
    allVille: VilleType | any,
    villeId: number | any,
    setVil: any
}
export const EditQuartier: React.FC<Props> = ({
    designation,
    vil,
    setDesignation,
    villes,
    allVille,
    villeId,
    setVil
}) => {

    const [ville, setVille] = useState<number | any>();

    const handleChange = (event: any) => {
        setVille(event.target.value);
    };

    const updateQuartier = async(e: any) => {
        e.preventDefault();
        
        console.log(designation, villeId, vil);
        await axios.put(`${baseUrl}api/quartier/${villes.id}`, {
            designation: designation,
            ville: parseInt(ville) ? parseInt(ville) : villeId
        }).then((response) => {
            // console.log(response);
            window.location.reload();
        }).catch((error) => {
            console.log(error);
        })
    }

    const items = allVille && allVille.filter((item: any) => {
        console.log(item)
        return (item.id !== parseInt(villeId));
    });
    console.log(items)
  return (
    <div>
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Modification du quartier</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Form noValidate>
                            
                        <Form.Group controlId="validationCustomUsername" className='mb-4'>
                            <Form.Label>Quartier</Form.Label>
                            <InputGroup hasValidation>
                                
                                <Form.Control
                                    type="text"
                                    placeholder="Quartier"
                                    aria-describedby="inputGroupPrepend"
                                    value={designation}
                                    onChange={(e)=>setDesignation(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please choose a email adress.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="validationCustomUsername" className='mb-4'>
                            <Form.Label>Ville</Form.Label>
                            <InputGroup hasValidation>
                                
                                <Form.Select multiple={false} className="form-control" id="exampleFormControlSelect1" value={ville} onChange={handleChange}>
                                    <option defaultValue={vil} value={villeId} selected >{vil}</option>
                                    {
                                        items && items.map((ville: any) => (
                                            <option key={ville.id} value={ville.id}>{ville.designation}</option>
                                        ))
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please choose a email adress.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Form>
                </div>
                <div className="modal-footer justify-content-between">
                    <button type="button" className="btn btn-default" data-dismiss="modal">Fermer</button>
                    <button type="button" className="btn btn-primary" onClick={(e) => updateQuartier(e)}>Enregistrer</button>
                </div>
            </div>
        </div>
  )
}

import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { baseUrl } from '../../services/Services';
import { Authorization } from '../../utils/headers/Authorization';
import { ListTextParam } from './Components/ListTextParam'
interface Props {

}
export const ParametrageTextComponent: React.FC<Props> =() => {

    const [allText, setAllText] = useState<any>([]);


    const [loading, setLoading] = useState<boolean>(false);

    

    useEffect(()=> {
        setLoading(true);

        const getAllText = async() => axios.get(`${baseUrl}api/parametre-texte`, {
            headers: Authorization
        }).then((response: any) => {

            setLoading(false);

            setAllText(response.data.data.rows);
            

        }).catch((error: any) => {

            setLoading(false);
            console.log(error);

        });

        getAllText();

        // const interval = setInterval(()=>{
        //     getAllText()
        // },500);
       
       
        // return()=>clearInterval(interval);
    }, []);

    return (
        <div className="content-wrapper">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                />
                {/* Same as */}
            <ToastContainer />
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Parametrage de text</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="index.html">Parametre text</a></li>
                                <li className="breadcrumb-item active">v1</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <ListTextParam allText={allText} loading={loading} setLoading = {setLoading}  />
        </div>
    )
}

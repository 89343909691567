import React from 'react'
import { VilleType } from '../../utils/interface/LogementType'
import { ListVile } from './Components/ListVille/ListVile'
interface Props {
    ville: VilleType,
    createVille: any,
    setVille: any,
    loading: boolean | any,
    searchText: any,
    handleChange: any,
    setVillePerPage: any,
    villePerPage: any,
    totalPage: any,
    paginate: any,
}
export const VilleComponent:React.FC<Props> = ({
    ville,
    createVille,
    loading,
    setVille,
    searchText,
    handleChange,
    setVillePerPage,
    villePerPage,
    totalPage,
    paginate,
}) => {
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Ville</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="index.html">Ville</a></li>
                                <li className="breadcrumb-item active">Ville v1</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <ListVile
                ville={ville}
                createVille={createVille}
                loading={loading}
                setVille={setVille}
                searchText={searchText}
                handleChange={handleChange}
                setVillePerPage={setVillePerPage}
                villePerPage={villePerPage}
                totalPage={totalPage}
                paginate={paginate}
            />
        </div>
    )
}

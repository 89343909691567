import React from 'react'
import { HistoriquePComponent } from '../../Components/HistoriquePComponent/HistoriquePComponent'

export const HistoriquePPage = () => {
  return (
    <div>
        <HistoriquePComponent/>
    </div>
  )
}

import React, { useContext, useState } from 'react'
import { VilleContext } from '../../../../Context/AppContext'
import { VilleType } from '../../../../utils/interface/LogementType'
import { DeleteComfirmation } from '../../../DeleteComfirmation/DeleteComfirmation'
import { Paginations } from '../../../pagination/Paginations'
import { AddVille } from '../AddVille/AddVille'

import ReactLoading from 'react-loading';
import axios from 'axios'
import { Authorization } from '../../../../utils/headers/Authorization'
import { baseUrl } from '../../../../services/Services'
import { UpdateVille } from '../UpdateVille/UpdateVille'
import { Modal } from 'react-bootstrap'
interface Props {
    ville: VilleType,
    createVille: any,
    setVille: any,
    loading: any,
    searchText: any,
    handleChange: any,
    setVillePerPage: any,
    villePerPage: any,
    totalPage: any,
    paginate: any,
}
export const ListVile: React.FC<Props> = ({
    ville,
    createVille,
    loading,
    setVille,
    searchText,
    handleChange,
    setVillePerPage,
    villePerPage,
    totalPage,
    paginate,
}) => {
    const allVille = useContext(VilleContext);
    const [villes, setVilles] = useState<VilleType | any>([]);
    const [vil, setVil] = useState<string | any>("");
    const [item, setItem] = useState<VilleType | any>([]);
    const [vilId, setVilId] = useState<VilleType | any>("");

    const [showConfirmDel, setShowComfirmDel] = useState<boolean>(false);
    const [delVille, setDelVille] = useState<any>([]);


    const handleShowDel = (data: any) => {
        setShowComfirmDel(true);
        setDelVille(data);
    }
    const handleCloseDel = () => setShowComfirmDel(false);

    console.log(allVille);

    const getVille = (id: number) => {
        axios.get(`${baseUrl}api/ville/${id}`,{
          headers: Authorization
        }).then((response) => {
            setItem(response.data.data.rows);
            setVil(response.data.data.rows.designation);
            setVilId(response.data.data.rows.id)
        });
    }

    console.log(item)

    const deleteArticle = async(e: any) => {
        e.preventDefault();
        await axios.put(`${baseUrl}api/ville/${delVille.id}`, {
            status: 2
        })
        .then((response: any) => {
            console.log(response);
            window.location.reload();
        })
        .catch((error: any) => {
            console.log(error)
        });
    }

    return (
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-4">
                        <div className="d-md-flex justify-content-between">
                            <div className="input-group input-group-lg _inputForm w-25">
                                <input type="text" value={searchText} onChange={handleChange} name="table_search" className="form-control float-right input-lg _inputSearch" placeholder="Search" />

                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-default">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>

                            <button className="btn btn-primary btn-lg float-left _btnAdd" data-toggle="modal" data-target="#modal-lg">
                                <i className='fas fa-plus pr-2'></i>
                                Ajouter Ville
                            </button>
                            <div className="modal fade" id="modal-lg">
                                <div className="modal-dialog modal-lg">
                                    <AddVille ville={ville} createVille={createVille} setVille={setVille}/>
                                </div>
                            </div>
                            <div className="modal fade" id="modal-update">
                                <div className="modal-dialog modal-update">
                                    <UpdateVille setVille={setVil} ville={vil} item={item}/>
                                </div>
                            </div>
                            <div className="modal fade" id="modal-del">
                                <div className="modal-dialog modal-del">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    loading ? (
                        <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0} />
                    ) : (
                        <div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="d-flex col-md-6">
                                                <label htmlFor="">Afficher par</label>
                                                <select id="inputState" onChange={(e) => setVillePerPage(e.target.value)} className="form-control w-auto ml-2">
                                                    <option value="10">10</option>
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                    <option value="75">75</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="card-body table-responsive p-0" style={{height: "63vh"}}>
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">ID</th>
                                                        <th scope="col">Ville</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                {allVille ? (
                                                    <>
                                                        {
                                                            allVille.map((ville: VilleType) => (
                                                                <tbody key={ville.id}>
                                                                    <tr>
                                                                        <th scope="row">{ville.id}</th>
                                                                        <td>{ville.designation}</td>
                                                                        <td>
                                                                            {
                                                                                ville.status && ville.status.designation === "active" ? (
                                                                                    <span className="badge badge-success">
                                                                                       {ville.status && ville.status.designation}
                                                                                    </span>
                                                                                ):(
                                                                                    <span className="badge badge-warning">
                                                                                        {ville.status && ville.status.designation}
                                                                                    </span>
                                                                                )
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <div>
                                                                                {
                                                                                    ville.status && ville.status.designation === "active" && (
                                                                                        <button type="button" onClick={() => getVille(ville.id)} data-toggle="modal" data-target="#modal-update" className="btn btn-sm" data-placement="top" title="Modifier">
                                                                                            <i className='fas fa-edit text-success'></i>
                                                                                        </button>
                                                                                    )
                                                                                }
                                                                                <button type="button" className="btn btn-sm" onClick={() => handleShowDel(ville)} data-placement="top" title="Supprimer">
                                                                                    <i className='fas fa-trash text-danger'></i>
                                                                                </button>
                                                                                
                                                                                {/* <button type="button" className="btn btn-sm" data-toggle="tooltip" data-placement="top" title="Consulter">
                                                                                    <i className='fas fa-eye text-primary'></i>
                                                                                </button> */}
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                </tbody>
                                                            ))
                                                        }
                                                    </>
                                                ) : (
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-danger">
                                                                Vide
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )}
                                            </table>
                                        </div>
                                        <div className="card-footer">
                                            <div className="float-right">
                                                <Paginations
                                                    postPerPage={villePerPage}
                                                    totalPages={totalPage.length}
                                                    paginate={paginate}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )
                }
            </div>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showConfirmDel}
                onHide={handleCloseDel}
            >
                <DeleteComfirmation actionOK={deleteArticle} />
            </Modal>

        </section>
    )
}

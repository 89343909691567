import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface Props{
  user: any,
}
const ProtectedRoute = ({ user }: Props) =>{
  if(user.data.token) {
    return <Outlet />
  }
  return <Navigate to="/login" />
}

export default ProtectedRoute;
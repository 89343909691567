import moment from 'moment';
import React, { useContext } from 'react'
import { ArticleContext } from '../../../Context/AppContext'
import { ArticleType } from '../../../utils/interface/ArticleType'
import parse from 'html-react-parser';

interface Props {
    article: ArticleType | any,
}
export const ArticleDetailCompo: React.FC<Props> = ({article})=> {

    let dn = moment(article && article.createdAt).format('YYYY-MM-DD, h:mm:ss');
    

    return (
        <section className="content">
            
            <div className="container-fluid">
                <div className='row'>
                    <div className="col-12 col-md-6">
                        <div className="card"   style={{height: "83vh"}}>
                            <div className="card-header">
                                <h4>Contenu de l'article: <b>{article && article.titre}</b></h4>
                            </div>
                            <div className="card-body table-responsive p-2">
                                {parse(`${article.contentHtml}`)}
                                
                                <table style={{width:"100%", border: "1px solid black", borderCollapse: "collapse"}}>
                                    <tr>
                                        <th style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>ID:</th>
                                        <td style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>{article.id}</td>
                                    </tr>
                                    <tr>
                                        <th style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>Categorie:</th>
                                        <td style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>{article.categorie ? article.categorie.designation : "_"}</td>
                                    </tr>

                                    {/* <tr>
                                        <th style={{padding: "5px", textAlign: "left", border: "1px solid black"}}></th>
                                        <td style={{padding: "5px", textAlign: "left", border: "1px solid black"}}> {parse(article.contentHtml)} </td>
                                    </tr> */}

                                    <tr>
                                        <th style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>Date de creation:</th>
                                        <td style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>{moment(article && article.createdAt).format('YYYY-MM-DD à hh:mm:ss')}</td>
                                    </tr>
                                    <tr>
                                        <th style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>Status:</th>
                                        <td style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>{article.status ? article.status.designation : '_'}</td>
                                    </tr>
                                </table>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className='w-auto'>
                            <div className="card"   style={{height: "83vh"}}>
                                <div className="card-header">
                                    <h4>Les images de l'article</h4>
                                </div>
                                <div className="card-body">
                                    <div className='imgBox_ mb-4'>
                                    {
                                        article.files && article.files.length > 0 ? (
                                            article.files.map((img: any) => {
                                                return(
    
                                                    <figure key={img.id}>
                                                        <img className='_imgS' style={{margin: "0.5rem"}} src={img.path} alt={img.name} />
                                                        <figcaption>{img.name}</figcaption>
                                                    </figure>
                                                    
                                                )
                                            })
                                        ):(
                                            <div>
                                                <h4>Pas d'image</h4>
                                            </div>
                                        )
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { CategorieComponent } from '../../Components/CategorieComponent/CategorieComponent'
import { CategoryContext } from '../../Context/AppContext'
import { baseUrl } from '../../services/Services'
import { Authorization } from '../../utils/headers/Authorization'
import { CategoryType } from '../../utils/interface/CategoryTYpe'

export const CategoriePages: React.FC = () => {

  const [categories, setCategories] = useState<CategoryType[] | any>([]);
  const [loading, setLoading] = useState<boolean | any>(false);
  useEffect(() => {
    const getAllCategory = async() => {
      setLoading(true)
      await axios.get(`${baseUrl}api/categorie`, {headers: Authorization}).then((response) => {

        setLoading(false)
        setCategories(response.data.data.rows);

      }).catch((error) => {
        setLoading(false)
        console.log(error);

      })
    }

    getAllCategory();
  }, [])
  return (
    <CategoryContext.Provider value={categories}>
        <CategorieComponent loading={loading} setLoading={setLoading} />
    </CategoryContext.Provider>
  )
}

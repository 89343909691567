import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ArticleComponent } from '../../Components/ArticleComponent/ArticleComponent'
import { ArticleContext } from '../../Context/AppContext';
import { baseUrl } from '../../services/Services';
import { Authorization } from '../../utils/headers/Authorization';
import { ArticleType } from '../../utils/interface/ArticleType';
interface Props {}
export const ArticlePages: React.FC<Props> = ()=> {

  const [contenu, setContenu] = useState<ArticleType | any>("");
  const [categorie, setCategorie] = useState<ArticleType | any>();
  const [image, setImage] = useState<ArticleType | any>([]);
  const [title, setTitle] = useState<ArticleType | any>("");


  const [loading, setLoading] = useState<boolean | any>(false);

  const [articles, setArticles] = useState<ArticleType[] | any>([]);

  const [listImge, setListImg] = useState<any>([]);

  useEffect(() => {
    setLoading(true);
    const getAllArticle = async() => await axios.get(`${baseUrl}api/article`, {headers: Authorization})
      .then((response: any) => {

        setLoading(false);
        setArticles(response.data.data.rows);

    }).catch((error: any) => {

      setLoading(false);
      console.log(error);

    });
    getAllArticle();
    setListImg(image);
  }, [image])

  const createArticle = (e: any) => {
    e.preventDefault();

    setLoading(true);

    axios.post(`${baseUrl}api/article/create`, {
      contentHtml: contenu,
      title: title,
      categorie: parseInt(categorie),
      files: image,
    }, {headers: Authorization}).then((response: any) => {

      setLoading(false);
      console.log(response);
      window.location.reload();
      setContenu("");

    }).catch((error: any) => {

      setLoading(false);
      console.log("Message: ", error);

    });

  }


  const fileToDataUri = (image: any) => {
    return new Promise((res) => {
      const reader = new FileReader();
      const {type, name, size} = image;
      reader.addEventListener('load', () => {
          res({
              base64: reader.result,
              name: name,
              type,
              size: size,
          })
      });
      reader.readAsDataURL(image);
      // reader.onload = () => {
      //   resolve(reader.result);
      // };

      // reader.onerror = (error) => {
      //     reject(error);
      // };
    })
  }

  const uploadImage = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
        const newImagesPromises = []
        for (let i = 0; i < e.target.files.length; i++) {
            newImagesPromises.push(fileToDataUri(e.target.files[i]))
        }
        const newImages = await Promise.all(newImagesPromises)
        setImage([...image, ...newImages])
    }
    e.target.value = "";
  }

  const handleRemoveImgClick = () => {
    // setFile((file) => file.slice(1));
  };

  const removeImageNew = (e: any, name: any) => {
    e.preventDefault();
    console.log(name)
    const newImg = listImge.filter(
      (data: any) => data.name !== name
    );
    setListImg(newImg);
  };

console.log("LIST NEW IMG", listImge)


  return (
    <ArticleContext.Provider value={articles}>
        <ArticleComponent
          contenu={contenu}
          setContenu={setContenu}
          loading={loading}
          setLoading={setLoading}
          createArticle={createArticle}
          categorie={categorie}
          setCategorie={setCategorie}
          uploadImage={uploadImage}
          title={title}
          setTitle={setTitle}
          file={listImge}
          removeImageNew={removeImageNew}
        />
    </ArticleContext.Provider>
  )
}

import React from 'react'
import { ClientComponent } from '../../Components/ClientComponent/ClientComponent'
interface Props {}
export const ClientPages: React.FC<Props> = () => {
  return (
    <div>
        <ClientComponent/>
    </div>
  )
}

import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { VilleType } from '../../../../utils/interface/LogementType'
import { QuartierType } from '../../../../utils/interface/QuartierType'
interface Props {
    designation: QuartierType | any,
    setDesignation: any,
    ville: QuartierType | any,
    setVille: any,
    createQuartier: any,
    allVille: VilleType | any
}
export const AddQuartier: React.FC<Props> = ({
    designation,
    setDesignation,
    ville,
    setVille,
    createQuartier,
    allVille
}) => {
    console.log(allVille)
    return (
        <div>
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Ajouter quartier</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Form noValidate>
                            
                        <Form.Group controlId="validationCustomUsername" className='mb-4'>
                            <Form.Label>Quartier</Form.Label>
                            <InputGroup hasValidation>
                                
                                <Form.Control
                                    type="text"
                                    placeholder="Quartier"
                                    aria-describedby="inputGroupPrepend"
                                    value={designation}
                                    onChange={(e)=>setDesignation(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please choose a email adress.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="validationCustomUsername" className='mb-4'>
                            <Form.Label>Ville</Form.Label>
                            <InputGroup hasValidation>
                                
                                <Form.Select multiple={false} className="form-control" id="exampleFormControlSelect1" value={ville} onChange={(e: any)=> setVille(e.target.value)}>
                                    <option value="">Selectionner une ville</option>
                                    {
                                        allVille.map((ville: any) => (
                                            <option key={ville.id} value={ville.id}>{ville.designation}</option>
                                        ))
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please choose a email adress.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Form>
                </div>
                <div className="modal-footer justify-content-between">
                    <button type="button" className="btn btn-default" data-dismiss="modal">Fermer</button>
                    <button type="button" className="btn btn-primary" onClick={createQuartier}>Enregistrer</button>
                </div>
            </div>
        </div>
    )
}

import React from 'react'
import { ArticleType } from '../../utils/interface/ArticleType'
import { ListeArticle } from './Components/ListsArticles/ListeArticle'
interface Props {
    contenu: ArticleType | any,
    setContenu: any,
    loading: boolean | any,
    setLoading: any,
    createArticle: any,
    categorie: ArticleType | any,
    setCategorie: any,
    uploadImage: any,
    file: any,
    title: any,
    setTitle: any,
    removeImageNew: any
}
export const ArticleComponent: React.FC<Props> = ({
    contenu,
    loading,
    setLoading,
    createArticle,
    setContenu,
    categorie,
    setCategorie,
    uploadImage,
    file,
    title,
    setTitle,
    removeImageNew
}) => {
  return (
    <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Articles</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="index.html">Articles</a></li>
                            <li className="breadcrumb-item active">Article v1</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <ListeArticle
            contenu={contenu}
            setContenu={setContenu}
            loading={loading}
            setLoading={setLoading}
            createArticle={createArticle}
            categorie={categorie}
            setCategorie={setCategorie}
            uploadImage={uploadImage}
            file={file}
            title={title}
            setTitle={setTitle}
            removeImageNew={removeImageNew}
        />
    </div>
  )
}

import React from 'react'
import { ListClients } from './Components/ListClients/ListClients'
interface Props {}
export const ClientComponent: React.FC<Props> = () => {
  return (
    <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Clients</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="index.html">Clients</a></li>
                            <li className="breadcrumb-item active">Cliet v1</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <ListClients/>
    </div>
  )
}

import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { DetailsArticleComponent } from '../../Components/ArticleComponent/DetailsArticleComponent'
import { ArticleContext } from '../../Context/AppContext';
import { baseUrl } from '../../services/Services';
import { Authorization } from '../../utils/headers/Authorization';
import { ArticleType } from '../../utils/interface/ArticleType';

export default function DetailArticle() {

  const [article, setArticle] = useState<ArticleType[] | any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const {id} = useParams<any>();

  useEffect(() => {
    setLoading(true);
    const getAllArticle = async() => await axios.get(`${baseUrl}api/article/${id}`, {headers: Authorization})
      .then((response: any) => {

        setLoading(false);
        setArticle(response.data.data.rows);

    }).catch((error: any) => {

      setLoading(false);
      console.log(error);

    });
    getAllArticle()
  }, [])

  return (
    <ArticleContext.Provider value={article}>
      <DetailsArticleComponent loading={loading}/>
    </ArticleContext.Provider>
  )
}

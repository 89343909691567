import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ReactLoading from 'react-loading';
import { baseUrl } from '../../services/Services';
import { Authorization } from '../../utils/headers/Authorization';
import { DetailRes } from './Components/DetailRes';


interface Props {

}
export const DetailReservationComponent: React.FC<Props> = ({ }) => {
    const {id} = useParams<any>();
    console.log("ID", id);
    const [reservs, setReservs] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        getDetailReservation();
    }, [id])

    const getDetailReservation = async () => {
        setLoading(true)
        try {
            await axios.get(`${baseUrl}api/detail/paiement/${id}`, { headers: Authorization }).then((response: any) => {
                setReservs(response.data.data.rows);
                setLoading(false);
            })
        } catch {
            console.log("Erreur");
            setLoading(false)
        }
    }

    console.log("RESSSSS", reservs)
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Détail du reservation</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="index.html">Reservation</a></li>
                                <li className="breadcrumb-item active">Reservation v1</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            {
                loading ? (
                    <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0}/>
                ): (
                    <div>
                       <DetailRes itemRes={reservs}/>   
                    </div>  
                )
            }
        </div>
    )
}

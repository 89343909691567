import axios from 'axios';
import moment from 'moment'
import React, { useState } from 'react'
import { baseUrl } from '../../../../services/Services';
import { Modal } from 'react-bootstrap';

export default function ListResManuel({reservetions}: any) {
    console.log("RES MAN", reservetions)

    const [showConfirmUp, setShowComfirmUp] = useState<boolean>(false);
    const [resDel, setResDel] = useState<any>(null);

    const [loadingUp, setLoadingUp] = useState<any>(false);


    const handleCloseUp = () => setShowComfirmUp(false);

    const handleShowUp = (data: any) => {
        setShowComfirmUp(true);
        setResDel(data);
    }


    const deleteRes = (e: any) => {
        setLoadingUp(true)
        axios.delete(`${baseUrl}api/reservation/logement/${resDel.id}`).then((res) => {
            if(res){
                setLoadingUp(false)
                console.log("OK")
                window.location.reload()
            }
        }).catch(err => {
            setLoadingUp(false)
            setShowComfirmUp(false)
            console.log("Erreur")
        })
    }

  return (
    <>
        <div className="row">
            <div className="col-12">
                <div className="card">
                    
                    <div className="card-body table-responsive p-0">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    
                                    <th scope="col">Date du réservation</th>
                                    <th scope='col'>Date debut</th>
                                    <th scope="col">Date fin</th>
                                    {/* <th scope='col'>Prix Total</th>
                                    <th scope="col">Logement</th> */}
                                    <th scope="col">Adresse</th>
                                    <th scope="col">Nombre de piece</th>
                                    <th scope="col">Nombre personne</th>
                                    <th scope="col">Nom du client</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                    reservetions && reservetions.length ? (
                                        reservetions.map((res: any) => (
                                                    
                                            <tr key={res.id}>
                                                
                                                <td>{moment(res && res.date).format('YYYY-MM-DD')}</td>
                                                <td>{ res.dateDebut ? moment(res && res.dateDebut).format('YYYY-MM-DD') : '--'}</td>
                                                <td>{ res.dateFin ? moment(res.dateFin && res.dateFin).format('YYYY-MM-DD') : '--'}</td>
                                                <td>{res.logement ? res.logement.adresse : '_'}</td>
                                                <td>{res.logement ? res.logement.nombrePieceTotal : '_'}</td>
                                                <td>{res.logement ? res.logement.nombrePersonneMax : "_"}</td>
                                                <td>{res.logement ? res.logement.nom : "_"}</td>
                                                
                                                <td>
                                                    <button onClick={()=>handleShowUp(res)} type="button" className="btn btn-sm" data-placement="top" title={`Annulée le paiement ${res.payementIntent}`}>
                                                        <i className='fas fa-trash text-danger'></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            
                                        
                                    ))
                                    ):(
                                        <tr>
                                            <td style={{textAlign: "center"}} colSpan={10}>Pas de donnée</td>
                                        </tr>
                                    ) 
                                }
                            </tbody>
                        </table>
                        </div>
                            <div className="card-footer">
                            <div className="float-right">
                                {/* <Paginations
                                    postPerPage={reservationPerPage} 
                                    totalPages={reservationsLists.length}
                                    paginate={paginate}
                                /> */}
                            </div>
                        </div>
                </div>
            </div>
        </div>

        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showConfirmUp}
            onHide={handleCloseUp}
        >
            <div>
                <Modal.Header closeButton>
                    <Modal.Title>Comfirmation de suppression</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Voulez vous vraiment supprimer la reservation du logement? </h4>
                </Modal.Body>
                <Modal.Footer>
                    <button disabled={loadingUp} type="button" onClick={(e: any)=>deleteRes(e)} className="btn btn-danger">
                        {loadingUp ? 'Chargement...' : 'Comfirmer'}
                    </button>
                </Modal.Footer>
            </div>
        </Modal>
    </>
  )
}

import axios from 'axios';
import React from 'react'
import { Form, InputGroup } from 'react-bootstrap';
import { baseUrl } from '../../../../services/Services';
import { Authorization } from '../../../../utils/headers/Authorization';
import { EquipementType } from '../../../../utils/interface/EquipementType'
interface Props {
    designation: EquipementType | any,
    description: EquipementType | any,
    setDesignation: any,
    setDescription: any,
    createEquipement: any,
    equipement: EquipementType | any,
    setLoading: any
}
export const EditEquipement: React.FC<Props> = ({
    designation,
    description,
    setDesignation,
    setDescription,
    createEquipement,
    equipement,
    setLoading
}) => {

    const handleChange1 = (event: any)=> {
        setDesignation(event.target.value);
    }

    const handleChange2 = (event: any)=> {
        setDescription(event.target.value);
    }

    const updateEquipement = async (e: any) => {
        e.preventDefault();
        let edit = document.getElementById("editModal");
        setLoading(true);
        await axios.put(`${baseUrl}api/equipement/update/${equipement.id}`, {
            designation: designation,
            description: description
        }, {headers: Authorization})
        .then((response: any) => {
            setLoading(false);
            if(response) {
                edit?.setAttribute("data-dismiss", "modal")
            }
            window.location.reload();
        }).catch((error: any) => {
            setLoading(false);
        })
    }
    return (
        <div>
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Modification equipement</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Form noValidate>
                            
                        <Form.Group controlId="validationCustomUsername" className='mb-4'>
                            <Form.Label>Designation</Form.Label>
                            <InputGroup hasValidation>
                                
                                <Form.Control
                                    type="text"
                                    placeholder="Designation"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                    value={designation}
                                    onChange={handleChange1}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please choose a email adress.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="validationCustomUsername" className='mb-4'>
                            <Form.Label>Description</Form.Label>
                            <InputGroup hasValidation>
                                
                                <Form.Control
                                    type="text"
                                    placeholder="Description"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                    value={description}
                                    onChange={handleChange2}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please choose a email adress.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Form>
                </div>
                <div className="modal-footer justify-content-between">
                    <button type="button" className="btn btn-default" data-dismiss="modal">Fermer</button>
                    <button type="button" className="btn btn-primary" id='editModal'  onClick={(e) => updateEquipement(e)}>Validez</button>
                </div>
            </div>
        </div>
    )
}

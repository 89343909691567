import React from 'react'
import { Modal } from 'react-bootstrap'
interface Props {
    actionOK: any
}

export const DeleteComfirmation: React.FC<Props> = ({actionOK}) => {
    return (
        <div>
            <Modal.Header closeButton>
                <Modal.Title>Comfirmation de la suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h1>Comfirmation </h1>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" onClick={actionOK} className="btn btn-danger">Comfirmer</button>
            </Modal.Footer>
        </div>
    )
}

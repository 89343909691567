import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { PaiementContext } from '../../../../Context/AppContext'
import { RouterPath } from '../../../../utils/route/route'
import { DeleteComfirmation } from '../../../DeleteComfirmation/DeleteComfirmation'
import ReactLoading from 'react-loading';
import moment from 'moment'

interface Props {
    loading: boolean,
    setLoading: any
  }
  
export const ListPaiement: React.FC<Props> = ({
    loading, setLoading
}) => {

    const paiements = useContext(PaiementContext);

    const [searchText, setSearchText] = useState<string | any>('');
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [reservationPerPage, setReservationPerPage] = useState<any>(10);

    const dataFilter = paiements.filter((data: any) => {
        return (data.user && data.user.nom.toLowerCase().includes(searchText.toLowerCase())) ||
            (data.user && data.user.prenom.toLowerCase().includes(searchText.toLowerCase())) ||
            (data && data.reference.toLowerCase().includes(searchText.toLowerCase())) ||
            (data.reservationLogement && data.reservationLogement.logement.designation.toLowerCase().includes(searchText.toLowerCase()))
    })
    
    /**
     * Pagination
     */
    const indexOfLastPage = currentPage * reservationPerPage;
    const IndexOfFirstPage = indexOfLastPage - reservationPerPage;
    const currentReservation: any = dataFilter.slice(IndexOfFirstPage, indexOfLastPage);
    

    const paginate = (numberPage: any)=> {
        setCurrentPage(numberPage)
    }

    const deleteArticle = () => {
    }

    const handleChange = (e: any) => {
        e.preventDefault();
        setSearchText(e.target.value);
    };

    return (
        <section className="content">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 mb-4">
                    <div className="d-md-flex justify-content-between">
                        <div className="input-group input-group-lg _inputForm w-25">
                            <input value={searchText} onChange={handleChange} type="text" name="table_search" className="form-control float-right input-lg _inputSearch" placeholder="Search" />

                            <div className="input-group-append">
                                <button type="submit" className="btn btn-default">
                                    <i className="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <div className="modal fade" id="modal-del">
                            <div className="modal-dialog modal-del">
                                <DeleteComfirmation actionOK={deleteArticle}/>
                            </div>
                        </div>
                        {/* <button className="btn btn-primary btn-lg float-left _btnAdd">
                            <i className='fas fa-plus pr-2'></i>
                            Ajouter article
                        </button> */}
                    </div>
                </div>
            </div>
            {
                loading && loading ? (
                    <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0}/>
                ):(
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                
                                <div className="card-body table-responsive p-0">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                           
                                                
                                                <th scope="col">Référence</th>
                                                <th scope="col">Montant</th>
                                                {/* <th scope='col'>Paiement ID</th> */}
                                                <th scope="col">Nombre de voyageur</th>
                                                <th scope="col">Nom du client</th>
                                                <th scope="col">Prenoms du client</th>
                                                <th scope='col'>Début du réservation</th>
                                                <th scope="col">Fin du réservation</th>
                                                {/* <th scope='col'>Prix Total</th>
                                                <th scope="col">Logement</th> */}
                                                <th scope="col">Adresse du logement</th>
                                                <th scope="col">Nombre de piece</th>
                                                <th scope="col">Nombre personne</th>
                                               
                                                <th scope="col">Status</th>
                                                {/* <th scope="col">Action</th> */}
                                            
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            currentReservation && currentReservation.length > 0 ? (
                                                currentReservation.map((res: any) => (
                                                    <tr key={res.id}>
                                                            <td>{res.reference ?? "_"}</td>
                                                            <td>{res.paiement ? res.paiement.montant + ' MGA' : "_"}</td>
                                                            {/* <td>{res.payementIntent}</td> */}
                                                            <td>{res && res.nombreVoyageur + ' personnes'}</td>
                                                            <td>{res.user ? res.user.nom : "_"}</td>
                                                            <td>{res.user ? res.user.prenom : "_"}</td>
                                                            <td>{ res ? moment(res && res.dateDebut).format('YYYY-MM-DD') : '--'}</td>
                                                            <td>{ res ? moment(res && res.dateFin).format('YYYY-MM-DD') : '--'}</td>
                                                            <td>{res.logement ? res.logement.adresse : '_'}</td>
                                                            <td>{res.logement ? res.logement.nombrePieceTotal : '_'}</td>
                                                            <td>{res.logement ? res.logement.nombrePersonneMax : "_"}</td>
                                                            
                                                            
                                                            <td>
                                                                {/* <span className="badge badge-success">{res.status && res.status.designation}</span> */}

                                                                {
                                                                    res.status && res.status.designation === "requires_capture" && (
                                                                        <span className="badge badge-warning">{res.status && res.status.designation}</span>
                                                                    )
                                                                }

                                                                {
                                                                    res.status && res.status.designation === "annuler" && (
                                                                        <span className="badge badge-danger">{res.status && res.status.designation}</span>
                                                                    )
                                                                }

                                                                {
                                                                    res.status && res.status.designation === "terminer" && (
                                                                        <span className="badge badge-success">{res.status && res.status.designation}</span>
                                                                    )
                                                                }

{
                                                                    res.status && res.status.designation === "rembourser" && (
                                                                        <span className="badge badge-warning">{res.status && res.status.designation}</span>
                                                                    )
                                                                }
                                                            </td>
                                                            <td>
                                                                {/* <div>                                                                    
                                                                    <Link to={`${RouterPath.detailReservation}/${res.id}/details`} type="button" className="btn btn-sm" data-toggle="tooltip" data-placement="top" title="Consulter">
                                                                        <i className='fas fa-eye text-primary'></i>
                                                                    </Link>
                                                                </div> */}
                                                            </td>
                                                        </tr>
                                                ))
                                            ):(
                                                <tr>
                                                    <td style={{textAlign: "center"}} colSpan={8}>Pas de donnée</td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    </section>
    )
}

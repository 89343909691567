import axios from 'axios';
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { baseUrl } from '../../../services/Services';
import { Authorization } from '../../../utils/headers/Authorization';
import { AddComfirmation } from '../../AddConfirmation/AddComfirmation';
import ReactLoading from 'react-loading';
import { DeleteComfirmation } from '../../DeleteComfirmation/DeleteComfirmation';

interface Props {
    data: any[],
    loading: any,
    setLoading: any
}
export const ListParametraGeFrais: React.FC<Props> =({
    data,
    loading,
    setLoading
}) => {

    const [code, setCode] = useState<string | any>("");
    const [unite, setUnite] = useState<string | any>("");
    const [valeur, setValeur] = useState<string | any>("");
    const [designation, setDesignation] = useState<string | any>("");

    const [codeUp, setCodeUp] = useState<string | any>("");
    const [uniteUp, setUniteUp] = useState<string | any>("");
    const [valeurUp, setValeurUp] = useState<string | any>("");
    const [designationUp, setDesignationUp] = useState<string | any>("");


    const [show, setShow] = useState<boolean>(false);
    const [showUp, setShowUp] = useState<boolean>(false);


    const [showConfirm, setShowComfirm] = useState<boolean>(false);
    // const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);

    const [showConfirmUp, setShowComfirmUp] = useState<boolean>(false);
    const [showConfirmDel, setShowComfirmDel] = useState<boolean>(false);

    const [loadingDev, setLoadingdev] = useState<boolean>(false);
    // const [fsItems, setFsItems] = useState<any>([]);
    const [fsId, setFsId] = useState<any>();

    const [fsItemDel, setFSItemDel] = useState<any>([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseUp = () => setShowUp(false);    
    const handleShowUp = () => {
        setShowUp(true);
    }

    // const handleCloseUpdateModal = () => setShowUpdateModal(false);
    // const handleOpenUpdateModal = () => setShowUpdateModal(true);

    const handleCloseDel = () => setShowComfirmDel(false);

    const handleCloseComfirm = () => {
        setShowComfirm(false);
        setShow(false);
    }

    const handleCloseComfirmUp = () => {
        setShowComfirmUp(false);
        setShowUp(false);
    }

    const handleShowComfirm = () => {
        if(code === "" && designation === "" && valeur === "") {
            setLoading(false);
            setShowComfirm(false);
            toast.error("Les champs sont vide, veuillez les remplire SVP!");
        }else {
            setShowComfirm(true);
            setShow(false);
        }
        
    }

    const handleShowComfirmUp = () => {
        if(codeUp === "" && designationUp === "" && valeurUp === "" && uniteUp === "") {
            setShowComfirmUp(false);
            toast.error("Les champs sont vide, veuillez les remplire SVP!");
        }else {
            setShowComfirmUp(true);
            setShowUp(false);
        }
        
    }

    const getFS = (id: number) => {
        setLoadingdev(true);
        handleShowUp();
        axios.get(`${baseUrl}api/frais-service/${id}`,{
          headers: Authorization
        }).then((response) => {
            // setFsItems(response.data.data.rows);
            setCodeUp(response.data.data.rows.code)
            setUniteUp(response.data.data.rows.unite)
            setValeurUp(response.data.data.rows.valeur);
            setDesignationUp(response.data.data.rows.designation);
            setFsId(response.data.data.rows.id);
            setLoadingdev(false);
        }).catch(err => {
            setLoadingdev(false);
        });
    }


    const updateFrais = (e: any) => {

        setLoading(true);
        axios.put(`${baseUrl}api/frais-service/update/${fsId}`, {
            code: codeUp,
            designation: designationUp,
            unite: uniteUp,
            valeur:parseInt(valeurUp)
        }, {headers: Authorization}).then(res => {
            if(res) {
                setShow(false);
                window.location.reload();
                toast.success("Modification de devise effectuer avec succes!");
                setShowComfirmUp(false);
                setLoading(false);
                setCodeUp("");
                setDesignationUp("");
                setValeurUp("");
                setUniteUp("")

            }
        }).catch(err => {
            setLoading(false);
            toast.error("Error", err);

        })
    }

    const saveFrais = () => {
        
        if(code === "" && designation === "" && valeur === "") {
            setLoading(false);

            toast.error("Les champs sont vide, veuillez les remplire SVP!");
        }else{
            setLoading(true);
            axios.post(`${baseUrl}api/frais-service/create`, {
                code: code,
                designation: designation,
                unite: unite,
                valeur:parseInt(valeur)
            }, {headers: Authorization}).then(res => {
                if(res) {
                    setShow(false);
                    toast.success("Insertion effectuer avec succes!");
                    window.location.reload();
                    setShowComfirm(false);
                    setLoading(false);
                    setCode("");
                    setUnite("");
                    setDesignation("");
                    setValeur("");

                }
            }).catch(err => {
                setLoading(false);
                toast.error("Error", err);

            })
        }

    }

    const handleShowDel = (data: any) => {
        setShowComfirmDel(true);
        setFSItemDel(data);
    }

    const deleteFS = (e: any) => {
        setLoading(true);
        axios.delete(`${baseUrl}api/frais-service/delete/${fsItemDel.id}`, {headers: Authorization}).then(res => {
            if(res) {
                setLoading(false);
                window.location.reload();
                handleCloseDel();
                toast.success(`Suppression du devise ${fsItemDel.designation} effectuer avec succes!`);
            }
        }).catch(err => {
            toast.warning(`Erreur lors de la sSuppression du devise ${fsItemDel.designation}, veuillez verifier SVP!`);
            setLoading(false);
        })
    }

    return (
        <section className="content">
            <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
            {/* Same as */}
            <ToastContainer />
            <div className="container-fluid">
                
                <div className="row">
                    <div className="col-12 mb-4">
                        <div className="d-md-flex justify-content-between">
                            <div className="input-group input-group-lg _inputForm w-25">
                                <div className="">
                                    <h1 className="m-0">Frais de service </h1>
                                </div>
                            </div>
                            
                            <button className="btn btn-primary btn-lg float-left _btnAdd" onClick={handleShow}>
                                <i className='fas fa-plus pr-2'></i>
                                Ajouter frais de service
                            </button>
                            

                            <Modal
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                show={show}
                                onHide={handleClose}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Ajout frais</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="form-group">
                                        <label htmlFor="code">Code</label>
                                        <input type="text" value={code} onChange={(e)=>setCode(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Code" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="designation">Designation</label>
                                        <input type="text" value={designation} onChange={(e)=>setDesignation(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Designation" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="valeur">Valeur</label>
                                        <input type="text" value={valeur} onChange={(e)=>setValeur(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Valeur" />
                                    
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="valeur">Unite</label>
                                        <input type="text" value={unite} onChange={(e)=>setUnite(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Unité" />
                                    
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="button" onClick={handleClose} className="btn btn-danger">Annuler</button>
                                    <button type="button" onClick={handleShowComfirm} className="btn btn-primary">Enregistrer</button>
                                </Modal.Footer>
                            </Modal>

                            <Modal
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                show={showConfirm}
                                onHide={handleCloseComfirm}
                            >
                                <AddComfirmation actionOK={saveFrais}/>
                            </Modal>
                        </div>
                    </div>
                </div>
                {
                    loading ? (
                        <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0}/>
                    ) : (
                    <>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className='card-header'>
                                        <div className="d-flex col-md-6">
                                            <label htmlFor="">Afficher par</label>
                                            {/* <select id="inputState" onChange={(e)=>setArticlePerPage(e.target.value)} className="form-control w-auto ml-2">
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="75">75</option>
                                                <option value="100">100</option>
                                            </select> */}
                                        </div>
                                    </div>
                                    <div className="card-body table-responsive p-0"  style={{height: "63vh"}}>
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Code</th>
                                                    <th scope="col">Designation</th>
                                                    <th scope="col">Valeur</th>
                                                    <th scope="col">Unité</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                data && data.length ? (
                                                    data.map((item: any) => (
                                                                
                                                        <tr key={item.id}>
                                                            <th scope="row">{item.id}</th>
                                                            <td>{item.code ? item.code : "_"}</td>
                                                            <td>{item.designation ? item.designation : '_'}</td>
                                                            <td>{item.valeur ? item.valeur : '_'}</td>
                                                            <td>{item.unite ? item.unite : '_'}</td>
                                                            <td>
                                                                <div>
                                                                    
                                                                    <button type="button" onClick={() => getFS(item.id)} data-toggle="modal" data-target="#modal-lg" className="btn btn-sm" data-placement="top" title="Modifier">
                                                                        <i className='fas fa-edit text-success'></i>
                                                                    </button>
                                                                    <button onClick={() => handleShowDel(item)} className="btn btn-sm" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                                                        <i className='fas fa-trash text-danger'></i>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ):(
                                                    <tr>
                                                        <td style={{textAlign: "center"}} colSpan={8}>Pas de donnée</td>
                                                    </tr>
                                                ) 
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="card-footer">
                                        <div className="float-right">
                                            {/* <Paginations 
                                                postPerPage={articlePerPage} 
                                                totalPages={articleItems.length}
                                                paginate={paginate}
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    )
                }
                
            </div>

            <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={showUp}
                    onHide={handleCloseUp}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Modification frais de service</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {
                        loadingDev ? (
                            <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0}/>
                        ):(
                            <>
                                <Modal.Body>
                                    <div className="form-group">
                                        <label htmlFor="code">Code</label>
                                        <input type="text" value={codeUp} onChange={(e)=>setCodeUp(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Code" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="designation">Designation</label>
                                        <input type="text" value={designationUp} onChange={(e)=>setDesignationUp(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Designation" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="valeur">Valeur</label>
                                        <input type="text" value={valeurUp} onChange={(e)=>setValeurUp(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Valeur" />
                                    
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="valeur">Unite</label>
                                        <input type="text" value={uniteUp} onChange={(e)=>setUniteUp(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Unité" />
                                    
                                    </div>
                                </Modal.Body>
                            </>
                        )
                    }
                    
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" onClick={handleCloseUp} className="btn btn-danger">Annuler</button>
                        <button type="button" onClick={handleShowComfirmUp} className="btn btn-primary">Enregistrer</button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={showConfirmUp}
                    onHide={handleCloseComfirmUp}
                >
                    <AddComfirmation actionOK={(e: Event) => updateFrais(e)}/>
                </Modal>

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={showConfirmDel}
                    onHide={handleCloseDel}
                >
                    <DeleteComfirmation actionOK={(e: Event) => deleteFS(e)}/>
                </Modal>
        </section>
    )
}

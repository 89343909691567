import React from 'react'
import { ParametrageTextComponent } from '../../Components/ParametrageTextComponent/ParametrageTextComponent'

export const ParametragetextPage: React.FC = () => {
  return (
    <div>
      <ParametrageTextComponent/>
    </div>
  )
}


import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import Select from 'react-select';
import { LogementContext } from '../../../../Context/AppContext';
import { baseUrl, imgPath } from '../../../../services/Services';
import { Authorization } from '../../../../utils/headers/Authorization';
import { EquipementType } from '../../../../utils/interface/EquipementType';
import { LogementType } from '../../../../utils/interface/LogementType';
import ReactLoading from 'react-loading';
import { QuartierType } from '../../../../utils/interface/QuartierType';
import { toast } from 'react-toastify';
import { CKEditor } from 'ckeditor4-react';

interface Props {
    adress: LogementType | any,
    designation: LogementType | any,
    description: LogementType | any,
    nombrePersonneMax: LogementType | any,
    nombrePieceTotal: LogementType | any,
    quartier: LogementType | any,
    setAdress: any,
    setDesignation: any
    setDescription: any,
    setNombrePersonneMax: any,
    setNombrePieceTotal: any,
    setQuartier: any,
    equipement: LogementType[] | any,
    setEquipement: any,
    quartiers: QuartierType | any,
    equipements: EquipementType | any,
    createLogement: any,
    uploadImage: any,
    image: any,
    qId: any,
    logItem: any,
    loyer: any,
    setLoyer: any,
    contact: any,
    propri: any,
    setContact: any,
    setPropri: any,
    setProprio: any,
    setContactP: any,
    contactP: any,
    proprio: any,
    loadingUp: boolean,
    imge: any
}

export const UpdateLogement: React.FC<Props> = ({
    loadingUp,
    adress,
    designation,
    description,
    nombrePersonneMax,
    nombrePieceTotal,
    quartier,
    equipement,
    setAdress,
    setDesignation,
    setDescription,
    setNombrePersonneMax,
    setNombrePieceTotal,
    setQuartier,
    setEquipement,
    quartiers,
    equipements,
    createLogement,
    uploadImage,
    image,
    imge,
    qId,
    logItem,
    loyer,
    setLoyer,
    contact,
    propri,
    setContact,
    setPropri,
    setProprio,
    setContactP,
    contactP,
    proprio

}) => {
    let options: [] | any = [];
    let itemss: any[] = [];
    let itemOptions: [] | any = [];
    let optionsLists: [] | any = [];
    let listsEqs: any = [];
    
    let valEqs: any[] = [];
    let listEquips: any[] = [];
    
    let listEquipes: any = [];
    
    const [loading, setLoading] = useState<boolean | any>(false);
    const [loadingBtn, setLoadingBtn] = useState<boolean | any>(false);


    const [val, setVal] = useState<any>("");
    const [val1, setVal1] = useState<any>("");

    const region = useContext(LogementContext)

    const [faritra, setFaritra] = useState<any>("");
    const [listsEq, setListsEq] = useState<any>("");
    const [idEq, setIdEq] = useState<any>()
    const [logs, setLogs] = useState<any>(equipement);

    const [listEquipement, setListEquipement] = useState<any>([]);


    const [equipes, setEquips] = useState<any>(equipement);
    const [imgs, setImgs] = useState<any>(imge);
    const [newImgs, setNewImgs] = useState<any>([]);
    const [listImge, setListImg] = useState<any>([]);
    const [imgStatus, setImgStatus] = useState<any>(false);

    const [lists, setLists] = useState<any[] | any>([...itemOptions]);

    useEffect(() => {
        setEquips(equipement) 
        setImgs(imge)
        setListImg(newImgs)
    }, [equipement, imge, newImgs])

    const fileToDataUri = (image: any) => {
        return new Promise((res) => {
          const reader = new FileReader();
          const {type, name, size} = image;
          reader.addEventListener('load', () => {
              res({
                  base64: reader.result,
                  name: name,
                  type,
                  size: size,
              })
          });
          reader.readAsDataURL(image);
        })
    }

    
    const uploadImages = async (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const newImagesPromises = []
            for (let i = 0; i < e.target.files.length; i++) {
                newImagesPromises.push(fileToDataUri(e.target.files[i]))
            }
            const newImages = await Promise.all(newImagesPromises)
            setNewImgs([...newImgs, ...newImages])
        }
        e.target.value = "";
    }

    console.log("NEM img", newImgs)




    equipes.map((item: any) => {
        let datasEq = equipements.filter((name: any) => {
            return name.id !== item.id
        });

        if (equipes === null ) {
            listsEqs = equipements
        } else {
            
            listsEqs = datasEq;

        }

    })



    equipements.map((data: any) => {
        options.push({value: data.id, label: data.designation})
    });

    equipement.map((data: any) => {
        optionsLists.push({label: data && data.designation})
    });

    const handler = (event: any) => {
        const value = event;
        setVal1(value);
    }

    const items: any = quartiers && quartiers.filter((item: any) => {
        if(qId) {
            return (item.id === parseInt(qId));
        }
    });


    const listQuartier = quartiers && quartiers.filter((item: any) => {
        return (item.id !== parseInt(qId));
    });


    const addToList = (e: any) => {
        e.preventDefault();
        setLists((current: any) => [...current, {id: parseInt(val)}])
        // itemss.push(val)
    }

    const removeElement = (e: any, id: any) => {
        e.preventDefault();
        const newFruits = equipes.filter(
        (data: any) => data.id !== id
        );
        setEquips(newFruits);
    };

    const removeImage = (e: any, id: any) => {
        e.preventDefault();
        const newImg = imgs.filter(
            (data: any) => data.id !== id
        );
        handleDeleteFile(id)
        setImgs(newImg);
    };

    const handleDeleteFile = (id: number) => {
        axios.delete(`${baseUrl}api/delete-file/${id}`, {headers: Authorization}).then((res) => {
            if(res) {
                toast.success("Suppression d'image effectuer avec success");
            }
        }).catch(err => {
            toast.warning("Erreur lors de la suppression d'image");
        })
    }

    const makeCover = (e: any, idImg: number) => {
        e.preventDefault();
        axios.put(`${baseUrl}api/logement-cover/${logItem && logItem.id}/${idImg}`).then((res) => {
            if(res) {
                toast.success("Modification d'image fond effectuer avec success");
            }
        }).catch(err => {
            toast.warning("Erreur lors de la modification d'image");
        })
    }

    const removeImageNew = (e: any, name: any) => {
        e.preventDefault();
        const newImg = listImge.filter(
            (data: any) => data.name !== name
        );
        setListImg(newImg);
    };
    

    equipes && equipes.map((item: any) => {
        itemOptions.push({id: item && item.id})
    })

    
    val1 && val1.map((item: any) => {
        valEqs.push({id: item && item.value})
    })
    let listEqs = itemOptions.concat(valEqs);

    let newImgLists = imgs.concat(listImge)
  

    const updateLogement = async(e: any) => {
        e.preventDefault();
        setLoading(true);
        setLoadingBtn(true)

        await axios.put(`${baseUrl}api/logement/${logItem.id}`, { 
            designation: designation,
            nombrePieceTotal: parseInt(nombrePieceTotal),
            nombrePersonneMax: parseInt(nombrePersonneMax),
            adresse: adress,
            quartier: parseInt(quartier) ? parseInt(quartier) : qId,
            description: description,
            equipement: listEqs,
            loyer: parseFloat(loyer),
            contact: contact,
            nom: propri,
            files: newImgLists
        }, {headers: Authorization}).then(res => {
            setLoading(false);
            setLoadingBtn(false)
            window.location.reload();

        }).catch(err => {
            setLoading(false)
            setLoadingBtn(false)
            console.log(err);

        })
    }
    

    const deleteEquipementByLogement = async(id: number) => {
        setLoading(true);
        await axios.delete(`${baseUrl}api/details-logement/${id}?logementId=${logItem.id}`).then((res: any) => {
            setLoading(false);
        }).catch((err) => {
            console.log("error", err)
        })

    }

    const handleChange = (e: any) => {
        e.preventDefault()
        setVal(e.target.value)
    }

    let value = equipement && equipement.concat(optionsLists)

    return (
        <div>
           
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Modification logement</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                </div>
                
                <div className="modal-body">
                {
                    loadingUp ? (
                        <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0}/>
                    ): (
                        <Form noValidate>
                            
                            <input type="file" onChange={uploadImages} multiple/>
                            <br/><br />
                            <div className='imgBox_ mb-4' style={{position: "relative"}}>
                                {
                                    imgs && imgs.map((img: any, index: any) => {
                                        return(
                                            
                                            <div className='imgBox_ boxImg_ mb-3' style={{position: "relative"}}>
                                                <img style={{width: "200px", height: "150px"}} onDoubleClick={(e: any) => removeImage(e, img && img.id)} key={img.id} className='_imgS' src={`${imgPath}${img.path}`} alt={img.name} />
                                                {
                                                    img && img.isCover === true ? (
                                                        <span className="badge badge-success" style={{
                                                            position: "absolute",
                                                            padding: '2px',
                                                            bottom:'0'
                                                        }}>Active</span>
                                                    ) : (
                                                        <button style={{
                                                            position: "absolute", bottom: '0', width: '100%'
                                                        }} className='btn btn-primary' onClick={(e: any)=>makeCover(e, img.id)}>Mettre comme fond</button>
                                                    )
                                                }
                                            </div>
                                        )
                                    })
                                }
                                        
                                {
                                    listImge && listImge.map((img: any, index: any) => {
                                        return(
                                            
                                            <div className='imgBox_ boxImg_'>
                                                <img style={{width: "200px", height: "150px"}} onDoubleClick={(e: any) => removeImageNew(e, img && img.name)} key={index} className='_imgS' src={img.base64} alt={img.name} />
                                                <button>Mettre en active</button>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                                
                            
                            <div className="row">
                                <Form.Group className='col-12 col-md-3 mb-4'>
                                    <Form.Label>Quartier</Form.Label>
                                    
                                    <InputGroup hasValidation> 
                                        <Form.Select value={quartier} onChange={(e: any)=> setQuartier(e.target.value)} className="form-control" id="exampleFormControlSelect1">
                                            <option defaultValue={qId} value={qId}>{items && items[0]?.designation}</option>
                                            {
                                                listQuartier.map((cart: any, index: any) => (
                                                    <option key={index} value={cart && cart.id}>{cart && cart.designation}</option>
                                                ))
                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please choose a email adress.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>

                                        

                                <Form.Group className='col-12 col-md-3 mb-4'>
                                    <Form.Label>Adresse</Form.Label>
                                    <InputGroup hasValidation>
                                        
                                        <Form.Control
                                            type="text"
                                            placeholder="Adresse du logemente"
                                            aria-describedby="inputGroupPrepend"
                                            required
                                            value={adress}
                                            onChange={(e: any) => setAdress(e.target.value)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Erreur.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group className='col-12 col-md-3 mb-4'>
                                    <Form.Label>Loyer par nuit</Form.Label>
                                    <InputGroup hasValidation>
                                        
                                        <Form.Control
                                            type="text"
                                            placeholder="Loyer"
                                            aria-describedby="inputGroupPrepend"
                                            required
                                            value={loyer}
                                            onChange={(e: any) => setLoyer(e.target.value)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Erreur.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group className='col-12 col-md-3 mb-4'>
                                    <Form.Label>Designation</Form.Label>
                                    <InputGroup hasValidation>
                                        
                                        <Form.Control
                                            type="text"
                                            placeholder="Designation"
                                            aria-describedby="inputGroupPrepend"
                                            required
                                            value={designation}
                                            onChange={(e: any) => setDesignation(e.target.value)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Erreur.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </div>
                            
                            <div className="row">
                                <Form.Group className='col-12 col-md-3 mb-4'>
                                    <Form.Label>Nombre pièce</Form.Label>
                                    <InputGroup hasValidation>
                                        
                                        <Form.Control
                                            type="number"
                                            placeholder="Nombre des piècese"
                                            aria-describedby="inputGroupPrepend"
                                            value={nombrePieceTotal}
                                            onChange={(e: any) => setNombrePieceTotal(e.target.value)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Erreur.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group className='col-12 col-md-3 mb-4'>
                                    <Form.Label>Nombre personne</Form.Label>
                                    <InputGroup hasValidation>
                                        
                                        <Form.Control
                                            type="number"
                                            placeholder="Nombre des personnes"
                                            aria-describedby="inputGroupPrepend"
                                            value={nombrePersonneMax}
                                            onChange={(e: any) => setNombrePersonneMax(e.target.value)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Erreur.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group className='col-12 col-md-3 mb-4'>
                                    <Form.Label>Nom du proprietaire</Form.Label>
                                    <InputGroup hasValidation>
                                        
                                        <Form.Control
                                            type="text"
                                            placeholder="Nom du proprietaire"
                                            aria-describedby="inputGroupPrepend"
                                            value={propri}
                                            onChange={(e: any) => setPropri(e.target.value)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Erreur.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group className='col-12 col-md-3 mb-4'>
                                    <Form.Label>Contact du proprietaire</Form.Label>
                                    <InputGroup hasValidation>
                                        
                                        <Form.Control
                                            type="text"
                                            placeholder="Contact du proprietaire"
                                            aria-describedby="inputGroupPrepend"
                                            value={contact}
                                            onChange={(e: any) => setContact(e.target.value)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Erreur.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </div>

                            <Form.Group className='mb-4'>
                                <Form.Label>Equipements</Form.Label>
                                <Select isMulti options={ options } value={val1}  onChange={handler} />
                            </Form.Group>

                            <div className="row px-2">
                                <div className="mb-4">
                                    <Form.Label>Liste des equipements</Form.Label>
                                    <div  className = 'd-grid mb-2'>
                                        {
                                            
                                            equipes && equipes.map((item: any, index: any) => (
                                                <span key={item && item.id} onClick={(e: any)=>removeElement(e, item && item.id)}  className='btn btn-secondary pr-0 ml-2 mb-2 '>
                                                    {item && item.designation}
                                                    <span className='ml-2 p-2' style={{background: '#cf2d2d', color: '#fff'}}>
                                                        X
                                                    </span>
                                                </span>
                                            ))
                                            
                                        }
                                        {
                                            val1 && val1.map((item: any, index: any) => (
                                                <div key={index}>
                                                    <button className='btn btn-light pr-2 ml-2'>
                                                        {item && item.label}
                                                    </button>
                                                </div>
                                            ))
                                        }

                        
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <Form.Group className='col-12 col-md-12 mb-4'>
                                    <Form.Label>Description</Form.Label>
                                    <CKEditor
                                        // editor={ ClassicEditor }
                                        initData={`${description}`}
                                        data={`${description}`}
                                        onReady={ (editor: any) => {
                                            // You can store the "editor" and use when it is needed.
                                            console.log( 'Editor is ready to use!', editor );
                                        } }
                                        onLoad={(event: any) => {
                                            event.editor.setData(logItem.id)
                                        }}
                                        onChange={ ( ev: any ) => {
                                            const data = ev.editor.getData();
                                            setDescription(data);
                                        } }
                                        
                                    />
                                    {/* <InputGroup hasValidation>
                                        
                                        <Form.Control
                                            as="textarea" rows={4}
                                            placeholder="Description"
                                            aria-describedby="inputGroupPrepend"
                                            value={description}
                                            onChange={(e: any) => setDescription(e.target.value)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Erreur.
                                        </Form.Control.Feedback>
                                    </InputGroup> */}
                                </Form.Group>
                            </div>

                            

                        

                            

                            
                        </Form>
                    ) 
                }
                </div>
                <div className="modal-footer justify-content-between">
                    <button type="button" className="btn btn-default" data-dismiss="modal">Fermer</button>
                    <button type="button" onClick={(e) => updateLogement(e)} className="btn btn-primary">
                        {
                            loadingBtn ? (
                                <span>Loading...</span>
                            ):(
                                <span>Enregistrer</span>
                            )
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}

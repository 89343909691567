import React from 'react'
import {  NavLink } from 'react-router-dom'
import { RouterPath } from '../../../utils/route/route';
import { AiFillAppstore, AiOutlineOrderedList } from 'react-icons/ai';
interface Props {
    
}
export const Sidebar: React.FC<Props> = ()=> {

    return (
        <div>
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <NavLink to={RouterPath.home} className="brand-link">
                <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{opacity: ".8"}}/>
                <span className="brand-text font-weight-light">RESERVATION</span>
            </NavLink>

            <div className="sidebar">
                {/* <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Images"/>
                    </div>
                    <div className="info">
                        <a href="index.html" className="d-block">Alexander Pierce</a>
                    </div>
                </div> */}


                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <li className="nav-header">MENU</li>
                        <li className="nav-item menu-open">
                            <NavLink to={RouterPath.home} className="nav-link">
                                <i className="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Dashboard
                                    <i className="fas fa-angle-left right"></i>
                                </p>
                            </NavLink>
                        </li>

                        <li className="nav-item menu-open">
                            <NavLink to={RouterPath.devise} className="nav-link">
                                <i className="nav-icon fas fa-euro-sign"></i>
                                <p>
                                    Devise
                                    <i className="fas fa-angle-left right"></i>
                                </p>
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={RouterPath.logement} className="nav-link">
                                <i className="nav-icon fas fa-home"></i>
                                <p>
                                    Logement
                                    <i className="fas fa-angle-left right"></i>
                                </p>
                            </NavLink>
                        </li>

                        {/* <li className="nav-item">
                            <NavLink to={RouterPath.client} className="nav-link">
                                <i className="nav-icon fas fa-users"></i>
                                <p>
                                    Clients
                                    <i className="fas fa-angle-left right"></i>
                                </p>
                            </NavLink>
                        </li> */}

                        <li className="nav-item">
                            <NavLink to={RouterPath.categorie} className="nav-link">
                                <AiOutlineOrderedList className='nav-icon' size="1.5em"/>
                                {/* <i className="nav-icon fas fa-users"></i> */}
                                <p>
                                    Categorie
                                    <i className="fas fa-angle-left right"></i>
                                </p>
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={RouterPath.parametrageText} className="nav-link">
                                <AiFillAppstore className='nav-icon' size="1.5em"/>
                                {/* <i className="nav-icon fas fa-users"></i> */}
                                <p>
                                    Parametrage text
                                    <i className="fas fa-angle-left right"></i>
                                </p>
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={RouterPath.article} className="nav-link">
                                <i className="nav-icon fas fa-list"></i>
                                <p>
                                    Articles
                                    <i className="fas fa-angle-left right"></i>
                                </p>
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={RouterPath.equipement} className="nav-link">
                                <i className="nav-icon fas fa-cog"></i>
                                <p>
                                    Equipements
                                    <i className="fas fa-angle-left right"></i>
                                </p>
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={RouterPath.reservation} className="nav-link">
                                <i className="nav-icon fas fa-credit-card"></i>
                                <p>
                                    Reservation
                                    <i className="fas fa-angle-left right"></i>
                                </p>
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={RouterPath.historique} className="nav-link">
                                <i className="nav-icon fas fa-list"></i>
                                <p>
                                    Historique réservation
                                    <i className="fas fa-angle-left right"></i>
                                </p>
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={RouterPath.ville} className="nav-link">
                                <i className="nav-icon fas fa-map-marker"></i>
                                <p>
                                    Ville
                                    <i className="fas fa-angle-left right"></i>
                                </p>
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={RouterPath.quartier} className="nav-link">
                                <i className="nav-icon fas fa-street-view"></i>
                                <p>
                                    Quartier
                                    <i className="fas fa-angle-left right"></i>
                                </p>
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={RouterPath.contact} className="nav-link">
                                <i className="nav-icon fas fa-phone"></i>
                                <p>
                                    Contact
                                    <i className="fas fa-angle-left right"></i>
                                </p>
                            </NavLink>
                        </li>

                        <li className="nav-header">ADMINISTRATEUR</li>

                        <li className="nav-item">
                            <NavLink to={RouterPath.user} className="nav-link">
                                <i className="nav-icon fas fa-user"></i>
                                <p>
                                    Utilisateurs
                                    <i className="fas fa-angle-left right"></i>
                                </p>
                            </NavLink>
                        </li>
                    
                    </ul>
                </nav>
            </div>
        </aside>
    </div>
    )
}

import React from 'react'
import { ListContact } from './Components/ListContact/ListContact'

export const ContactComponents = () => {
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Contacts</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="index.html">Contact</a></li>
                                <li className="breadcrumb-item active">Contact v1</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <ListContact/>
        </div>
    )
}

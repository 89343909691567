import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { LogementContext } from '../../Context/AppContext';
import { baseUrl } from '../../services/Services';
import { Authorization } from '../../utils/headers/Authorization';
import { LogementType } from '../../utils/interface/LogementType';
import { ListLogement } from './Components/ListLogement/ListLogement';
import { toast, ToastContainer } from 'react-toastify';

interface Props {}
export const LogementComponents: React.FC<Props> = ()=> {

    const [adress, setAdress] = useState<LogementType | any>("");
    const [designation, setDesignation] = useState<LogementType | any>("");
    const [description, setDescription] = useState<LogementType | any>("");
    const [nombrePersonneMax, setNombrePersonneMax] = useState<LogementType | any>(0);
    const [nombrePieceTotal, setNombrePieceTotal] = useState<LogementType | any>(0);
    const [quartier, setQuartier] = useState<LogementType | any>(0);
    const [equipement, setEquipement] = useState<any[]>([]);
    const [image, setImage] = useState<LogementType | any>([]);
    const [loyer, setLoyer] = useState<LogementType | any>(0);
    const [contact, setContact] = useState<any>("");
    const [propri, setPropri] = useState<any>("");
    const [loading, setLoading] = useState<boolean | any>(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [listLogements, setLogements] = useState<LogementType[] | any>([]);
    const [listImge, setListImg] = useState<any>([]);

    const eqs: any = [];
    equipement && equipement.map((ds: any) => {
        eqs.push({id:ds.value})
    });

    useEffect(() => {
        setLoading(true);

        const getAllLogements = async() => {
            await axios.get(`${baseUrl}api/logement`, {headers: Authorization})
                .then((response: any) => {
            
                    setLoading(false);
                    setLogements(response.data.data.rows);
            
                }).catch((error: any) => {
            
                    setLoading(false);
            
                });
        }

        getAllLogements();
        setListImg(image);
    }, [image])
    
    const fileToDataUri = (image: any) => {
        return new Promise((res) => {
          const reader = new FileReader();
          const {type, name, size} = image;
          reader.addEventListener('load', () => {
              res({
                  base64: reader.result,
                  name: name,
                  type,
                  size: size,
              })
          });
          reader.readAsDataURL(image);
        })
    }

    const uploadImage = async (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const newImagesPromises = []
            for (let i = 0; i < e.target.files.length; i++) {
                newImagesPromises.push(fileToDataUri(e.target.files[i]))
            }
            const newImages = await Promise.all(newImagesPromises)
            setImage([...image, ...newImages])
        }
        e.target.value = "";
    }

    const removeImageNew = (e: any, name: any) => {
        e.preventDefault();
        const newImg = listImge.filter(
        (data: any) => data.name !== name
        );
        setListImg(newImg);
    };
    

    const createLogement = (e: any) => {
        e.preventDefault();
        setLoading(true);

        axios.post(`${baseUrl}api/logement`, {
            designation: designation,
            nombrePieceTotal: parseInt(nombrePieceTotal),
            nombrePersonneMax: parseInt(nombrePersonneMax),
            adresse: adress,
            quartier: parseInt(quartier),
            description: description,
            equipement: eqs,
            loyer: parseFloat(loyer),
            files: listImge,
            contact: contact,
            nom: propri
        }, {headers: Authorization}).then(res => {
            
            if(res){

                setLoading(false);
                window.location.reload();

                setShow(false);
                toast.success("Creation de logement effectué avec succes!");
            }
            

        }).catch(err => {
            setLoading(false)
            toast.error("Erreur lors du Creation de logement!");
            setShow(false)

        })
    }

    

    return (
        <div className="content-wrapper">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                />
                {/* Same as */}
            <ToastContainer />
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Logements</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="index.html">Logements</a></li>
                                <li className="breadcrumb-item active">Logements v1</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <LogementContext.Provider value={listLogements}>
                <ListLogement
                    adress={adress}
                    designation={designation}
                    description={description}
                    nombrePersonneMax={nombrePersonneMax}
                    nombrePieceTotal={nombrePieceTotal}
                    quartier={quartier}
                    loyer={loyer}
                    setLoyer={setLoyer}
                    equipement={equipement}
                    setEquipement={setEquipement}
                    setAdress={setAdress}
                    setDesignation={setDesignation}
                    setDescription={setDescription}
                    setNombrePersonneMax={setNombrePersonneMax}
                    setNombrePieceTotal={setNombrePieceTotal}
                    setQuartier={setQuartier}
                    createLogement={createLogement}
                    uploadImage={uploadImage}
                    image={listImge}
                    loading={loading}
                    handleClose={handleClose}
                    show={show}
                    handleShow={handleShow}
                    contact={contact}
                    propri={propri}
                    setContact={setContact}
                    setPropri={setPropri}
                    setLoading={setLoading}
                    removeImageNew={removeImageNew}
                />
            </LogementContext.Provider>
        </div>
    )
}

import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { PaiementContext } from '../../Context/AppContext';
import { baseUrl } from '../../services/Services';
import { Authorization } from '../../utils/headers/Authorization';
import { ListPaiement } from './Components/ListPaiement/ListPaiement'

export const HistoriquePComponent = () => {
    const [listsPaiements, setListePaiements] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const getPayments = async () => {
            setLoading(true)
            await axios.get(`${baseUrl}api/historique/reservation`, {
                headers: Authorization
            }).then((response: any) => {
                setLoading(false)
                setListePaiements(response.data.data.rows)
            }).catch(err => {
                setLoading(false)
                toast.warning("Erreur de chargement de donnée")
            })
        }
        getPayments()
    },[]);

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Historique du réservation</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="index.html">Historique</a></li>
                                <li className="breadcrumb-item active">Historique v1</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <PaiementContext.Provider value={listsPaiements}>
                <ListPaiement loading={loading} setLoading={setLoading} />
            </PaiementContext.Provider>
        </div>
    )
}

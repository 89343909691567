
import React from 'react'

export const LogementContext = React.createContext([]);

export const DetailLogementContext = React.createContext("");

export const VilleContext = React.createContext([]);

export const UserContext = React.createContext([]);

export const QuartierContext = React.createContext([]);

export const EquipementContext = React.createContext([]);

export const ArticleContext = React.createContext([]);

export const CategoryContext = React.createContext([]);

export const ReservationContext = React.createContext([]);

export const ContactContext = React.createContext([]);

export const PaiementContext = React.createContext([]);

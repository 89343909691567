import axios from 'axios';
import React, { useState } from 'react'
import { baseUrl } from '../../services/Services';
import { Authorization } from '../../utils/headers/Authorization';
import { CategoryType } from '../../utils/interface/CategoryTYpe';
import { ListCategorie } from './Components/Liste/ListCategorie'

interface Props {
  loading: boolean,
  setLoading: any
}

export const CategorieComponent: React.FC<Props> = ({
  loading, setLoading
}) => {

    const [designation, setDesignation] = useState<CategoryType | any>("");
    const [valeur, setValeur] = useState<CategoryType | any>("");
    const [rang, setRang] = useState<any>(0);
    

    const createCategory = (e: any) => {
      e.preventDefault();
  
      setLoading(true);
  
      axios.post(`${baseUrl}api/categorie/create`, {
        designation: designation,
        value: valeur,
        rang: parseInt(rang)
      }, {headers: Authorization}).then((response: any) => {
  
        setLoading(false);

        window.location.reload();
        setDesignation("");
        setValeur("");
        setRang("");
  
      }).catch((error: any) => {
  
        setLoading(false);
  
      });
  
    }
  return (
    <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Categorie</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="index.html">Categorie</a></li>
                            <li className="breadcrumb-item active">Categorie v1</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <ListCategorie
            loading={loading}
            designation={designation}
            setDesignation={setDesignation}
            valeur={valeur}
            rang={rang}
            setRang={setRang}
            setValeur={setValeur}
            createCategory={createCategory}
            setLoading={setLoading}
        />
    </div>
  )
}

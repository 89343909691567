import React from 'react'
import AllCard from './components/AllCards/AllCard'
// import CardMap from './components/CardMap/CardMap'
// import HomeStatistik from './components/HomeStatistic/HomeStatistik'

export default function HomeComponent() {
  return (
    <div>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Tableau de bord</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="index.html">Tableau de bord</a></li>
                    <li className="breadcrumb-item active">Tableau de bord v1</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <section className="content">
            <div className="container-fluid">
              <AllCard/>
              {/* <AllCard/> */}
              {/* <div className="row">
                  <HomeStatistik/>                  
                  <CardMap/>
              </div> */}
            </div>
          </section>
        </div>
    </div>
  )
}

import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast, ToastContainer } from 'react-toastify'
import { AddComfirmation } from '../../AddConfirmation/AddComfirmation'
import ReactLoading from 'react-loading';
import { Link } from 'react-router-dom';
import { RouterPath } from '../../../utils/route/route';
import axios from 'axios';
import { baseUrl } from '../../../services/Services';
import { Authorization } from '../../../utils/headers/Authorization';
import { DeleteComfirmation } from '../../DeleteComfirmation/DeleteComfirmation';

interface Props {
    allText: any,
    loading: any,
    setLoading: any
}
export const ListTextParam: React.FC<Props> = ({
    allText,
    loading,
    setLoading
}) => {
    
    const [titre, setTitre] = useState<string | any>("");
    const [valeur, setValeur] = useState<string | any>("");
    const [code, setCode] = useState<string>("");

    const [titreUp, setTitreUp] = useState<string | any>("");
    const [valeurUp, setValeurUp] = useState<string | any>("");
    const [codeUp, setCodeUp] = useState<string>("");
    const [txtId, setTxtId] = useState<string>("");
    
    const [show, setShow] = useState<boolean>(false);
    const [showConfirm, setShowComfirm] = useState<boolean>(false);

    const [showUp, setShowUp] = useState<boolean>(false);
    const [showConfirmUp, setShowComfirmUp] = useState<boolean>(false);
    const [showConfirmDel, setShowComfirmDel] = useState<boolean>(false);

    const [load, setLoad] = useState<boolean>(false);

    const [val, setVal] = useState<any>([]);



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseUp = () => setShowUp(false);

    const handleShowUp = () => {
        setShowUp(true);
    }

    const handleShowDel = (data: any) => {
        setShowComfirmDel(true);
        setVal(data);
    }
    const handleCloseDel = () => setShowComfirmDel(false);

    const getTexts = (id: number) => {
        setLoad(true);
        handleShowUp();
        axios.get(`${baseUrl}api/parametre-texte/${id}`,{
          headers: Authorization
        }).then((response) => {
            // setDeviseItems(response.data.data.rows);
            setCodeUp(response.data.data.rows.code)
            setTitreUp(response.data.data.rows.titre)
            setValeurUp(response.data.data.rows.valeur);
            setTxtId(response.data.data.rows.id);
            setLoad(false);
        }).catch(err => {
            setLoad(false);
            console.log("err", err);
        });
    }

    const handleCloseComfirm = () => {
        setShowComfirm(false);
        setShow(false);
    }

    const handleCloseComfirmUp = () => {
        setShowComfirmUp(false);
        setShowUp(false);
    }

    const handleShowComfirm = () => {
        if(titre === "" && valeur === "") {
            setLoading(false);
            setShowComfirm(false);
            toast.error("Les champs sont vide, veuillez les remplire SVP!");
        }else {
            setShowComfirm(true);
            setShow(false);
        }
        
    }

    const handleShowComfirmUp = () => {
        if(titreUp === "" && valeurUp === "") {
            setLoading(false);
            setShowComfirmUp(false);
            toast.error("Les champs sont vide, veuillez les remplire SVP!");
        }else {
            setShowComfirmUp(true);
            setShowUp(false);
        }
        
    }

    const saveParams = () => {
        if(titre === "" && valeur === "") {
            setLoading(false);
    
            toast.error("Les champs sont vide, veuillez les remplire SVP!");
        }else{
            setLoading(true);
            axios.post(`${baseUrl}api/parametre-texte/create`, {
                titre: titre,
                valeur: valeur,
                code: code
            }, {headers: Authorization}).then(res => {
                if(res) {
                    setShow(false);
                    toast.success("Insertion effectuer avec succes!");
                    setShowComfirm(false);
                    window.location.reload();
                    setLoading(false);
                    setTitre("");
                    setCode("");
                    setValeur("");
    
                }
            }).catch(err => {
                setLoading(false);
                toast.error("Error", err);
    
            })
        }
        
    
        console.log("OK");
    }

    const updateParams = () => {
        if(titreUp === "" && valeurUp === "") {
            setLoading(false);
    
            toast.error("Les champs sont vide, veuillez les remplire SVP!");
        }else{
            setLoading(true);
            axios.put(`${baseUrl}api/parametre-texte/update/${txtId}`, {
                titre: titreUp,
                valeur: valeurUp,
                code: codeUp
            }, {headers: Authorization}).then(res => {
                if(res) {
                    setShow(false);
                    toast.success("Modification effectuer avec succes!");
                    setShowComfirmUp(false);
                    window.location.reload();
                    setLoading(false);
                    setTitre("");
                    setCode("");
                    setValeur("");
    
                }
            }).catch(err => {
                setLoading(false);
                toast.error("Error", err);
    
            })
        }
        
    
        console.log("OK");
    }

    const deleteText = (e: any) => {
        setLoading(true);
        axios.put(`${baseUrl}api/parametre-texte/update/${val.id}`, {
            status: 2
        }, {headers: Authorization}).then(res => {
            if(res) {
                setShow(false);
                toast.success("Suppression effectuer avec succes!");
                setShowComfirmDel(false);
                window.location.reload();
                setLoading(false);

            }
        }).catch(err => {
            setLoading(false);
            toast.error("Erreur de la suppression");

        })
    }
    
  return (
    <section className="content">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />
          {/* Same as */}
        <ToastContainer />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-4">
                        <div className="d-md-flex justify-content-between">
                            <div className="input-group input-group-lg _inputForm w-25">
                                {/* <input type="text" value={searchText} onChange={handleChange}  name="table_search" className="form-control float-right input-lg _inputSearch" placeholder="Search" />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-default">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div> */}
                            </div>
                            
                            <button className="btn btn-primary btn-lg float-left _btnAdd" onClick={handleShow}>
                                <i className='fas fa-plus pr-2'></i>
                                Ajouter text
                            </button>
                            
                            <div className="modal fade" id="modal-xl">
                                <div className="modal-dialog modal-xl"  style={{width: "1230px", margin: "auto", marginTop: "3rem"}}>
                                    {/* <AddArticle
                                        loading={loading}
                                        createArticle={createArticle}
                                        categorie={categorie}
                                        setCategorie={setCategorie}
                                        uploadImage={uploadImage}
                                        file={file}
                                        contenu={contenu}
                                        setContenu={setContenu}
                                    /> */}
                                </div>
                            </div>

                            <Modal
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                show={show}
                                onHide={handleClose}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Ajout text parametrage</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    <div className="form-group">
                                        <label htmlFor="code">Code</label>
                                        <input type="text" value={code} onChange={(e)=>setCode(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Sujet" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="code">Titre</label>
                                        <input type="text" value={titre} onChange={(e)=>setTitre(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Sujet" />
                                    </div>
                                    
                                    <div className="form-group">
                                        <label htmlFor="valeur">Valeur</label>
                                        <textarea className="form-control" value={valeur} onChange={(e)=>setValeur(e.target.value)} name="" id="" cols={30} rows={10}></textarea>
                                    
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="button" onClick={handleShowComfirm} className="btn btn-primary">Enregistrer</button>
                                </Modal.Footer>
                            </Modal>

                            <div className="modal fade" id="modal-lg">
                                <div className="modal-dialog modal-lg" style={{width: "1230px", margin: "auto", marginTop: "3rem"}}>
                                    {/* <UpdateArticle
                                        article={article}
                                        categoryes={categoryes}
                                        setCategoryes={setCategoryes}
                                        artId={artId}
                                        files={files}
                                        setFiles={setFiles}
                                        content={content}
                                        setContent={setContent}
                                        artVal={artVal}
                                        contentIner={contentIner}
                                    /> */}
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>
                {
                    loading ? (
                        <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0}/>
                    ) : (
                    <>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className='card-header'>
                                        <div className="d-flex col-md-6">
                                            <label htmlFor="">Afficher par</label>
                                            {/* <select id="inputState" onChange={(e)=>setArticlePerPage(e.target.value)} className="form-control w-auto ml-2">
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="75">75</option>
                                                <option value="100">100</option>
                                            </select> */}
                                        </div>
                                    </div>
                                    <div className="card-body table-responsive p-0"  style={{height: "63vh"}}>
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Titre</th>
                                                    <th scope="col">Valeur</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                allText && allText.length ? (
                                                    allText.filter((el: any)=> el.status && el.status.designation === "active").map((txt: any) => (
                                                               
                                                        <tr key={txt.id}>
                                                            <th scope="row">{txt.id}</th>
                                                            <td>{txt.titre ? txt.titre : "_"}</td>
                                                            <td>{txt.valeur ? txt.valeur.slice(0,100)+" ..." : '_'}</td>
                                                            <td>
                                                                <div>
                                                                    
                                                                    <button type="button" onClick={() => getTexts(txt.id)} data-toggle="tooltip"  className="btn btn-sm" data-placement="top" title="Modifier">
                                                                        <i className='fas fa-edit text-success'></i>
                                                                    </button>
                                                                    <button type="button" onClick={() => handleShowDel(txt)} data-toggle="tooltip"  className="btn btn-sm" data-placement="top" title="Supprimer">
                                                                        <i className='fas fa-trash text-danger'></i>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        
                                                    
                                                    ))
                                                ):(
                                                    <tr>
                                                        <td style={{textAlign: "center"}} colSpan={8}>Pas de donnée</td>
                                                    </tr>
                                                ) 
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="card-footer">
                                        <div className="float-right">
                                            {/* <Paginations 
                                                postPerPage={articlePerPage} 
                                                totalPages={articleItems.length}
                                                paginate={paginate}
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    )
                }
                
            </div>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showUp}
                onHide={handleCloseUp}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Modifier text parametrage</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {
                        load && load ? (
                            <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0}/>
                        ):(
                            <>
                                <div className="form-group">
                                    <label htmlFor="code">Code</label>
                                    <input type="text" value={codeUp} onChange={(e)=>setCodeUp(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Sujet" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="code">Titre</label>
                                    <input type="text" value={titreUp} onChange={(e)=>setTitreUp(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Sujet" />
                                </div>
                                
                                <div className="form-group">
                                    <label htmlFor="valeur">Valeur</label>
                                    <textarea className="form-control" value={valeurUp} onChange={(e)=>setValeurUp(e.target.value)} name="" id="" cols={30} rows={10}></textarea>
                                
                                </div>
                            </>
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={handleShowComfirmUp} className="btn btn-primary">Enregistrer</button>
                </Modal.Footer>
            </Modal>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showConfirm}
                onHide={handleCloseComfirm}
            >
                <AddComfirmation actionOK={saveParams}/>
            </Modal>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showConfirmUp}
                onHide={handleCloseComfirmUp}
            >
                <AddComfirmation actionOK={updateParams}/>
            </Modal>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showConfirmDel}
                onHide={handleCloseDel}
            >
                <DeleteComfirmation actionOK={(e: Event) => deleteText(e)}/>
            </Modal>
        </section>
  )
}

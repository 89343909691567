import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { QuartierComponent } from '../../Components/QuartierComponent/QuartierComponent'
import { QuartierContext } from '../../Context/AppContext';
import { baseUrl } from '../../services/Services';
import { Authorization } from '../../utils/headers/Authorization';
import { VilleType } from '../../utils/interface/LogementType';
import { QuartierType } from '../../utils/interface/QuartierType';

export const QuartierPages = () => {
  const [designation, setDesignation] = useState<QuartierType | any>("");
  const [ville, setVille] = useState<QuartierType | number | any>();
  const [allQuartier, setAllQuartier] = useState<QuartierType | any>([]);
  const [loading, setLoading] = useState<boolean | any>(false);

  const [allVille, setAllVille] = useState<VilleType | any>([]);

  useEffect(() => {
    setLoading(true);
    
    const getAllQuartier = async() => {
      await axios.get(`${baseUrl}api/quartier`, {
        headers: Authorization
      }).then((response: any) => {
        if(response){
          setLoading(false);
          console.log(response.data)
          setAllQuartier(response.data.data.rows);
        }
      }).catch((err) => {
        setLoading(false);
        console.log(err)
      })
    }
    getAllQuartier();
  }, []);

  //Create Quartier
  const createQuartier = (e: any) => {
    e.preventDefault();
    
    if(designation ===''){
      alert("Le champs designation est vide")
    } else {
      setLoading(true);
      axios.post(`${baseUrl}api/quartier`, {
        designation: designation,
        ville: parseInt(ville)
      }, {headers: Authorization}).then((response: any) => {
        setLoading(false);
        window.location.reload();
        setDesignation("");
      }).catch(function (error) {
        setLoading(false);
        console.log(error);
      });
    }
  }

  useEffect(() => {
    setLoading(true);
    
    const getAllVille = async() => {
      await axios.get(`${baseUrl}api/ville`, {
        headers: Authorization
      }).then((response: any) => {
        if(response){
          setLoading(false);
          setAllVille(response.data.data.rows)
        }
      }).catch((err) => {
        setLoading(false);
        console.log(err)
      })
    }
    getAllVille();
  }, []);
  return (
    <QuartierContext.Provider value={allQuartier}>
        <QuartierComponent
          designation={designation}
          setDesignation={setDesignation}
          ville={ville}
          setVille={setVille}
          loading={loading}
          createQuartier={createQuartier}
          allVille={allVille}
        />
    </QuartierContext.Provider>
  )
}

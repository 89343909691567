import React from 'react'
import { LogementComponents } from '../../Components/LogementComponent/LogementComponents'
interface Props {}
export const LogementPages: React.FC<Props> = ()=> {
  return (
      <div>
        <LogementComponents/>
      </div>
  )
}

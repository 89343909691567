import React from 'react'
interface Props {

}
export const DetailHistorique = () => {
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Détails du paiements</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="index.html">Paiements</a></li>
                                <li className="breadcrumb-item active">Paiements v1</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

import React from 'react'
import { Modal } from 'react-bootstrap'
interface Props {
    actionOK: any
}
export const AddComfirmation: React.FC<Props> = ({actionOK}) => {
  return (
    <div>
        <Modal.Header closeButton>
            <Modal.Title>Message de comfirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h1>Comfirmation </h1>
        </Modal.Body>
        <Modal.Footer>
            <button type="button" onClick={actionOK} className="btn btn-primary">Comfirmer</button>
        </Modal.Footer>
    </div>
  )
}

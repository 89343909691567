import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { UserComponent } from '../../Components/UserComponent/UserComponent'
import { UserContext } from '../../Context/AppContext';
import { baseUrl } from '../../services/Services';
import { ApiUrl } from '../../utils/apiUrl';
import { Authorization } from '../../utils/headers/Authorization';
import { UserType } from '../../utils/interface/UserTytpe';
import { ValueStatus } from '../../utils/utils';

export const UserPages = () => {

  const [email, setEmail] = useState<UserType>();
  const [password, setPassword] = useState<UserType>();
  const [nom, setNom] = useState<UserType>();
  const [prenom, setPrenom] = useState<UserType>();
  const [adresse, setAdresse] = useState<UserType>();
  const [telephone, setTelephone] = useState<UserType>();

  // const [userData,setUserData] = useState<UserType | any>();

  const [userLists, setUserLists] = useState<UserType | any>([]);
  const [loading, setLoading] = useState<boolean | any>(false);
  const [searchText, setSearchText] = useState<string | any>('');
  const [currentPage, setCurrentPage] = useState<any>(1)
  const [userPerPage, setUserPerPage] = useState<any>(10);
  const [status, setStatus] = useState<any>();

  //Get all users

  useEffect(() => {
    setLoading(true);

    // const getAllUsers = async () => {
    //   await axios.get(`${baseUrl}${ApiUrl.user}`, {
    //     headers: Authorization
    //   }).then((response: any) => {
    //     if (response) {
    //       setLoading(false);
    //       console.log(response.data.data.rows)

    //       setUserLists(response.data.data.rows);
    //     }
    //   }).catch((err) => {
    //     setLoading(false);
    //     console.log(err)
    //   })
    // }
    getAllUsers();
  }, []);


  const getAllUsers = async () => {
    setLoading(true);

    await axios.get(`${baseUrl}${ApiUrl.user}`, {
      headers: Authorization
    }).then((response: any) => {
      if (response) {
        setLoading(false);
        console.log(response.data.data.rows)

        setUserLists(response.data.data.rows);
      }
    }).catch((err) => {
      setLoading(false);
      console.log(err)
    })
  }

  console.log(userLists)

  const createUser = (e: any) => {
    e.preventDefault();
    setLoading(true);
    axios.post(`${baseUrl}${ApiUrl.user}`, {

      email: email,
      password: password,
      nom: nom,
      prenom: prenom,
      adresse: adresse,
      telephone: telephone


    }, { headers: Authorization }).then((response: any) => {
      setLoading(false);
      if (response.data.success) {

      } else {
        alert('email efa ao io')
      }
      console.log('nety', response);
      getAllUsers();


    }).catch(function (error) {
      setLoading(false);
      console.log('tsy nety', error);
    });
  }

  const changeStatus = (id: number, checked: boolean) => {
    setLoading(true);

    let status: number = 0;

    checked ? status = ValueStatus.active : status = ValueStatus.desactive
    // alert(status)

    axios.put(`${baseUrl}${ApiUrl.user}/${id}`, {

      status: status
    }, {
      headers: Authorization
    }).then((response) => {
      console.log(response);
      getAllUsers();
      // setLoading(false);

    });

  }

  const dataFilter: any = userLists.filter((users: UserType) => {
    const username = users.nom + users.prenom;
    console.log(username)
    return users.nom.toLowerCase().includes(searchText.toLowerCase()) ||
      users.nom.toLowerCase().includes(searchText.toLowerCase()) ||
      users.prenom.toLowerCase().includes(searchText.toLowerCase()) ||
      users.email.toLowerCase().includes(searchText.toLowerCase()) ||
      users.adresse.toLowerCase().includes(searchText.toLowerCase())
    // users.phone.toLowerCase().includes(searchText.toLowerCase()) ||
    // users.role.designation.toLowerCase().includes(searchText.toLowerCase())
  });


  /**
   * Pagination
   */
  const indexOfLastPage = currentPage * userPerPage;
  const IndexOfFirstPage = indexOfLastPage - userPerPage;
  const currentUser = dataFilter.slice(IndexOfFirstPage, indexOfLastPage)

  const paginate = (numberPage: any) => {
    setCurrentPage(numberPage)
  }

  /**
   * Pour la recherche des ville
   */
  const handleChange = (e: any) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };

  return (
    <UserContext.Provider value={currentUser}>
      <UserComponent
        users={userLists}
        loading={loading}
        paginate={paginate}
        createUser={createUser}
        changeStatus={changeStatus}
        password={password}
        totalPage={userLists}
        setUserLists={setUserLists}
        searchText={searchText}
        handleChange={handleChange}
        setUserPerPage={setUserPerPage}
        userPerPage={userPerPage}
        setEmail={setEmail}
        setPassword={setPassword}
        setNom={setNom}
        setPrenom={setPrenom}
        setAdresse={setAdresse}
        setTelephone={setTelephone} />
    </UserContext.Provider>
  )
}

import React, { useState } from 'react'
import {LoginComponent} from '../../Components/LoginComponent/LoginComponent'
import { RouterPath } from '../../utils/route/route';
import axios from 'axios';
import { baseUrl } from '../../services/Services';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// interface Props {
//     setIsAuth: React.Dispatch<React.SetStateAction<boolean>>
// }

export const LoginPage: React.FC = () => {
    const [isLoggedIn, setLoggedIn] = useState<boolean | any>(false);
    const [setIsError] = useState<boolean | any>(false);
    // const [isLoading, setIsLoading] = useState<boolean | any>(false);
    const [validated, setValidated] = useState<boolean | any>(false);
    const [email, setEmail] = useState<string | any>("");
    const [password, setPassword] = useState<string | any>("");

    const navigate = useNavigate();

    // let admin: any = localStorage.getItem('currentAdmin');
    
    //on login

    window.addEventListener("popstate", () => {
        window.history.pushState(null, window.location.pathname);
    });
    
    const handleSubmit = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        // setIsLoading(true);
        
        axios
        .post(`${baseUrl}api/login`, {
            email,
            password,
        })
        .then((result: any) => {
            console.log(result.data)

            if (result.data.success === true) {

                // setIsAuth(true);
                
               
                const token: any = result.data.data.rows;

                localStorage.setItem('currentAdmin',JSON.stringify({ data: token }));

                setLoggedIn(true);
                
                // window.location.href = RouterPath.home;

            } else if(result.data.emailNotFound) {

                toast.error(result.data.message);

            } else if(result.data.emailNotFound === false) {

                toast.success(result.data.message);

            }

            // setIsLoading(false);
        })
        
        .catch((e) => {
            setIsError(true);
            // setIsLoading(false);
        });
        if(email === '' && password === ''){
            // alert("Les champ sont vide")
            toast.error("Les champ sont vide");
        }else if(email === ''){
            // alert("Le champs email est vide")
            toast.error("Le champs email est vide");
        }
        else if(password ===''){
            // alert("Le champs password est vide")
            toast.error("Le champs password est vide");
        }
    
        setValidated(true);
    };

    

    if (isLoggedIn) {
        // navigate(RouterPath.home);
        window.location.href = RouterPath.home;
    }

    return (
        <div className="hold-transition login-page">
            <LoginComponent
                validated={validated}
                setValidated={setValidated}
                handleSubmit={handleSubmit}
                email={email}
                password={password}
                setEmail={setEmail}
                setPassword={setPassword}
            />
        </div>
    )
}

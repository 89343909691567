export const RouterPath = {
    login: '/',
    home: '/home',
    logement: '/logements',
    article: '/article',
    users: '/users',
    client: '/client',
    reservation: '/reservation',
    detailReservation: '/reservation',
    equipement: '/equipements',
    historique: '/historique-paiement',
    historiquePDetail: '/historique-paiement-detail',
    ville: '/ville',
    quartier: '/quartier',
    user: '/user',
    categorie: '/categorie',
    articleDetail: '/article',
    logementDetail: '/logements',
    contact: "/contact",
    devise: "/parametre-devise",
    parametrageText: "/parametre-text"
}

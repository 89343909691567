import React, { useContext, useState } from 'react'
import { QuartierContext } from '../../../../Context/AppContext'
import { VilleType } from '../../../../utils/interface/LogementType'
import { QuartierType } from '../../../../utils/interface/QuartierType'
import { DeleteComfirmation } from '../../../DeleteComfirmation/DeleteComfirmation'
import { Paginations } from '../../../pagination/Paginations'
import { AddQuartier } from '../AddQuartier/AddQuartier';
import ReactLoading from 'react-loading';
import axios from 'axios'
import { baseUrl } from '../../../../services/Services'
import { Authorization } from '../../../../utils/headers/Authorization'
import { EditQuartier } from '../EditQuartier/EditQuartier'
import { Modal } from 'react-bootstrap'
interface Props {
    designation: QuartierType,
    setDesignation: any,
    ville: QuartierType,
    setVille: any
    loading: boolean
    createQuartier: any,
    allVille: VilleType
}
export const ListQuartier: React.FC<Props> = ({
    designation,
    setDesignation,
    ville,
    setVille,
    loading,
    createQuartier,
    allVille
}) => {

    const [searchText, setSearchText] = useState<string | any>('');
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [quartierPerPage, setquartierPerPage] = useState<any>(10);

    const [quartierItem, setQuartierItem] = useState<QuartierType | any>([]);
    const [desi, setDesi] = useState<string | any>("");
    const [vil, setVil] = useState<string | any>("");
    const [villeId, setVId] = useState<number | any>();
    const [qId, setQId] = useState<number | any>();

    const [showConfirmDel, setShowComfirmDel] = useState<boolean>(false);
    const [delQuartier, setDelQuartier] = useState<any>([]);

    const handleShowDel = (data: any) => {
        setShowComfirmDel(true);
        setDelQuartier(data);
    }
    const handleCloseDel = () => setShowComfirmDel(false);

    const allQuartier = useContext(QuartierContext)

    const dataFilter = allQuartier.filter((name: any) => {
        return name.designation.toLowerCase().includes(searchText.toLowerCase()) ||
            name.ville.designation.toLowerCase().includes(searchText.toLowerCase())
    })
    
      /**
       * Pagination
       */
    const indexOfLastPage = currentPage * quartierPerPage;
    const IndexOfFirstPage = indexOfLastPage - quartierPerPage;
    const currentQuartier = dataFilter.slice(IndexOfFirstPage, indexOfLastPage)
    
    const paginate = (numberPage: any)=> {
        setCurrentPage(numberPage)
    }
    
      /**
       * Pour la recherche des ville
       */
    const handleChange = (e: any) => {
        e.preventDefault();
        setSearchText(e.target.value);
    };

    const getQuartier = (id: number) => {
        axios.get(`${baseUrl}api/quartier/${id}`,{
          headers: Authorization
        }).then((response) => {
            setQuartierItem(response.data.data.rows);
            setQId(response.data.data.rows.id)
            setDesi(response.data.data.rows.designation)
            setVil(response.data.data.rows.ville && response.data.data.rows.ville.designation);
            setVId(response.data.data.rows.ville && response.data.data.rows.ville.id);
        });
    }

    const deleteQuartier = async(e: any) => {
        e.preventDefault();
        await axios.put(`${baseUrl}api/quartier/${delQuartier.id}`, {
            status: 2
        })
        .then((response: any) => {
            window.location.reload();
        })
        .catch((error: any) => {
            console.log(error)
        });
    }

    return (
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-4">
                        <div className="d-md-flex justify-content-between">
                            <div className="input-group input-group-lg _inputForm w-25">
                                <input type="text" value={searchText} onChange={handleChange} name="table_search" className="form-control float-right input-lg _inputSearch" placeholder="Search" />

                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-default">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                            <button className="btn btn-primary btn-lg float-left _btnAdd" data-toggle="modal" data-target="#modal-lg">
                                <i className='fas fa-plus pr-2'></i>
                                Ajouter quartier
                            </button>
                            <div className="modal fade" id="modal-lg">
                                <div className="modal-dialog modal-lg">
                                    <AddQuartier
                                        designation={designation}
                                        setDesignation={setDesignation}
                                        ville={ville}
                                        setVille={setVille}
                                        createQuartier={createQuartier}
                                        allVille={allVille}
                                    />
                                </div>
                            </div>
                            <div className="modal fade" id="modal-update">
                                <div className="modal-dialog modal-update">
                                    <EditQuartier
                                        designation={desi}
                                        vil={vil}
                                        setVil={setVil}
                                        setDesignation={setDesi}
                                        villes={quartierItem}
                                        allVille={allVille}
                                        villeId={villeId}
                                    />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                {
                    loading ? (
                        <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0}/>
                    ):(
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                            <div className="d-flex col-md-6">
                                                <label htmlFor="">Afficher par</label>
                                                <select id="inputState" onChange={(e)=>setquartierPerPage(e.target.value)} className="form-control w-auto ml-2">
                                                    <option value="10">10</option>
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                    <option value="75">75</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                        </div>
                                    <div className="card-body table-responsive p-0" style={{height: "63vh"}}>
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Quartier</th>
                                                    <th scope="col">Ville</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            { currentQuartier ? (
                                                <>
                                                    {
                                                        currentQuartier.map((quartier: QuartierType) => (
                                                            <tbody key={quartier.id}>
                                                                <tr>
                                                                    <th scope="row">{quartier.id}</th>
                                                                    <td>{quartier.designation}</td>
                                                                    <td>{quartier.ville ? quartier.ville.designation : '-'}</td>
                                                                    <td>
                                                                        {
                                                                            quartier.status &&  quartier.status.designation === "active" ? (
                                                                                <span className="badge badge-success">
                                                                                    {quartier.status && quartier.status.designation}
                                                                                </span>
                                                                            ):(
                                                                                <span className="badge badge-warning">
                                                                                    {quartier.status && quartier.status.designation}
                                                                                </span>
                                                                            )
                                                                        }
                                                                    </td>
                                                                            
                                                                            {/* <button type="button" className="btn btn-sm" data-toggle="tooltip" data-placement="top" title="Consulter">
                                                                                <i className='fas fa-eye text-primary'></i>
                                                                            </button> */}
                                                                        
                                                                    
                                                                    <td>
                                                                        {
                                                                            quartier.status && quartier.status.designation === "active" && (
                                                                                <button type="button" onClick={() => getQuartier(quartier.id)} data-toggle="modal" data-target="#modal-update" className="btn btn-sm" data-placement="top" title="Modifier">
                                                                                    <i className='fas fa-edit text-success'></i>
                                                                                </button>
                                                                            )
                                                                        }

                                                                        <button type="button" className="btn btn-sm" onClick={() => handleShowDel(quartier)} data-placement="top" title="Supprimer">
                                                                            <i className='fas fa-trash text-danger'></i>
                                                                        </button>
                                                                        
                                                                    </td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        ))
                                                    }
                                                </>
                                            ):(
                                                <tbody>
                                                    <tr>
                                                        <td className="text-danger">
                                                            Vide
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ) }
                                        </table>
                                    </div>
                                    <div className="card-footer">
                                        <div className="float-right">
                                            <Paginations 
                                                postPerPage={quartierPerPage} 
                                                totalPages={allQuartier.length}
                                                paginate={paginate}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showConfirmDel}
                onHide={handleCloseDel}
            >
                <DeleteComfirmation actionOK={deleteQuartier}/>
            </Modal>
        </section>
    )
}

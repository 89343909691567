import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import './core-ux/List.css';
import 'react-toastify/dist/ReactToastify.css';
import HomePages from './Pages/HomePages/HomePages';
import { 
  RouterPath 
} from './utils/route/route';
import { LoginPage } from './Pages/LoginPage/LoginPage';
import { NavApp } from './Pages/Layout/Navbar/NavApp';
import { Sidebar } from './Pages/Layout/Sidebar/Sidebar';
import { LogementPages } from './Pages/LogementPages/LogementPages';
import { ReservationPages } from './Pages/ReservationPages/ReservationPages';
import { ClientPages } from './Pages/ClientPages/ClientPages';
import { ArticlePages } from './Pages/ArticlePages/ArticlePages';
import { HistoriquePPage } from './Pages/HistoriquePage/HistoriquePage';
import { EquipementPages } from './Pages/EquipementPages/EquipementPages';
import { QuartierPages } from './Pages/QuartierPages/QuartierPages';
import { VillePages } from './Pages/VillePages/VillePages';
import { UserPages } from './Pages/UserPages/UserPages';
import ProtectedRoute from './Auth/ProtectedRoute';
import { CategoriePages } from './Pages/CategoriePages/CategoriePages';
import DetailArticle from './Pages/ArticlePages/DetailArticle';
import { DetailsLogement } from './Pages/LogementPages/DetailsLogement';
import ContactPages from './Pages/ContactPages/ContactPages';
import DevisePage from './Pages/DevisePage/DevisePage';
import { ParametragetextPage } from './Pages/PageParametrageText/ParametragetextPage';
// import { toast } from 'react-toastify';
import { DetailPaiementPages } from './Pages/HistoriquePage/DetailPaiement';
import DetailReservation from './Pages/ReservationPages/DetailReservation';
import { DetailHistorique } from './Components/HistoriquePComponent/DetailHistorique';
function App() {

  
  const [user] = useState<any | undefined>(JSON.parse(localStorage.getItem('currentAdmin') || '{}'));
  let users = localStorage.getItem('currentAdmin');

  const onBackButtonEvent = (e: any) => {
    e.preventDefault();
    window.history.pushState(null,'', window.location.pathname);
  }

   //Get all ville
   const parseJwt = (token: any) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    window.history.pushState(null,'', window.location.pathname);

    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);  
    };
  }, []);

  useEffect(() => {
    
    if(users === null) {
      // window.location.href = ('/');
      
      // window.history.pushState(null, '/');
    }

    const decodedJwt = parseJwt(user.data  && user.data.token);
    
    if(decodedJwt && decodedJwt.exp < Date.now() / 1000){

      localStorage.removeItem('currentAdmin');
      // window.history.pushState(null, '/');
      window.location.href = ('/');

    }


    if(decodedJwt === null) {
      window.history.pushState(null, '/');
    }
    
  }, [users, user]);

 

  
  

  window.addEventListener("popstate", () => {
    
    window.history.pushState(null,'', window.location.pathname);

  });

  
  
  
  if (user.data && user.data.token !== undefined) {

    return (
      <BrowserRouter>
        <NavApp />
        <Sidebar />
        <Routes>

          <Route path={RouterPath.home} element={<ProtectedRoute user={user}/>}>
              <Route path={RouterPath.home} element={<HomePages/>}/>
          </Route>

          <Route path={RouterPath.logement} element={<ProtectedRoute user={user}/>}>
              <Route path={RouterPath.logement} element={<LogementPages/>}/>
          </Route>

          <Route path={`${RouterPath.logementDetail}/:id/details`} element={<ProtectedRoute user={user}/>}>
              <Route path={`${RouterPath.logementDetail}/:id/details`} element={<DetailsLogement/>}/>
          </Route>

          <Route path={RouterPath.reservation} element={<ProtectedRoute user={user}/>}>
              <Route path={RouterPath.reservation} element={<ReservationPages/>}/>
          </Route>
          <Route path={`${RouterPath.detailReservation}/:id/details`} element={<ProtectedRoute user={user}/>}>
              <Route path={`${RouterPath.detailReservation}/:id/details`} element={<DetailReservation/>}/>
          </Route>

          <Route path={RouterPath.client} element={<ProtectedRoute user={user}/>}>
              <Route path={RouterPath.client} element={<ClientPages/>}/>
          </Route>

          <Route path={RouterPath.article} element={<ProtectedRoute user={user}/>}>
              <Route path={RouterPath.article} element={<ArticlePages/>}/>
          </Route>

          <Route path={`${RouterPath.articleDetail}/:id/details`} element={<ProtectedRoute user={user}/>}>
              <Route path={`${RouterPath.articleDetail}/:id/details`} element={<DetailArticle/>}/>
          </Route>

          <Route path={RouterPath.historique} element={<ProtectedRoute user={user}/>}>
              <Route path={RouterPath.historique} element={<HistoriquePPage/>}/>
          </Route>

          <Route path={`${RouterPath.historiquePDetail}/:idPaiement`} element={<ProtectedRoute user={user}/>}>
              <Route path={`${RouterPath.historiquePDetail}/:idPaiement`} element={<DetailHistorique/>}/>
          </Route>

          <Route path={RouterPath.equipement} element={<ProtectedRoute user={user}/>}>
              <Route path={RouterPath.equipement} element={<EquipementPages/>}/>
          </Route>

          <Route path={RouterPath.quartier} element={<ProtectedRoute user={user}/>}>
              <Route path={RouterPath.quartier} element={<QuartierPages/>}/>
          </Route>

          <Route path={RouterPath.ville} element={<ProtectedRoute user={user}/>}>
              <Route path={RouterPath.ville} element={<VillePages/>}/>
          </Route>

          <Route path={RouterPath.user} element={<ProtectedRoute user={user}/>}>
              <Route path={RouterPath.user} element={<UserPages/>}/>
          </Route>

          <Route path={RouterPath.categorie} element={<ProtectedRoute user={user}/>}>
              <Route path={RouterPath.categorie} element={<CategoriePages/>}/>
          </Route>

          <Route path={RouterPath.contact} element={<ProtectedRoute user={user}/>}>
              <Route path={RouterPath.contact} element={<ContactPages/>}/>
          </Route>

          <Route path={RouterPath.devise} element={<ProtectedRoute user={user}/>}>
              <Route path={RouterPath.devise} element={<DevisePage/>}/>
          </Route>

          <Route path={RouterPath.parametrageText} element={<ProtectedRoute user={user}/>}>
              <Route path={RouterPath.parametrageText} element={<ParametragetextPage/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }else{
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<LoginPage/>} />
        </Routes>
      </BrowserRouter>
    )
  }
  
  

  
}

export default App;

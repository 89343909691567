import React, { useContext, useState } from 'react'
import { EquipementContext } from '../../../../Context/AppContext'
import { EquipementType } from '../../../../utils/interface/EquipementType'
import { DeleteComfirmation } from '../../../DeleteComfirmation/DeleteComfirmation'
import { AddEquipement } from '../AddEquipement/AddEquipement';
import ReactLoading from 'react-loading';
import { Paginations } from '../../../pagination/Paginations';
import { EditEquipement } from '../EditEquipement/EditEquipement';
import axios from 'axios';
import { baseUrl } from '../../../../services/Services';
import { Authorization } from '../../../../utils/headers/Authorization';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
interface Props {
    designation: EquipementType | any,
    description: EquipementType | any,
    setDesignation: any,
    setDescription: any,
    createEquipement: any,
    loading: boolean,
    setLoading: any
}
export const ListEquipement: React.FC<Props> = ({
    designation,
    description,
    setDesignation,
    setDescription,
    createEquipement,
    loading,
    setLoading
}) => {
    const equipements = useContext(EquipementContext);

    const [searchText, setSearchText] = useState<string | any>('');
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [equipPerPage, setEquipPerPage] = useState<any>(10);

    const [equipement, setEquipement] = useState<EquipementType[]>([]);

    const [desi, setDesi] = useState<EquipementType | any>("");
    const [desc, setDesc] = useState<EquipementType | any>("");
    const [eq, setEq] = useState<any>([]);

    const [showModal, setShowModal] = useState<boolean | any>(false);
    const [showConfirmDel, setShowComfirmDel] = useState<boolean>(false);
    const [eqDel, setEqDel] = useState<any>([]);


    const handleCloseDel = () => setShowComfirmDel(false);

    const handleShowDel = (data: any) => {
        setShowComfirmDel(true);
        setEqDel(data);
    }


    const dataFilter = equipements.filter((name: any) => {
        return name.designation.toLowerCase().includes(searchText.toLowerCase()) ||
            name.description.toLowerCase().includes(searchText.toLowerCase())
    })
    
      /**
       * Pagination
       */
    const indexOfLastPage = currentPage * equipPerPage;
    const IndexOfFirstPage = indexOfLastPage - equipPerPage;
    const currentEquip = dataFilter.slice(IndexOfFirstPage, indexOfLastPage)
    
    const paginate = (numberPage: any)=> {
        setCurrentPage(numberPage)
    }

    const getEquipementUp = (id: number) => {
        axios.get(`${baseUrl}api/equipement/${id}`,{
          headers: Authorization
        }).then((response) => {
            setEquipement(response.data.data.rows);
            setShowModal(true);
            setDesc(response.data.data.rows.description)
            setDesi(response.data.data.rows.designation)
        });
    }
    
      /**
       * Pour la recherche des ville
       */
    const handleChange = (e: any) => {
        e.preventDefault();
        setSearchText(e.target.value);
    };

    

    const deleteEquipement = async(e: any) => {
        setLoading(true);
        await axios.delete(`${baseUrl}api/equipement/delete/${eqDel.id}`, {headers: Authorization}).then(res => {
            if(res) {
                toast.success(`Suppression de de l'equipement ${eq.designation} effectué avec succes!`);
                window.location.reload();
                setLoading(false);
                setShowComfirmDel(false)
            }
        }).catch(err => {
            setLoading(false);
            toast.warning(`Erreur lors de la suppression de de l'equipement ${eq.designation}!`)
        })
    }

    return (
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-4">
                        <div className="d-md-flex justify-content-between">
                            <div className="input-group input-group-lg _inputForm w-25">
                                <input type="text" value={searchText} onChange={handleChange} name="table_search" className="form-control float-right input-lg _inputSearch" placeholder="Search" />

                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-default">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                            <button className="btn btn-primary btn-lg float-left _btnAdd" data-toggle="modal" data-target="#modal-lg">
                                <i className='fas fa-plus pr-2'></i>
                                Ajouter equipement
                            </button>
                            <div className="modal fade" id="modal-lg">
                                <div className="modal-dialog modal-lg">
                                    <AddEquipement
                                        designation= {designation}
                                        description={description}
                                        setDesignation={setDesignation}
                                        setDescription={setDescription}
                                        createEquipement={createEquipement}
                                        setLoading={setLoading}
                                    />
                                </div>
                            </div>

                            <div className="modal fade" id="modal-update">
                                <div className="modal-dialog modal-update">
                                    <EditEquipement
                                        designation= {desi}
                                        description={desc}
                                        setDesignation={setDesi}
                                        setDescription={setDesc}
                                        createEquipement={createEquipement}
                                        equipement={equipement}
                                        setLoading={setLoading}
                                    />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                {
                    loading ? (
                        <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0}/>
                    ) : (
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                            <div className="d-flex col-md-6">
                                                <label htmlFor="">Afficher par</label>
                                                <select id="inputState" onChange={(e)=>setEquipPerPage(e.target.value)} className="form-control w-auto ml-2">
                                                    <option value="10">10</option>
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                    <option value="75">75</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                        </div>
                                    <div className="card-body table-responsive p-0" style={{height: "63vh"}}>
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Designation</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            {
                                                currentEquip ? (
                                                    <>
                                                        {
                                                            currentEquip.map((equipement: EquipementType | any) => (
                                                                <tbody key={equipement.id}>
                                                                    <tr>
                                                                        <th scope="row">{equipement.id}</th>
                                                                        <td>{equipement.designation}</td>
                                                                        <td>{equipement.description}</td>
                                                                        <td>
                                                                            <div>
                                                                                <button type="button" onClick={() => getEquipementUp(equipement.id)} data-toggle="modal" data-target="#modal-update" className="btn btn-sm" data-placement="top" title="Modifier">
                                                                                    <i className='fas fa-edit text-success'></i>
                                                                                </button>
                                                                                <button onClick={()=> handleShowDel(equipement)} type="button" className="btn btn-sm" data-placement="top" title="Supprimer">
                                                                                    <i className='fas fa-trash text-danger'></i>
                                                                                </button> 
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    
                                                                </tbody>
                                                            ))
                                                        }
                                                    </>
                                                ):(
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-danger">
                                                                Vide
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                ) 
                                            }
                                                
                                                
                                        </table>
                                    </div>
                                    <div className="card-footer">
                                        <div className="float-right">
                                            <Paginations
                                                postPerPage={equipPerPage} 
                                                totalPages={equipements.length}
                                                paginate={paginate}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showConfirmDel}
                onHide={handleCloseDel}
            >
                <DeleteComfirmation actionOK={(e: Event) => deleteEquipement(e)}/>
            </Modal>

        </section>
    )
}

import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'


interface Props {
    email: string,
    password: string,
    nom: string,
    prenom: string,
    adresse: string,
    telephone: string,
    setEmail: any,
    setPassword: any,
    setNom: any,
    setPrenom: any,
    setAdresse: any,
    setTelephone: any,
    createUser: any

}
export const AddUser: React.FC<Props> = ({ email, password, nom, prenom, adresse, telephone, setEmail, setPassword, setNom, setPrenom, setAdresse, setTelephone, createUser }) => {
    let btn = document.getElementById('addBtn');

    if (createUser) {
        btn?.setAttribute("data-dismiss", "modal")
    }

    return (
        <div>
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Ajouter utilisateur</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Form noValidate>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Form.Group controlId="validationCustomUsername" className='mb-4'>
                                    <Form.Label>Nom</Form.Label>
                                    <InputGroup hasValidation>

                                        <Form.Control
                                            type="text"
                                            placeholder="Nom de l'utilisateur"
                                            aria-describedby="inputGroupPrepend"
                                            value={nom}
                                            onChange={(e) => setNom(e.target.value)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please choose a name.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </div>
                            <div className="col-12 col-md-6">
                                <Form.Group controlId="validationCustomUsername" className='mb-4'>
                                    <Form.Label>Prénoms</Form.Label>
                                    <InputGroup hasValidation>

                                        <Form.Control
                                            type="text"
                                            placeholder="Prénom de l'utilisateur"
                                            aria-describedby="inputGroupPrepend"
                                            value={prenom}
                                            onChange={(e) => setPrenom(e.target.value)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please choose a lastname.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Form.Group controlId="validationCustomUsername" className='mb-4'>
                                    <Form.Label>Adresse email</Form.Label>
                                    <InputGroup hasValidation>

                                        <Form.Control
                                            type="email"
                                            placeholder="Adresse email de l'utilisateur"
                                            aria-describedby="inputGroupPrepend"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please choose a email adress.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </div>

                            <div className="col-12 col-md-6">
                                <Form.Group controlId="validationCustomUsername" className='mb-4'>
                                    <Form.Label>Télephone</Form.Label>
                                    <InputGroup hasValidation>

                                        <Form.Control
                                            type="tel"
                                            placeholder="Téléphone de l'utilisateur"
                                            aria-describedby="inputGroupPrepend"
                                            value={telephone}
                                            onChange={(e) => setTelephone(e.target.value)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please choose a phone number.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <Form.Group controlId="validationCustomUsername" className='mb-4'>
                                <Form.Label>Adresse </Form.Label>
                                <InputGroup hasValidation>

                                    <Form.Control
                                        type="text"
                                        placeholder="Adresse de l'utilisateur"
                                        aria-describedby="inputGroupPrepend"
                                        value={adresse}
                                        onChange={(e) => setAdresse(e.target.value)}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please choose a email adress.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Form.Group controlId="validationCustomUsername" className='mb-4'>
                                    <Form.Label>Mot de passe</Form.Label>
                                    <InputGroup hasValidation>

                                        <Form.Control
                                            type="password"
                                            placeholder="Mot de passe de l'utilisateur"
                                            aria-describedby="inputGroupPrepend"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please choose a password.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </div>

                        </div>
                    </Form>
                </div>
                <div className="modal-footer justify-content-between">
                    <button type="button" className="btn btn-default" data-dismiss="modal">Fermer</button>
                    <button type="button" id="addBtn" onClick={createUser} className="btn btn-primary">Enregistrer</button>
                </div>
            </div>
        </div>
    )
}

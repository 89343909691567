import React, { useState } from 'react'
import ReactLoading from 'react-loading';
import { toast, ToastContainer } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { baseUrl } from '../../../services/Services';
import { Authorization } from '../../../utils/headers/Authorization';
import { AddComfirmation } from '../../AddConfirmation/AddComfirmation';
import { DeleteComfirmation } from '../../DeleteComfirmation/DeleteComfirmation';

interface Props {
    devise: any[],
    loading: any,
    setLoading: any
}
export const ListDevise: React.FC<Props> = ({loading, devise, setLoading}) => {

    const [code, setCode] = useState<string | any>("");
    const [designation, setDesignation] = useState<string | any>("");
    const [valeur, setValeur] = useState<string | any>("");

    const [codeUp, setCodeUp] = useState<string | any>("");
    const [designationUp, setDesignationUp] = useState<string | any>("");
    const [valeurUp, setValeurUp] = useState<string | any>("");
    const [devId, setDevId] = useState<string | any>("");


    const [show, setShow] = useState<boolean>(false);
    const [showConfirm, setShowComfirm] = useState<boolean>(false);
    const [showConfirmUp, setShowComfirmUp] = useState<boolean>(false);
    const [showConfirmDel, setShowComfirmDel] = useState<boolean>(false);



    const [showUp, setShowUp] = useState<boolean>(false);

    // const [deviseItems, setDeviseItems] = useState<any>([]);

    const [loadingDev, setLoadingdev] = useState<boolean>(false);

    const [delDevise, setDeviseDel] = useState<any>([]);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleShowDel = (data: any) => {
        setShowComfirmDel(true);
        setDeviseDel(data);
    }
    const handleCloseDel = () => setShowComfirmDel(false);


    const handleCloseUp = () => setShowUp(false);

    
    const handleShowUp = () => {
        setShowUp(true);
    }

    const getDevise = (id: number) => {
        setLoadingdev(true);
        handleShowUp();
        axios.get(`${baseUrl}api/devise/${id}`,{
          headers: Authorization
        }).then((response) => {
            // setDeviseItems(response.data.data.rows);
            setCodeUp(response.data.data.rows.code)
            setDesignationUp(response.data.data.rows.devise)
            setValeurUp(response.data.data.rows.valeurEnAriary);
            setDevId(response.data.data.rows.id);
            setLoadingdev(false);
        }).catch(err => {
            setLoadingdev(false);
        });
    }
    
    const handleCloseComfirm = () => {
        setShowComfirm(false);
        setShow(false);
    }

    const handleCloseComfirmUp = () => {
        setShowComfirmUp(false);
        setShow(false);
    }

     /**
     * @author Freddy
     * @description show comfirm modal on save
     */
    const handleShowComfirm = () => {
        if(code === "" && designation === "" && valeur === "") {
            setLoading(false);
            setShowComfirm(false);
            toast.error("Les champs sont vide, veuillez les remplire SVP!");
        }else {
            setShowComfirm(true);
            setShow(false);
        }
        
    }

    /**
     * @author Freddy
     * @description show comfirm modal on update
     */

    const handleShowComfirmUp = () => {
        if(codeUp === "" && designationUp === "" && valeurUp === "") {
            setShowComfirmUp(false);
            toast.error("Les champs sont vide, veuillez les remplire SVP!");
        }else {
            setShowComfirmUp(true);
            setShowUp(false);
        }
        
    }

    /**
     * @author Freddy
     * @description update Devise
     * @param e 
     */

    const updateDevise = (e: Event) => {

        setLoading(true);
        axios.put(`${baseUrl}api/devise/update/${devId}`, {
            code: codeUp,
            devise: designationUp,
            valeurEnAriary:parseFloat(valeurUp)
        }, {headers: Authorization}).then(res => {
            if(res) {
                setShow(false);
                window.location.reload();
                toast.success("Modification de devise effectuer avec succes!");
                setShowComfirmUp(false);
                setLoading(false);
                setCodeUp("");
                setDesignationUp("");
                setValeurUp("");

            }
        }).catch(err => {
            setLoading(false);
            toast.error("Error", err);

        })
        
    }

    /**
     * @author Freddy
     * @description save Devise
     */
    
    const saveDevise = () => {
        
        if(code === "" && designation === "" && valeur === "") {
            setLoading(false);

            toast.error("Les champs sont vide, veuillez les remplire SVP!");
        }else{
            setLoading(true);
            axios.post(`${baseUrl}api/devise/create`, {
                code: code,
                devise: designation,
                valeurEnAriary:parseFloat(valeur)
            }, {headers: Authorization}).then(res => {
                if(res) {
                    setShow(false);
                    window.location.reload();
                    toast.success("Insertion effectuer avec succes!");
                    setShowComfirm(false);
                    setLoading(false);
                    setCode("");
                    setDesignation("");
                    setValeur("");

                }
            }).catch(err => {
                setLoading(false);
                toast.error("Error", err);

            })
        }

    }

    /**
     * @author
     * @description delete devise
     * @param e 
     */

    const deleteDevise = (e: any) => {

        setLoading(true);
        axios.put(`${baseUrl}api/devise/update/${delDevise.id}`, {
            status: 2
        }, {headers: Authorization}).then(res => {
            if(res) {
                setShow(false);
                window.location.reload();
                toast.success("Suppression de devise effectuer avec succes!");
                setShowComfirmUp(false);
                setLoading(false);
                

            }
        }).catch(err => {
            setLoading(false);
            toast.error("Error", err);

        })


    }

    return (
        <section className="content">
            <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
            {/* Same as */}
            <ToastContainer />
                <div className="container-fluid">
                    
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="d-md-flex justify-content-between">
                                <div className="input-group input-group-lg _inputForm w-25">
                                    <div className="">
                                        <h1 className="m-0">Devise</h1>
                                    </div>
                                </div>
                                
                                <button className="btn btn-primary btn-lg float-left _btnAdd" onClick={handleShow}>
                                    <i className='fas fa-plus pr-2'></i>
                                    Ajouter devise
                                </button>
                                

                                <Modal
                                    size="lg"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    show={show}
                                    onHide={handleClose}
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>Ajout devise</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                    <div className="form-group">
                                        <label htmlFor="code">Code du devise</label>
                                        <input type="text" value={code} onChange={(e)=>setCode(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Code du devise" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="designation">Designation du devise</label>
                                        <input type="text" value={designation} onChange={(e)=>setDesignation(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Designation" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="valeur">Valeur</label>
                                        <input type="text" value={valeur} onChange={(e)=>setValeur(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Valeur" />
                                    
                                    </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="button" onClick={handleClose} className="btn btn-danger">Annuler</button>
                                        <button type="button" onClick={handleShowComfirm} className="btn btn-primary">Enregistrer</button>
                                    </Modal.Footer>
                                </Modal>

                                

                                <Modal
                                    size="lg"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    show={showConfirm}
                                    onHide={handleCloseComfirm}
                                >
                                    <AddComfirmation actionOK={saveDevise}/>
                                </Modal>
                            </div>
                        </div>
                    </div>
                    {
                        loading ? (
                            <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0}/>
                        ) : (
                        <>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className='card-header'>
                                            <div className="d-flex col-md-6">
                                                <label htmlFor="">Afficher par</label>
                                                {/* <select id="inputState" onChange={(e)=>setArticlePerPage(e.target.value)} className="form-control w-auto ml-2">
                                                    <option value="10">10</option>
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                    <option value="75">75</option>
                                                    <option value="100">100</option>
                                                </select> */}
                                            </div>
                                        </div>
                                        <div className="card-body table-responsive p-0"  style={{height: "63vh"}}>
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">ID</th>
                                                        <th scope="col">Code</th>
                                                        <th scope="col">Devise</th>
                                                        <th scope="col">Valeur en Euro</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    devise && devise.length ? (
                                                        <>
                                                            {
                                                                devise.filter((data: any)=> data.status && data.status.designation ==="active").map((devs: any) => (
                                                                    
                                                                    <tr key={devs.id}>
                                                                        <th scope="row">{devs.id}</th>
                                                                        <td>{devs.code ? devs.code : "_"}</td>
                                                                        <td>{devs.devise ? devs.devise : '_'}</td>
                                                                        <td>{devs.valeurEnAriary ? devs.valeurEnAriary : '_'}</td>
                                                                        <td>
                                                                            <div>
                                                                                
                                                                                <button type="button" onClick={()=>getDevise(devs.id)} data-toggle="modal" data-target="#modal-lg" className="btn btn-sm" data-placement="top" title="Modifier">
                                                                                    <i className='fas fa-edit text-success'></i>
                                                                                </button>
                                                                                <button onClick={() => handleShowDel(devs)} className="btn btn-sm" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                                                                    <i className='fas fa-trash text-danger'></i>
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </>
                                                    ):(
                                                        
                                                        <tr>
                                                            <td style={{textAlign: "center"}} colSpan={8}>Pas de donnée</td>
                                                        </tr>
                                                        
                                                    ) 
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="card-footer">
                                            <div className="float-right">
                                                {/* <Paginations 
                                                    postPerPage={articlePerPage} 
                                                    totalPages={articleItems.length}
                                                    paginate={paginate}
                                                /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        )
                    }
                    
                </div>

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={showUp}
                    onHide={handleCloseUp}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Modification devise</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {
                        loadingDev ? (
                            <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0}/>
                        ):(
                            <>
                                <div className="form-group">
                                    <label htmlFor="code">Code du devise</label>
                                    <input type="text" value={codeUp} onChange={(e)=>setCodeUp(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Code du devise" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="designation">Designation du devise</label>
                                    <input type="text" value={designationUp} onChange={(e)=>setDesignationUp(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Designation" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="valeur">Valeur</label>
                                    <input type="text" value={valeurUp} onChange={(e)=>setValeurUp(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Valeur" />
                                
                                </div>
                            </>
                        )
                    }
                    
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" onClick={handleCloseUp} className="btn btn-danger">Annuler</button>
                        <button type="button" onClick={handleShowComfirmUp} className="btn btn-primary">Enregistrer</button>
                    </Modal.Footer>
                </Modal>

                

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={showConfirm}
                    onHide={handleCloseComfirm}
                >
                    <AddComfirmation actionOK={ saveDevise }/>
                </Modal>

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={showConfirmUp}
                    onHide={handleCloseComfirmUp}
                >
                    <AddComfirmation actionOK={(e: Event) => updateDevise(e)}/>
                </Modal>

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={showConfirmDel}
                    onHide={handleCloseDel}
                >
                    <DeleteComfirmation actionOK={(e: Event) => deleteDevise(e)}/>
                </Modal>

                
            </section>
    )
}

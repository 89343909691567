import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { baseUrl } from '../../../../services/Services'
import { ArticleType } from '../../../../utils/interface/ArticleType'
import { CategoryType } from '../../../../utils/interface/CategoryTYpe'

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


// import { CKEditor } from 'ckeditor4-react';
import { Authorization } from '../../../../utils/headers/Authorization'
interface Props {
    loading: boolean | any,
    createArticle: any,
    categorie: ArticleType | any,
    setCategorie: any,
    uploadImage: any,
    file: any,
    contenu: ArticleType | any,
    setContenu: any,
    title: any,
    setTitle: any,
    removeImageNew: any
}
export const AddArticle: React.FC<Props> = ({
    contenu,
    setContenu,
    loading,
    removeImageNew,
    createArticle,
    setCategorie,
    categorie,
    uploadImage,
    file,
    title,
    setTitle
}) => {
    const [category, setCategory] = useState<CategoryType | any>([]);
    // const [baseImage, setBaseImage] = useState([]);

    useEffect(() => {
        const getAllArticle = () => axios.get(`${baseUrl}api/categorie`, {headers: Authorization})
          .then((response: any) => {
    
            setCategory(response.data.data.rows);
    
        }).catch((error: any) => {
    
    
        });
        getAllArticle()
    }, []);

    const hanleChanheContenu = (event: any, editor: any) => {
        const data = editor.getData();
        setContenu(data);
    }

    return (
        <div>
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Ajouter article</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Form noValidate>

                        <input type="file" onChange={uploadImage} multiple/>
                        <br/><br />
                        <div className='imgBox_ mb-4'>
                        {
                            file && file.map((img: any) => {
                                return(
                                    
                                    <img onDoubleClick={(e: any) => removeImageNew(e, img && img.name)} key={img.base64} className='_imgS' src={img.base64} alt={img.name} />
                                    
                                )
                            })
                        }
                        </div>

                        <Form.Group controlId="validationCustomUsername" className='mb-4'>
                            <Form.Label>Titre</Form.Label>
                            <InputGroup hasValidation>
                                
                                <Form.Control
                                    type="text"
                                    value={title}
                                    onChange={(e: any) => setTitle(e.target.value)}
                                    placeholder="Titre de l'article"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                />
                                
                                <Form.Control.Feedback type="invalid">
                                    Please choose a email adress.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        {/* <input type="text" className='form-control' value={title} onChange={(e: any) => setTitle(e.target.value)} /> */}

                        <Form.Group controlId="validationCustomUsername" className='mb-4'>
                            <Form.Label>Categorie</Form.Label>
                            <InputGroup hasValidation>
                                
                                <Form.Select multiple={false} className="form-control" id="exampleFormControlSelect1" value={categorie} onChange={(e: any)=> setCategorie(e.target.value)}>
                                    <option value="">Selectionner une Categorie</option>
                                    {
                                        category.filter((data: any) => data.status.designation === "active").map((cat: any) => (
                                            <option key={cat.id} value={cat.id}>{cat.designation}</option>
                                        ))
                                    }
                                </Form.Select>
                            </InputGroup>
                        </Form.Group>
                    
                        <CKEditor
                            editor={ClassicEditor}
                            data={contenu}
                            // onChange={(ev: any) => {
                            //     const data = ev.editor.getData();

                            //     setContenu(data);
                            // }}
                            onChange={(event: any, editor: any) => {
                                const data = editor.getData();
                                setContenu(data);
                            }}
                        />
                        
                    </Form>
                </div>
                <div className="modal-footer justify-content-between">
                    <button type="button" className="btn btn-default" data-dismiss="modal">Fermer</button>
                    <button type="button" onClick={(e: any) => createArticle(e)} className="btn btn-primary">Enregistrer</button>
                </div>
            </div>
        </div>
    )
}

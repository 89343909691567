import axios from 'axios'
// import { CKEditor } from 'ckeditor4-react'
import React, { useEffect, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { baseUrl, imgPath } from '../../../../services/Services'
import { ArticleType } from '../../../../utils/interface/ArticleType'
import { CategoryType } from '../../../../utils/interface/CategoryTYpe'
import parse from 'html-react-parser';
import { Authorization } from '../../../../utils/headers/Authorization'
import ReactLoading from 'react-loading';

// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// import { CKEditor } from '@ckeditor/ckeditor5-react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify'
interface Props {
  article: ArticleType | any,
  categoryes: any,
  setCategoryes: any,
  files: any,
  setFiles: any,
  content: any,
  setContent: any,
  artId: any,
  artVal: any,
  contentIner: any,
  loadingData: any,
  artTitle: any,
  setArtTitle: any,
  imge: any
}
export const UpdateArticle: React.FC<Props> = ({
  article,
  categoryes,
  setCategoryes,
  files,
  setFiles,
  content,
  setContent,
  artId,
  artVal,
  contentIner,
  loadingData,
  artTitle,
  setArtTitle,
  imge
}) => {
  const [contenu, setContenu] = useState<ArticleType | any>("");
  const [title, setTitle] = useState<ArticleType | any>("");
  const [categorie, setCategorie] = useState<any>("");
  const [file, setFile] = useState<ArticleType[] | any>([]);
  const [imgs, setImgs] = useState<any>(imge);
  const [loading, setLoading] = useState<boolean | any>(false);
  const [listImge, setListImg] = useState<any>([]);

  const [category, setCategory] = useState<CategoryType | any>([]);

  useEffect(() => {
    setImgs(imge)
    const getCategory = async() => await axios.get(`${baseUrl}api/categorie`, {headers: Authorization})
      .then((response: any) => {

        setCategory(response.data.data.rows);

    }).catch((error: any) => {


    });
    getCategory()
    setListImg(file);
  }, [file]);


  const removeImageNew = (e: any, name: any) => {
    e.preventDefault();
    const newImg = listImge.filter(
      (data: any) => data.name !== name
    );
    setListImg(newImg);
  };

  
  const fileToDataUri = (image: any) => {
    return new Promise((res) => {
      const reader = new FileReader();
      const {type, name, size} = image;
      reader.addEventListener('load', () => {
          res({
              base64: reader.result,
              name: name,
              type,
              size: size,
          })
      });
      reader.readAsDataURL(image);
    })
  }

  const uploadImage = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
        const newImagesPromises = []
        for (let i = 0; i < e.target.files.length; i++) {
            newImagesPromises.push(fileToDataUri(e.target.files[i]))
        }
        const newImages = await Promise.all(newImagesPromises)
        setFile([...file, ...newImages])
    }
    e.target.value = "";
  }

  let newImgLists = imgs.concat(listImge)

  const updateArticle = (e: any) => {
    e.preventDefault();

    setLoading(true);

    axios.put(`${baseUrl}api/article/update/${article.id}`, {
      contentHtml: contenu ? contenu : content,
      title: artTitle,
      categorie: parseInt(categorie) ? parseInt(categorie) : artId,
      files: newImgLists,
    }, {headers: Authorization}).then((response: any) => {

      setLoading(false);

      window.location.reload();
      
      setContenu("");

    }).catch((error: any) => {

      setLoading(false);

    });

  }


  const items = category && category.filter((item: any) => {
    return (item.id !== parseInt(artId));
  });

  


  const handleChange = (event: any) => {
    setCategorie(event.target.value)
  };

  const handleDeleteFile = (id: number) => {

    axios.delete(`${baseUrl}api/delete-file/${id}`, {
      headers: Authorization
    }).then((res) => {
      if(res) {
        toast.success("Suppression d'image effectuer avec success");
      }
    }).catch(err => {
      toast.warning("Erreur lors de la suppression d'image");
    })
  }


  return (
    <div>
          <div className="modal-content">
            <div className="modal-header">
                <h4 className="modal-title">Modification article</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
              
            {
              loadingData ? (
                <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0}/>
              ):(
                <div className="modal-body">
                    
                    <Form noValidate>

                        <input type="file" onChange={uploadImage} multiple/>
                        <br/><br />
                        <div className='imgBox_ mb-4'>
                          {
                              article.files && article.files.length > 0 ? (
                                  article.files.map((img: any) => {
                                      return(

                                          <figure key={img.id}>
                                              <img className='_imgS_' style={{margin: "0.5rem"}} src={`${imgPath}${img.path}`} alt={img.name} />
                                              <figcaption>{img.name}</figcaption>
                                              <button onClick={()=> handleDeleteFile(img.id)}>Delete</button>
                                          </figure>
                                          
                                      )
                                  })
                              ):(
                                  <div>
                                      <h4>Pas d'image</h4>
                                  </div>
                              )
                          }
                          {
                              listImge && listImge.map((img: any) => {
                                  return(
                                      
                                    <img onDoubleClick={(e: any) => removeImageNew(e, img && img.name)} key={img.base64} className='_imgS' src={img.base64} alt={img.name} />
                                      
                                  )
                              })
                          }
                        </div>

                        <Form.Group controlId="validationCustomUsername" className='mb-4'>
                            <Form.Label>Titre</Form.Label>
                            <InputGroup hasValidation>
                                
                                <Form.Control
                                    type="text"
                                    value={artTitle}
                                    onChange={(e: any) => setArtTitle(e.target.value)}
                                    placeholder="Titre de l'article"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                />
                                
                                <Form.Control.Feedback type="invalid">
                                    Please choose a email adress.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group controlId="validationCustomUsername" className='mb-4'>
                            <Form.Label>Categorie</Form.Label>
                            <InputGroup hasValidation>
                                
                                <Form.Select multiple={false} className="form-control" id="exampleFormControlSelect1" value={categorie} onChange={handleChange}>
                                  <option defaultValue={artVal} value={artId} selected >{categoryes}</option>
                                    {
                                        items.filter((data: any) => data.status.designation === "active").map((cat: any) => (
                                            <option key={cat.value} value={cat.id}>{cat.designation}</option>
                                        ))
                                    }
                                </Form.Select>
                            </InputGroup>
                        </Form.Group>
                        <CKEditor
                          editor={ ClassicEditor }
                          initData={`${article.contentHtml}`}
                          data={`${article.contentHtml}`}
                          // onReady={ (editor: any) => {
                          //     // You can store the "editor" and use when it is needed.
                          //     console.log( 'Editor is ready to use!', editor );
                          // } }
                          onReady={(editor: any) => {
                            console.log('Editor is ready to use!', editor);
                          }}
                          onLoad={(event: any) => {
                            event.editor.setData(article.id);
                          }}
                          onChange={(event: any, editor: any) => {
                            const data = editor.getData();
                            console.log("DATA", data)
                            setContent(data);
                          }}
                          // config={{
                          //   removePlugins: [
                          //     "blockQuote",
                          //     "ckfinder",
                          //     "imageTextAlternative",
                          //     "imageUpload",
                          //     "heading",
                          //     "imageStyle:full",
                          //     "imageStyle:side",
                          //     "mediaEmbed",
                          //     "insertTable",
                          //     "tableColumn",
                          //     "tableRow",
                          //     "mergeTableCells"
                          //   ],
                          //   Plugin: ["Underline", "underline", "Paragraph"],

                          //   toolbar: [
                          //     "undo",
                          //     "redo",
                          //     "bold",
                          //     "italic",
                          //     "bulletedList",
                          //     "numberedList",
                          //     "link"
                          //   ]
                          // }}
                          
                        />
                        
                    </Form>
                </div>
              )
            }
            <div className="modal-footer justify-content-between">
                <button type="button" className="btn btn-default" data-dismiss="modal">Fermer</button>
                <button type="button" onClick={(e: any) => updateArticle(e)} className="btn btn-primary">Enregistrer</button>
            </div>
        </div>
    </div>
  )
}

import React from 'react'
import { EquipementType } from '../../utils/interface/EquipementType'
import { ListEquipement } from './Components/ListEquipement/ListEquipement'
interface Props {
    designation: EquipementType,
    description: EquipementType,
    setDesignation: any,
    setDescription: any,
    createEquipement: any,
    loading: boolean,
    setLoading: any
}
export const EquipementComponent: React.FC<Props> = ({
    designation,
    description,
    setDesignation,
    setDescription,
    createEquipement,
    loading,
    setLoading
}) => {
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Equipements</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="index.html">Equipements</a></li>
                                <li className="breadcrumb-item active">Equipements v1</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <ListEquipement
                designation= {designation}
                description={description}
                setDesignation={setDesignation}
                setDescription={setDescription}
                createEquipement={createEquipement}
                loading={loading}
                setLoading={setLoading}
            />
        </div>
    )
}

import React from 'react'

export default function AllCard() {
  return (
    <div>
        <div className="row">
            <div className="col-lg-3 col-6">
                <div className="small-box bg-info">
                <div className="inner">
                    <h3>150</h3>

                    <p>Logements</p>
                </div>
                <div className="icon">
                    <i className="ion ion-home"></i>
                </div>
                <a href="index.html" className="small-box-footer">Plus d'info <i className="fas fa-arrow-circle-right"></i></a>
                </div>
            </div>

            <div className="col-lg-3 col-6">
                <div className="small-box bg-info">
                <div className="inner">
                    <h3>53<sup style={{fontSize: "20px"}}></sup></h3>

                    <p>Clients</p>
                </div>
                <div className="icon">
                    <i className="fas fa-users"></i>
                </div>
                <a href="index.html" className="small-box-footer">Plus d'info <i className="fas fa-arrow-circle-right"></i></a>
                </div>
            </div>

            <div className="col-lg-3 col-6">
                <div className="small-box bg-info">
                <div className="inner">
                    <h3>44</h3>

                    <p>User Registrations</p>
                </div>
                <div className="icon">
                    <i className="ion ion-person-add"></i>
                </div>
                <a href="index.html" className="small-box-footer">Plus d'info <i className="fas fa-arrow-circle-right"></i></a>
                </div>
            </div>
            <div className="col-lg-3 col-6">
                <div className="small-box bg-info">
                <div className="inner">
                    <h3>65</h3>

                    <p>Reservation</p>
                </div>
                <div className="icon">
                    <i className="fas fa-credit-card"></i>
                </div>
                <a href="index.html" className="small-box-footer">Plus d'info <i className="fas fa-arrow-circle-right"></i></a>
                </div>
            </div>
        </div>
    </div>
  )
}

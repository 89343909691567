import React, { useContext } from 'react'
import { LogementContext } from '../../Context/AppContext'
import { LogementType } from '../../utils/interface/LogementType';
import { LogeDetailCompo } from './Components/DetailComponent/LogeDetailCompo';

export const DetailLogementCompo = () => {

    const logement: LogementType | any = useContext(LogementContext);
  return (
    <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Details du logement <b>{logement && logement.id}</b></h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="index.html">Logement</a></li>
                            <li className="breadcrumb-item active">Logement v1</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <LogeDetailCompo logement={logement}/>
    </div>
  )
}

import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { ContactContext } from '../../../../Context/AppContext'
import { ContactType } from '../../../../utils/interface/ContactType';
import { DeleteComfirmation } from '../../../DeleteComfirmation/DeleteComfirmation'
import moment from 'moment';
import { Paginations } from '../../../pagination/Paginations';

export const ListContact = () => {

    const contactListe = useContext(ContactContext);
    const [searchText, setSearchText] = useState<string | any>('');
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [contactPerPage, setContactPerPage] = useState<any>(10);
    const [info, setInfo] = useState<any>([]);

    const [showConfirmUp, setShowComfirmUp] = useState<boolean>(false);

    const handleShowUp = (data: any) => {
        setInfo(data)
        setShowComfirmUp(true)
    }
    const paginate = (numberPage: any)=> {
        setCurrentPage(numberPage)
    }

    const handleCloseComfirmUp = () => {
        setShowComfirmUp(false);
    }


    const dataFilter = contactListe.filter((name: any) => {
        return name.nom.toLowerCase().includes(searchText.toLowerCase()) ||
            name.prenom.toLowerCase().includes(searchText.toLowerCase()) ||
            name.mail.toLowerCase().includes(searchText.toLowerCase()) ||
            name.telephone.toLowerCase().includes(searchText.toLowerCase())
    })
    
      /**
       * Pagination
       */
    const indexOfLastPage = currentPage * contactPerPage;
    const IndexOfFirstPage = indexOfLastPage - contactPerPage;
    const currentContact = dataFilter.slice(IndexOfFirstPage, indexOfLastPage);

    const handleChange = (e: any) => {
        e.preventDefault();
        setSearchText(e.target.value);
    };

    const deleteArticle = () => {
    }

   

    return (
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-4">
                        <div className="d-md-flex justify-content-between">
                            <div className="input-group input-group-lg _inputForm w-25">
                                <input type="text" value={searchText} onChange={handleChange} name="table_search" className="form-control float-right input-lg _inputSearch" placeholder="Search" />

                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-default">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="modal fade" id="modal-del">
                                <div className="modal-dialog modal-del">
                                    <DeleteComfirmation actionOK={deleteArticle}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            
                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Nom</th>
                                            <th scope="col">Prenom</th>
                                            <th scope="col">Adresse email</th>
                                            <th scope="col">Téléphone</th>
                                            <th scope="col">Voir message</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        currentContact && currentContact.length ? (
                                            currentContact.map((contacts: ContactType | any) => (
                                                        
                                                <tr key={contacts.id}>
                                                    <th>{moment(contacts.createdAt).format("YYYY-MM-DD")}</th>
                                                    <td>{contacts.nom}</td>
                                                    <td>{contacts.prenom}</td>
                                                    <td>{contacts.mail}</td>
                                                    <td>{contacts.telephone}</td>
                                                    <td>
                                                        <div>
                                                            {/* <button type="button" className="btn btn-sm" data-toggle="modal" data-target="#modal-del" data-placement="top" title="Supprimer">
                                                                <i className='fas fa-trash text-danger'></i>
                                                            </button> */}
                                                            {/* <button type="button" onClick={() => console.log(contacts.id)} data-toggle="modal" data-target="#modal-update" className="btn btn-sm" data-placement="top" title="Modifier">
                                                                <i className='fas fa-edit text-success'></i>
                                                            </button> */}
                                                            <button onClick={()=> handleShowUp(contacts)} type="button" className="btn btn-sm" data-toggle="tooltip" data-placement="left" title="Voir message">
                                                                <i className='fas fa-comment text-primary'></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                            
                                        ))
                                        ):(
                                            <tr>
                                                <td style={{textAlign: "center"}} colSpan={8}>Pas de donnée</td>
                                            </tr>
                                        ) 
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer">
                                        <div className="float-right">
                                            <Paginations
                                                postPerPage={contactPerPage} 
                                                totalPages={contactListe.length}
                                                paginate={paginate}
                                            />
                                        </div>
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showConfirmUp}
                onHide={handleCloseComfirmUp}
            >
                <div>
                    <Modal.Header closeButton>
                        <Modal.Title>Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{info.message} </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" onClick={handleCloseComfirmUp} className="btn btn-primary">Fermer</button>
                    </Modal.Footer>
                </div>
            </Modal>
        </section>
    )
}

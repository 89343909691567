import React, { useEffect, useState } from 'react'
import { ListReservation } from './Components/ListReservation/ListReservation'
import { Form, InputGroup, Modal, Tab, Tabs } from 'react-bootstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Authorization } from '../../utils/headers/Authorization';
import { baseUrl } from '../../services/Services';
import axios from 'axios';
import { LogementType } from '../../utils/interface/LogementType';
import ListResManuel from './Components/ListReservation/ListResManuel';
interface Props {
    loading: boolean | any
}
export const ReservationComponent: React.FC<Props> = ({loading}) => {
    const [key, setKey] = useState('simple');

    const [log, setLog] = useState('')
    const [dateDebut, setDateDebut] = useState<string>('')
    const [dateFin, setDateFin] = useState<string>('')

    const [showRModalAddRes, setShowModalAddRes] = useState<boolean>(false);
    const [loadingPOST, setLoadingPOST] = useState<any>(false);

    const [listLogements, setLogements] = useState<LogementType[] | any>([]);
    const [resManuel, setResManuel] = useState<any[]>([]);

    const date = Date.now()
    const minDateDebut = moment(date).format("YYYY-MM-DD")
    const minDateFin = moment(dateDebut).format("YYYY-MM-DD")

    useEffect(() => {

        const getAllLogements = async() => {
            await axios.get(`${baseUrl}api/logement`, {headers: Authorization})
                .then((response: any) => {
            
                    setLogements(response.data.data.rows);
                    console.log("LOGGGGG", response.data.data.rows)
            
                }).catch((error: any) => {
            
                    console.log(error);
            
                });
        }

        const getAllResMAnual = async() => {
            await axios.get(`${baseUrl}api/reservation/logement`, {headers: Authorization})
            .then((res) => {
                setResManuel(res.data.data.rows)
            }).catch(err => {
                console.log("Error")
            })
        }

        getAllLogements();
        getAllResMAnual();
    }, [])


    const handleShowModalAddRes = () => {
        setShowModalAddRes(true);
    }

    const handleCloseModalAddRes = () => {
        setShowModalAddRes(false);
    }

    const CreerReservation = async(e: Event) => {
        e.preventDefault();
        setLoadingPOST(true)
        return await axios.post(`${baseUrl}api/reservation/logement`, {
            logement:{
                id: parseInt(log),
            },
            dateDebut: dateDebut as string,
            dateFin: dateFin as string,
            nombreVoyageur: 0,
            isManuel: true
        }, { headers: Authorization }).then((res) => {
            if(res) {
                toast.success("Reservation créer avec succes");
                // setTimeout(() => {
                //     window.location.reload();
                // }, 2000);
                setLoadingPOST(false)
                setShowModalAddRes(false);
                window.location.reload()
            }else{
                setLoadingPOST(false)
                toast.error("Erreur lors du creation de reservation!");
                setShowModalAddRes(false);
            }
        }).catch(err => {
            toast.warning("Erreur lors du creation de reservation!");
            setShowModalAddRes(false);
            setLoadingPOST(false)
        })
    }

  return (
    <>
        <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Reservation</h1>
                    </div>
                    <div className="col-sm-6">
                        <button onClick={handleShowModalAddRes} className="btn btn-primary btn-lg float-right _btnAdd">
                            <i className='fas fa-plus pr-2'></i>
                            Creer une reservation
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid">
                <div className="pl-2 pr-2">
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k: any) => setKey(k)}
                    className="mb-3"
                    >
                    <Tab eventKey="simple" title="Reservation">
                        <ListReservation
                            loading={loading}
                        />
                    </Tab>
                    <Tab eventKey="manuel" title="Reservation manuel">
                       <ListResManuel reservetions={resManuel}/>
                    </Tab>
                    
                </Tabs>
                </div>
            </div>
        
    </div>
    <Modal
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    show={showRModalAddRes}
    onHide={handleCloseModalAddRes}
>
    <div>
        <Modal.Header closeButton>
            <Modal.Title>Creer une reservation </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <Form.Group controlId="validationCustomUsername" className='mb-4'>
                    <Form.Label>Logement</Form.Label>
                    <InputGroup hasValidation>
                        
                        <Form.Select multiple={false} className="form-control" id="exampleFormControlSelect1" value={log} onChange={(e: any)=> setLog(e.target.value)}>
                            <option value="">Selectionner un logement</option>
                            {
                                listLogements.filter((data: any) => data.status.designation === "active").map((log: any) => (
                                    <option key={log.id} value={log.id}>{log.designation} -- {log.reference}</option>
                                ))
                            }
                        </Form.Select>
                    </InputGroup>
                </Form.Group>
                <Form.Group controlId="validationCustomUsername" className='mb-4'>
                    <Form.Label>Date debut</Form.Label>
                    <InputGroup hasValidation>
                        
                        <Form.Control
                            type="date"
                            min={minDateDebut}
                            value={dateDebut}
                            onChange={(e: any) => setDateDebut(e.target.value)}
                            aria-describedby="inputGroupPrepend"
                            required
                        />
                        
                        <Form.Control.Feedback type="invalid">
                            Please choose a email adress.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Form.Group controlId="validationCustomUsername" className='mb-4'>
                    <Form.Label>Date fin</Form.Label>
                    <InputGroup hasValidation>
                        
                        <Form.Control
                            type="date"
                            disabled={dateDebut === ''}
                            value={dateFin}
                            min={minDateFin}
                            onChange={(e: any) => setDateFin(e.target.value)}
                            aria-describedby="inputGroupPrepend"
                            required
                        />
                        
                        <Form.Control.Feedback type="invalid">
                            Please choose a email adress.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button disabled={loadingPOST} type="button" onClick={(e: any)=>CreerReservation(e)} className="btn btn-danger">
                {loadingPOST ? 'Chargement...' : 'Comfirmer'}
            </button>
        </Modal.Footer>
    </div>
</Modal>
    </>
  )
}

import React from 'react'
import {DetailReservationComponent} from '../../Components/ReservationComponent/DetailReservationComponent'

export default function DetailReservation() {
  return (
    <div>
      <DetailReservationComponent/>
    </div>
  )
}

import React, { useContext, useEffect, useState } from 'react'
import { ArticleContext, CategoryContext } from '../../../../Context/AppContext'
import { ArticleType } from '../../../../utils/interface/ArticleType'
import { DeleteComfirmation } from '../../../DeleteComfirmation/DeleteComfirmation'
import { AddArticle } from '../AddArticle/AddArticle';
import ReactLoading from 'react-loading';
import axios from 'axios';
import { baseUrl } from '../../../../services/Services';
import { Authorization } from '../../../../utils/headers/Authorization';
import { Paginations } from '../../../pagination/Paginations';
import { Link } from 'react-router-dom';
import { RouterPath } from '../../../../utils/route/route';
import {UpdateArticle} from '../UpdateArticle/UpdateArticle';

import parse from 'html-react-parser';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CategoryType } from '../../../../utils/interface/CategoryTYpe';
import { emitKeypressEvents } from 'readline';

interface Props {
    loading: boolean | any,
    setLoading: any,
    createArticle: any,
    categorie: ArticleType | any,
    setCategorie: any,
    uploadImage: any,
    file: any,
    contenu: ArticleType | any,
    setContenu: any,
    title: any,
    setTitle: any,
    removeImageNew: any
}
export const ListeArticle: React.FC<Props> = ({
    contenu,
    setContenu,
    loading,
    setLoading,
    createArticle,
    setCategorie,
    categorie,
    uploadImage,
    file,
    title,
    setTitle,
    removeImageNew
}) => {
    const articleItems = useContext(ArticleContext);

    const [article, setArticle] = useState<any>([]);

    const [content, setContent] = useState<ArticleType | any>("");
    const [categoryes, setCategoryes] = useState<any>("");
    const [files, setFiles] = useState<ArticleType[] | any>([]);
    const [artId, setArtID] = useState<number | any>();
    const [artVal, setArtVal] = useState<string | any>();
    const [artTitle, setArtTitle] = useState<string | any>();

    const [categories, setCategories] = useState<CategoryType[] | any>([]);

    const [imge, setImge] = useState<any>([]);

    const [val, setVal] = useState<any>([]);

    const [showConfirmDel, setShowComfirmDel] = useState<boolean>(false);

    const handleShowDel = (data: any) => {
        setShowComfirmDel(true);
        setVal(data);
    }

    
    
    useEffect(() => {
        const getAllCategory = async() => {
        await axios.get(`${baseUrl}api/categorie`, {headers: Authorization}).then((response) => {

            setCategories(response.data.data.rows);

        }).catch((error) => {

        })
        }

        getAllCategory();
    }, [])


    const handleCloseDel = () => setShowComfirmDel(false);

    
    const [searchText, setSearchText] = useState<string | any>('');
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [articlePerPage, setArticlePerPage] = useState<any>(10);
    const [loadingData, setLoadingData] = useState<boolean>(false);

    const paginate = (numberPage: any)=> {
        setCurrentPage(numberPage)
    }

    const handleChange = (e: any) => {
        e.preventDefault();
        setSearchText(e.target.value);
    };
    
    const getArticleUp = (id: number) => {
        setLoadingData(true);
        axios.get(`${baseUrl}api/article/${id}`,{
          headers: Authorization
        }).then((response) => {

            setArticle(response.data.data.rows);
            setArtID(response.data.data.rows.categorie && response.data.data.rows.categorie.id);
            setArtVal(response.data.data.rows.categorie && response.data.data.rows.categorie.value)
            setArtTitle(response.data.data.rows && response.data.data.rows.title)
            setContent(response.data.data.rows.contentHtml);
            setCategoryes(response.data.data.rows.categorie && response.data.data.rows.categorie.designation);
            setFiles(response.data.data.rows.files);
            setLoadingData(false);
            setImge(response.data.data.rows.files);

        }).catch((err: any) => {
            setLoadingData(false)
        });
    }


    const deleteArticle = (e: any) => {
        e.preventDefault();
        setLoading(true);
        axios.put(`${baseUrl}api/article/update/${val.id}`, {
            status: 2,
            files: [],
        }, {headers: Authorization}).then(res => {
            if(res) {
                toast.success("Suppression effectuer avec succes!");
                setShowComfirmDel(false);
                window.location.reload();
                setLoading(false);

            }
        }).catch(err => {
            setLoading(false);
            toast.error("Erreur de la suppression");

        })
    }

    function groupBy(list: any, keyGetter: any) {
        const map = new Map();
        list.forEach((item: any) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }
    
    const dataFilter = articleItems.filter((data: any) => {
        return (
            data.categorie && data.categorie.designation.toLowerCase().includes(searchText.toLowerCase()) ||
            data && data.title.toLowerCase().includes(searchText.toLowerCase()) 
            )
    })
        
    const listArticleByCategory = groupBy(dataFilter, (data: any) => data.categorie.designation);
    
    /**
     * Pagination
     */
    const indexOfLastPage = currentPage * articlePerPage;
    const IndexOfFirstPage = indexOfLastPage - articlePerPage;
    const currentArticle: any = dataFilter.slice(IndexOfFirstPage, indexOfLastPage);
    

    let itemssss: any = []

    listArticleByCategory.forEach((elements: any, key: any, index: any) => (
        itemssss.push(
            <div key={key} id="accordion" style={{marginBottom: "0px"}}>
                <div className='card'>
                    <div className="card-header" id={key}>
                        <h3 className="mb-0">
                            {key}
                        </h3>
                    </div>
                    <div className="card-body">
                        <div id={key} className="collapse show" aria-labelledby={key} data-parent="#accordion">
                            
                            <div className="card-body table-responsive p-0" style={{ height: "auto" }}>
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Titre de l'article</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody >

                                        {
                                        
                                            elements.length > 0 ? (
                                                elements.filter((el: any) =>el.status && el.status.designation === "active").map((element: any) => (
                                                    <tr key={element.id}>
                                                        <th scope="row">{element.id}</th>
                                                        <td>{element.title ? element.title : '_'}</td>
                                            
                                                        <td>
                                                            {
                                                                element.status && element.status.designation === "active" ? (
                                                                    <span className="badge badge-success">
                                                                        {element.status && element.status.designation}
                                                                    </span>
                                                                ) : (
                                                                    <span className="badge badge-warning">
                                                                        {element.status && element.status.designation}
                                                                    </span>
                                                                )
                                                            }
                                                        </td>
                                                        <td>
                                                            <div>
                                                            
                                                                {
                                                                    element.status && element.status.designation === "active" && (
                                                                        <>
                                                                            <button type="button" onClick={() => getArticleUp(element.id)} data-toggle="modal" data-target="#modal-lg" className="btn btn-sm" data-placement="top" title="Modifier">
                                                                                <i className='fas fa-edit text-success'></i>
                                                                            </button>
                                                                            <button onClick={() => handleShowDel(element)} type="button" className="btn btn-sm" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                                                                <i className='fas fa-trash text-danger'></i>
                                                                            </button>
                                                                        </>
                                                                    )
                                                                }
                                                                <Link to={`${RouterPath.articleDetail}/${element.id}/details`} type="button" className="btn btn-sm" data-toggle="tooltip" data-placement="top" title="Consulter">
                                                                    <i className='fas fa-eye text-primary'></i>
                                                                </Link>
                                                               

                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td style={{ textAlign: "center" }} colSpan={4}>Pas de donnée</td>
                                                </tr>
                                            )
                                        
                                        }
                                    
                                    </tbody>
                                    
                                </table>
                            </div>
                            
                        </div>
                    </div>

                    <div className="card-footer">
                        <div className="float-right">
                            <Paginations 
                                postPerPage={articlePerPage} 
                                totalPages={articleItems.length}
                                paginate={paginate}
                            />
                        </div>
                    </div>

                </div>
            </div>
        )
    ));

   

    const contentIner = parse(`${article.contentHtml}`);
    
    return (
        <section className="content">
            <div className="container-fluid">
                <div className="row mb-4">
                    <div className="col-12 mb-4">
                        <div className="d-md-flex justify-content-between">
                            <div className="input-group input-group-lg _inputForm w-25">
                                <input type="text" value={searchText} onChange={handleChange} name="table_search" className="form-control float-right input-lg _inputSearch" placeholder="Search" />

                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-default">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>

                            
                            
                            <button className="btn btn-primary btn-lg float-left _btnAdd" data-toggle="modal" data-target="#modal-xl">
                                <i className='fas fa-plus pr-2'></i>
                                Ajouter article
                            </button>
                            
                            <div className="modal fade" id="modal-xl">
                                <div className="modal-dialog modal-xl"  style={{width: "1230px", margin: "auto", marginTop: "3rem"}}>
                                    <AddArticle
                                        removeImageNew={removeImageNew}
                                        loading={loading}
                                        createArticle={createArticle}
                                        categorie={categorie}
                                        setCategorie={setCategorie}
                                        uploadImage={uploadImage}
                                        file={file}
                                        contenu={contenu}
                                        setContenu={setContenu}
                                        title={title}
                                        setTitle={setTitle}
                                    />
                                </div>
                            </div>

                            <div className="modal fade" id="modal-lg">
                                <div className="modal-dialog modal-lg" style={{width: "1230px", margin: "auto", marginTop: "3rem"}}>
                                    <UpdateArticle
                                        article={article}
                                        categoryes={categoryes}
                                        setCategoryes={setCategoryes}
                                        artId={artId}
                                        files={files}
                                        imge={imge}
                                        setFiles={setFiles}
                                        content={content}
                                        setContent={setContent}
                                        artVal={artVal}
                                        contentIner={contentIner}
                                        loadingData={loadingData}
                                        artTitle={artTitle}
                                        setArtTitle={setArtTitle}

                                    />
                                </div>
                            </div>

                            <div className="modal fade" id="modal-del">
                                <div className="modal-dialog modal-del">
                                    <DeleteComfirmation actionOK={deleteArticle}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    loading ? (
                        <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0}/>
                    ) : (
                    
                        <div className="row">
                            <div className='col-12 mb-4'>
                            {
                                itemssss
                            }
                            </div>
                                
                        </div>
                    
                    )
                }
                
            </div>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showConfirmDel}
                onHide={handleCloseDel}
            >
                <DeleteComfirmation actionOK={(e: Event) => deleteArticle(e)}/>
            </Modal>
        </section>
    )
}

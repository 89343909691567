import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { VilleComponent } from '../../Components/VilleComponent/VilleComponent'
import { baseUrl } from '../../services/Services';
import { VilleType } from '../../utils/interface/LogementType';
import { VilleContext } from '../../Context/AppContext';
import { Authorization } from '../../utils/headers/Authorization';

export const VillePages = () => {
  const [designation, setVille] = useState<VilleType | any>("");
  const [loading, setLoading] = useState<boolean | any>(false);
  const [allVille, setAllVille] = useState<VilleType | any>([]);
  const [searchText, setSearchText] = useState<string | any>('');
  const [currentPage, setCurrentPage] = useState<any>(1)
  const [villePerPage, setVillePerPage] = useState<any>(10);

  
  

  useEffect(() => {
    setLoading(true);
    const getAllVille = async() => {
      await axios.get(`${baseUrl}api/ville`, {
        headers: Authorization
      }).then((response: any) => {
        if(response){
          setLoading(false);
          setAllVille(response.data.data.rows)
        }
      }).catch((err) => {
        setLoading(false);
        console.log(err)
      })
    }
    getAllVille();
  }, []);

  //Create ville
  const createVille = (e: any) => {
    e.preventDefault();
    
    if(designation ===''){
      alert("Le champs designation est vide")
    } else {
      setLoading(true);
      axios.post(`${baseUrl}api/ville`, {
        designation: designation
      }, {headers: Authorization}).then((response: any) => {
        setLoading(false);
        window.location.reload();
        setVille("");
      }).catch(function (error) {
        setLoading(false);
        console.log(error);
      });
    }
  }

  //Create ville
  // const updateVille = (e: any) => {
  //   e.preventDefault();
    
  //   if(designation ===''){
  //     alert("Le champs designation est vide")
  //   } else {
  //     setLoading(true);
  //     axios.post(`${baseUrl}api/ville`, {
  //       designation: designation
  //     }).then((response: any) => {
  //       setLoading(false);
  //       btn?.setAttribute("data-dismiss", "modal")
  //       setVille("");
  //     }).catch(function (error) {
  //       setLoading(false);
  //       console.log(error);
  //     });
  //   }
  // }
  

  const dataFilter = allVille.filter((name: any) => {
    return name.designation.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
  })

  /**
   * Pagination
   */
  const indexOfLastPage = currentPage * villePerPage;
  const IndexOfFirstPage = indexOfLastPage - villePerPage;
  const currentVille = dataFilter.slice(IndexOfFirstPage, indexOfLastPage)

  const paginate = (numberPage: any)=> {
      setCurrentPage(numberPage)
  }

  /**
   * Pour la recherche des ville
   */
   const handleChange = (e: any) => {
      e.preventDefault();
      setSearchText(e.target.value);
  };

  return (
    <div>
        <VilleContext.Provider value={currentVille}>
          <VilleComponent
            ville={designation}
            loading={loading}
            paginate={paginate}
            createVille={createVille}
            totalPage={allVille} 
            setVille={setVille}
            searchText={searchText}
            handleChange={handleChange}
            setVillePerPage={setVillePerPage}
            villePerPage={villePerPage}
          />
        </VilleContext.Provider>
    </div>
  )
}

import React from 'react'
import { VilleType } from '../../utils/interface/LogementType'
import { QuartierType } from '../../utils/interface/QuartierType'
import { ListQuartier } from './Components/ListQuartier/ListQuartier'
interface Props {
    designation: QuartierType,
    setDesignation: any,
    ville: QuartierType,
    setVille: any
    loading: boolean
    createQuartier: any,
    allVille: VilleType
}
export const QuartierComponent: React.FC<Props> = ({
    designation,
    setDesignation,
    ville,
    setVille,
    loading,
    createQuartier,
    allVille
}) => {
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Quartier</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="index.html">Quartier</a></li>
                                <li className="breadcrumb-item active">Quartier v1</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <ListQuartier
                designation={designation}
                setDesignation={setDesignation}
                ville={ville}
                setVille={setVille}
                loading={loading}
                createQuartier={createQuartier}
                allVille={allVille}
            />
        </div>
    )
}

import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ReservationComponent } from '../../Components/ReservationComponent/ReservationComponent'
import { ReservationContext } from '../../Context/AppContext';
import { baseUrl } from '../../services/Services';
import { Authorization } from '../../utils/headers/Authorization';
import { toast } from 'react-toastify';
interface Props {}
export const ReservationPages: React.FC<Props> =() => {

  const [reservations, setReservations] = useState<[] | any>([]);
  const [loading, setLoading] = useState<boolean | any>(false);

  useEffect(() => {
    setLoading(true);
    const getReservations = async() => await axios.get(`${baseUrl}api/detail/paiement`, {headers: Authorization})
      .then((response: any) => {

        setLoading(false);
        setReservations(response.data.data.rows);

    }).catch((error: any) => {
      
      setLoading(false);
      console.log(error);

    });
    getReservations()
  }, [])

  return (
    <ReservationContext.Provider value={reservations}>
        <ReservationComponent
          loading={loading}
        />
    </ReservationContext.Provider>
  )
}

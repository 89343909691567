import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { EquipementComponent } from '../../Components/EquipementComponent/EquipementComponent'
import { EquipementContext } from '../../Context/AppContext';
import { baseUrl } from '../../services/Services';
import { Authorization } from '../../utils/headers/Authorization';
import { EquipementType } from '../../utils/interface/EquipementType';

export const EquipementPages = () => {
  const [designation, setDesignation] = useState<EquipementType | any>("");
  const [description, setDescription] = useState<EquipementType | any>("");
  const [loading, setLoading] = useState<boolean | any>(false);
  const [allEquipement, setAllEquipement] = useState<EquipementType | any>([]);



  useEffect(() => {
    setLoading(true);
    const getEquipement = async() => {
      await axios.get(`${baseUrl}api/equipement`, {
        headers: Authorization
      }).then((response: any) => {
        if(response){
          setLoading(false);
          setAllEquipement(response.data.data.rows)
        }
      }).catch((err) => {
        setLoading(false);
        console.log(err)
      })
    }
    getEquipement();
  }, []);


  //Create equipement
  const createEquipement = (e: any) => {
    e.preventDefault();
    
    if(designation ===''){
      alert("Le champs designation est vide")
    } else {
      setLoading(true);
      axios.post(`${baseUrl}api/equipement/create`, {
        designation: designation,
        description: description
      }, {headers: Authorization}).then((response: any) => {
        console.log(response)
        setLoading(false);
        window.location.reload();
        setDesignation("");
        setDescription("");
      }).catch(function (error) {
        setLoading(false);
        console.log(error);
      });
    }
  }

  return (
    <EquipementContext.Provider value={allEquipement}>
        <EquipementComponent
          designation= {designation}
          description={description}
          setDesignation={setDesignation}
          setDescription={setDescription}
          createEquipement={createEquipement}
          loading={loading}
          setLoading={setLoading}
        />
    </EquipementContext.Provider>
  )
}

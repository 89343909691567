import React, { useContext, useEffect, useState } from 'react'
import { LogementContext, ReservationContext } from '../../../../Context/AppContext'
import { DeleteComfirmation } from '../../../DeleteComfirmation/DeleteComfirmation'
import ReactLoading from 'react-loading';
import { ReservationType } from '../../../../utils/interface/ReservationType';
import { Link } from 'react-router-dom';
import { RouterPath } from '../../../../utils/route/route';
import moment from 'moment';
import { Paginations } from '../../../pagination/Paginations';
import { baseUrl } from '../../../../services/Services';
import { Authorization } from '../../../../utils/headers/Authorization';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Form, InputGroup, Modal } from 'react-bootstrap';
import { handleInputChange } from 'react-select/dist/declarations/src/utils';
import { LogementType } from '../../../../utils/interface/LogementType';

interface Props {
    loading: boolean | any
}
export const ListReservation: React.FC<Props> = ({loading}) => {

    const reservationsLists = useContext(ReservationContext);

    const [searchText, setSearchText] = useState<string | any>('');
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [reservationPerPage, setReservationPerPage] = useState<any>(10);

    const [showConfirmUp, setShowComfirmUp] = useState<boolean>(false);
    const [showConfirmUp1, setShowComfirmUp1] = useState<boolean>(false);


    const [loadingUp, setLoadingUp] = useState<any>(false);
    

    const [logUpt, setLogUpt] = useState<any>([]);
    const [logUpt1, setLogUpt1] = useState<any>([]);
    const [logUpt2, setLogUpt2] = useState<any>("");

    

    const [showConfirmUpRes, setShowComfirmUpRes] = useState<boolean>(false);

    const handleCloseUp = () => setShowComfirmUp(false);
    const handleCloseUp1 = () => setShowComfirmUp1(false);
    const handleCloseUp2 = () => setShowComfirmUpRes(false);

    const handleShowUp = (data: any) => {
        setShowComfirmUp(true);
        setLogUpt(data);
    }

    const handleShowComfirmUp = (dataLog: any) => {
        console.log("okokok");
        setShowComfirmUpRes(true);
        setLogUpt2(dataLog);
        
    }

    const handleShowUp1 = (data: any) => {
        setShowComfirmUp1(true);
        setLogUpt1(data);
    }

    const dataFilter = reservationsLists.filter((data: any) => {
        return (data.user && data.user.nom.toLowerCase().includes(searchText.toLowerCase())) ||
            (data.user && data.user.prenom.toLowerCase().includes(searchText.toLowerCase())) ||
            (data && data.reference.toLowerCase().includes(searchText.toLowerCase())) ||
            (data.reservationLogement && data.reservationLogement.logement.designation.toLowerCase().includes(searchText.toLowerCase()))
    })
    
    /**
     * Pagination
     */
    const indexOfLastPage = currentPage * reservationPerPage;
    const IndexOfFirstPage = indexOfLastPage - reservationPerPage;
    const currentReservation: any = dataFilter.slice(IndexOfFirstPage, indexOfLastPage);    

    const paginate = (numberPage: any)=> {
        setCurrentPage(numberPage)
    }

    const handleChange = (e: any) => {
        e.preventDefault();
        setSearchText(e.target.value);
    };

    const getLogementUp = (id: number) => {
        console.log(id)
    }

    const deleteArticle = () => {
        console.log("delete article");
    }

    const cancelePayment = (e: any) => {
        e.preventDefault();
        setLoadingUp(true);
        
        axios.put(`${baseUrl}api/detail/paiement/annuler`, {
            paymentIntent: logUpt.payementIntent
        }, { headers: Authorization }).then((res) => {
            console.log("RESSS", res);
            if(res) {
                toast.success("Paiement annuler avec succes");
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
                setLoadingUp(false);
                setShowComfirmUp(false);
            }else{
                toast.error("Erreur lors de l'annulation de paiement!");
                setShowComfirmUp(false);
            }
        }).catch(err => {
            toast.warning("Erreur d'annulation du paiement");
            setLoadingUp(false);
            setShowComfirmUp(false);
        })
    }

    const capturePaiement = (e: any) => {
        e.preventDefault();
        setLoadingUp(true);
        axios.put(`${baseUrl}api/detail/paiement/capture`, {
            paymentIntent: logUpt1.payementIntent
        }, { headers: Authorization }).then((res) => {
            if(res) {
                toast.success("Paiement capturé avec succes");
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
                setLoadingUp(false);
                setShowComfirmUp1(false);
            }else{
                toast.error("Erreur lors de capture du paiement!");
                setShowComfirmUp1(false);
            }
        }).catch(err => {
            toast.warning("Erreur de la capture du paiement");
            setLoadingUp(false);
            setShowComfirmUp1(false);
        })
    }

    const resetPaiement = (e: any) => {
        e.preventDefault();
        setLoadingUp(true);
        console.log("IDDDD", logUpt2.id)
        axios.put(`${baseUrl}api/reservation/logement/remboursement/${logUpt2.id}`, {
            dateDebut: "2023-06-04",
            dateFin: "2023-06-04"
        }, { headers: Authorization }).then((res) => {
            if(res) {
                toast.success("Paiement remboursé avec succes");
                // setTimeout(() => {
                //     window.location.reload();
                // }, 2000);
                setLoadingUp(false);
                setShowComfirmUpRes(false);
            }else{
                toast.error("Erreur lors de remboursement du paiement!");
                setShowComfirmUpRes(false);
            }
        }).catch(err => {
            toast.warning("Erreur de la remboursement du paiement");
            setLoadingUp(false);
            setShowComfirmUpRes(false);
        })
    }

    


  return (
    <section className="content">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 mb-4">
                    <div className="d-md-flex justify-content-between">
                        <div className="input-group input-group-lg _inputForm w-25">
                            <input type="text" value={searchText} onChange={handleChange} name="table_search" className="form-control float-right input-lg _inputSearch" placeholder="Search" />

                            <div className="input-group-append">
                                <button type="submit" className="btn btn-default">
                                    <i className="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <div className="modal fade" id="modal-del">
                            <div className="modal-dialog modal-del">
                                <DeleteComfirmation actionOK={deleteArticle}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                loading ? (
                    <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0}/>

                ):(
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                
                                <div className="card-body table-responsive p-0">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                
                                                <th scope="col">Réservation</th>
                                                {/* <th scope='col'>Paiement ID</th> */}
                                                <th scope="col">Date du réservation</th>
                                                <th scope='col'>Date debut</th>
                                                <th scope="col">Date fin</th>
                                                {/* <th scope='col'>Prix Total</th>
                                                <th scope="col">Logement</th> */}
                                                <th scope="col">Adresse</th>
                                                <th scope="col">Nombre de piece</th>
                                                <th scope="col">Nombre personne</th>
                                                <th scope="col">Nom du client</th>
                                                <th scope="col">Prenoms du client</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                                currentReservation && currentReservation.length ? (
                                                    currentReservation.map((res: ReservationType | any) => (
                                                                
                                                        <tr key={res.id}>
                                                            
                                                            <td>{res ? res.reference : "_"}</td>
                                                            {/* <td>{res.payementIntent}</td> */}
                                                            <td>{moment(res && res.date).format('YYYY-MM-DD')}</td>
                                                            <td>{ res.reservationLogement ? moment(res.reservationLogement && res.reservationLogement.dateDebut).format('YYYY-MM-DD') : '--'}</td>
                                                            <td>{ res.reservationLogement ? moment(res.reservationLogement && res.reservationLogement.dateFin).format('YYYY-MM-DD') : '--'}</td>
                                                            <td>{res.reservationLogement ? res.reservationLogement.logement.adresse : '_'}</td>
                                                            <td>{res.reservationLogement ? res.reservationLogement.logement.nombrePieceTotal : '_'}</td>
                                                            <td>{res.reservationLogement ? res.reservationLogement.logement.nombrePersonneMax : "_"}</td>
                                                            <td>{res.user ? res.user.nom : "_"}</td>
                                                            <td>{res.user ? res.user.prenom : "_"}</td>
                                                            
                                                            <td>
                                                                {/* <span className="badge badge-success">{res.status && res.status.designation}</span> */}

                                                                {
                                                                    res.status && res.status.designation === "requires_capture" && (
                                                                        <span className="badge badge-warning">{res.status && res.status.designation}</span>
                                                                    )
                                                                }

                                                                {
                                                                    res.status && res.status.designation === "annuler" && (
                                                                        <span className="badge badge-danger">{res.status && res.status.designation}</span>
                                                                    )
                                                                }

                                                                {
                                                                    res.status && res.status.designation === "succeeded" && (
                                                                        <span className="badge badge-success">{res.status && res.status.designation}</span>
                                                                    )
                                                                }
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    {
                                                                        res.status && res.status.designation !== "succeeded" && res.status && res.status.designation !== "annuler" && (
                                                                            <button onClick={()=>handleShowUp(res)} type="button" className="btn btn-sm" data-placement="top" title={`Annulée le paiement ${res.payementIntent}`}>
                                                                                <i className='fas fa-trash text-danger'></i>
                                                                            </button>
                                                                        )
                                                                    }

                                                                    {
                                                                        res.status && res.status.designation === "succeeded" && (
                                                                            <button onClick={()=>handleShowComfirmUp(res.reservationLogement && res.reservationLogement)} type="button" className="btn btn-sm btn-warning" data-placement="top" title={`Annulée le paiement ${res.payementIntent}`}>
                                                                                {/* <i className='fa-solid fa-retweet text-waring'></i> */}
                                                                                Rembourser
                                                                            </button>
                                                                        )
                                                                    }


                                                                    {
                                                                        res.status && res.status.designation === "requires_capture" && (
                                                                            <button onClick={()=>handleShowUp1(res)} type="button" className="btn btn-sm" data-placement="top" title={`Capturé le paiement ${res.payementIntent}`}>
                                                                                <i className='fas fa-check text-success'></i>
                                                                            </button>
                                                                        )
                                                                    }
                                                                    
                                                                    <Link to={`${RouterPath.detailReservation}/${res.id}/details`} type="button" className="btn btn-sm" data-toggle="tooltip" data-placement="top" title="Consulter">
                                                                        <i className='fas fa-eye text-primary'></i>
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        
                                                    
                                                ))
                                                ):(
                                                    <tr>
                                                        <td style={{textAlign: "center"}} colSpan={10}>Pas de donnée</td>
                                                    </tr>
                                                ) 
                                            }
                                        </tbody>
                                    </table>
                                    </div>
                                      <div className="card-footer">
                                        <div className="float-right">
                                            <Paginations
                                                postPerPage={reservationPerPage} 
                                                totalPages={reservationsLists.length}
                                                paginate={paginate}
                                            />
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                )
            }
            
          </div>
          <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showConfirmUp}
                onHide={handleCloseUp}
            >
                <div>
                    <Modal.Header closeButton>
                        <Modal.Title>Comfirmation de l'activation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h4>Voulez vous vraiment annuler le paiement {logUpt && logUpt.reference} </h4>
                    </Modal.Body>
                    <Modal.Footer>
                        <button disabled={loadingUp} type="button" onClick={(e: any)=>cancelePayment(e)} className="btn btn-danger">
                            {loadingUp ? 'Chargement...' : 'Comfirmer'}
                        </button>
                    </Modal.Footer>
                </div>
          </Modal>
          
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showConfirmUp1}
            onHide={handleCloseUp1}
        >
            <div>
                <Modal.Header closeButton>
                    <Modal.Title>Comfirmation de la capture</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Voulez vous vraiment effectué la capture du paiement {logUpt1 && logUpt1.reference} </h4>
                </Modal.Body>
                <Modal.Footer>
                    <button disabled={loadingUp} type="button" onClick={(e: any)=>capturePaiement(e)} className="btn btn-danger">
                        {loadingUp ? 'Chargement...' : 'Comfirmer'}
                    </button>
                </Modal.Footer>
            </div>
        </Modal>

        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showConfirmUpRes}
            onHide={handleCloseUp2}
        >
            <div>
                <Modal.Header closeButton>
                    <Modal.Title>Comfirmation de remboursement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Voulez vous vraiment effectué le remboursement du paiement {logUpt1 && logUpt1.reference} </h4>
                </Modal.Body>
                <Modal.Footer>
                    <button disabled={loadingUp} type="button" onClick={(e: any)=>resetPaiement(e)} className="btn btn-danger">
                        {loadingUp ? 'Chargement...' : 'Comfirmer'}
                    </button>
                </Modal.Footer>
            </div>
        </Modal>
    </section>
  )
}

import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { LogementContext } from '../../../../Context/AppContext'
import { baseUrl } from '../../../../services/Services'
import { Authorization } from '../../../../utils/headers/Authorization'
import { LogementType } from '../../../../utils/interface/LogementType'
import { QuartierType } from '../../../../utils/interface/QuartierType'
import { DeleteComfirmation } from '../../../DeleteComfirmation/DeleteComfirmation'
import { AddLogement } from '../AddLogement/AddLogement';
import ReactLoading from 'react-loading';
import { Link } from 'react-router-dom'
import { RouterPath } from '../../../../utils/route/route'
import {UpdateLogement} from '../UpdateLogement/UpdateLogement'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import { Paginations } from '../../../pagination/Paginations'
interface Props {
    adress: LogementType | any,
    designation: LogementType | any,
    description: LogementType | any,
    nombrePersonneMax: LogementType | any,
    nombrePieceTotal: LogementType | any,
    quartier: LogementType | any,
    setAdress: any,
    setDesignation: any
    setDescription: any,
    setNombrePersonneMax: any,
    setNombrePieceTotal: any,
    setQuartier: any,
    equipement: LogementType[] | any,
    setEquipement: any,
    createLogement: any,
    uploadImage: any,
    image: any,
    loading: boolean,
    setLoading: any,
    loyer: any,
    setLoyer: any,
    show: any,
    handleClose: any,
    handleShow: any,
    contact: any,
    propri: any,
    setContact: any,
    setPropri: any,
    removeImageNew: any
}

export const ListLogement: React.FC<Props> = ({
    adress,
    designation,
    description,
    nombrePersonneMax,
    nombrePieceTotal,
    quartier,
    setAdress,
    setDesignation,
    setDescription,
    setNombrePersonneMax,
    setNombrePieceTotal,
    setQuartier,
    equipement,
    setEquipement,
    createLogement,
    uploadImage,
    image,
    loading,
    setLoading,
    loyer,
    setLoyer,
    show,
    handleClose,
    handleShow,
    contact,
    propri,
    setContact,
    setPropri,
    removeImageNew
}) => {

    const [quartiers, setQuartiers] = useState<QuartierType[] | any>([]);
    const [equipements, setEquipements] = useState<QuartierType[] | any>([]);

    const [searchText, setSearchText] = useState<string | any>('');
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [logementPerPage, setLogementPerPage] = useState<any>(10);


    const [logItem, setLogItem] = useState<LogementType | any>("");

    const [adr, setAdr] = useState<LogementType | any>("");
    const [desig, setDesig] = useState<LogementType | any>("");
    const [descr, setDescr] = useState<LogementType | any>("");
    const [nombrePersonneM, setNombrePersonneM] = useState<LogementType | any>(0);
    const [nombrePieceT, setNombrePieceT] = useState<LogementType | any>(0);
    const [quart, setQuart] = useState<LogementType | any>(0);
    const [equipem, setEquipem] = useState<any>([]);
    const [location, setLocation] = useState<LogementType | any>(0);
    const [imge, setImge] = useState<LogementType | any>([]);
    const [contactP, setContactP] = useState<LogementType | any>("");
    const [proprio, setProprio] = useState<LogementType | any>("");

    const [showConfirmDel, setShowComfirmDel] = useState<boolean>(false);
    const [showConfirmUp, setShowComfirmUp] = useState<boolean>(false);

    const [logDel, setLogDel] = useState<any>([]);
    const [logUpt, setLogUpt] = useState<any>([]);

    const [loadingUp, setLoadingUp] = useState<any>(false)


    const handleCloseDel = () => setShowComfirmDel(false);
    const handleCloseUp = () => setShowComfirmUp(false);


    const handleShowDel = (data: any) => {
        setShowComfirmDel(true);
        setLogDel(data);
    }

    const handleShowUp = (data: any) => {
        setShowComfirmUp(true);
        setLogUpt(data);
    }


    const [qId, setQId] = useState<number | any>()

    const logements: any = useContext(LogementContext);





    useEffect(() => {
        
        const getAllQuartier = async() => {
          await axios.get(`${baseUrl}api/quartier`, {
            headers: Authorization
          }).then((response: any) => {
            if(response){

              setQuartiers(response.data.data.rows);

            }
          }).catch((err) => {
            console.log(err)
          })
        }

        const getEquipement = async() => {
            await axios.get(`${baseUrl}api/equipement`, {
              headers: Authorization
            }).then((response: any) => {
              if(response){
                setEquipements(response.data.data.rows)
              }
            }).catch((err) => {
              console.log(err)
            })
        }
        getEquipement();
        getAllQuartier();
    }, []);

    
    const dataFilter = logements.filter((name: any) => {
        return (name.adresse && name.adresse.toLowerCase().includes(searchText.toLowerCase())) ||
        (name.quartier && name.quartier.designation.toLowerCase().includes(searchText.toLowerCase())) ||
        (name.designation && name.designation.toLowerCase().includes(searchText.toLowerCase())) ||
        (name.description && name.description.toLowerCase().includes(searchText.toLowerCase()))
    })
    
    /**
     * Pagination
     */
    const indexOfLastPage = currentPage * logementPerPage;
    const IndexOfFirstPage = indexOfLastPage - logementPerPage;
    const currentLog: any = dataFilter.slice(IndexOfFirstPage, indexOfLastPage);
    

    const paginate = (numberPage: any)=> {
        setCurrentPage(numberPage)
    }

    const handleChange = (e: any) => {
        e.preventDefault();
        setSearchText(e.target.value);
    };

    // let equips: any = [{id: '', value: ''}]

    const getLogementUp = (id: number) => {
        setLoadingUp(true)
        axios.get(`${baseUrl}api/logement/${id}`,{
          headers: Authorization
        }).then((response) => {
            setLogItem(response.data.data.rows);
            setAdr(response.data.data.rows.adresse);
            setDesig(response.data.data.rows.designation);
            setDescr(response.data.data.rows.description);
            setNombrePersonneM(response.data.data.rows.nombrePersonneMax);
            setNombrePieceT(response.data.data.rows.nombrePieceTotal);
            setQuart(response.data.data.rows.quartier && response.data.data.rows.quartier.designation);
            setEquipem(response.data.data.rows.equipements);
            setImge(response.data.data.rows.files);
            setLocation(response.data.data.rows.loyer);
            setProprio(response.data.data.rows.nom)
            setContactP(response.data.data.rows.contact)
            setQId(response.data.data.rows.quartier && response.data.data.rows.quartier.id)
            setLoadingUp(false)
        }).catch(err => {
            setLoadingUp(false)
        });
    }



    const deleteLogement = async () => {
        setLoading(true);
        await axios.put(`${baseUrl}api/logement/${logDel.id}`, {
            
            status: 2
        },{headers: Authorization}).then(res => {
            if(res) {
                toast.success(`Suppression de de l'equipement ${logDel.designation} effectué avec succes!`);
                window.location.reload();
                setLoading(false);
                setShowComfirmDel(false);
            }
        }).catch(err => {
            setLoading(false);
            toast.warning(`Erreur lors de la suppression de de l'equipement ${logDel.designation}!`)
        })
        
    }

    const activeLogement = async () => {
        setLoading(true);
        await axios.put(`${baseUrl}api/logement/${logUpt.id}`, {
            
            status: 1
        },{headers: Authorization}).then(res => {
            if(res) {
                toast.success(`Suppression de de l'equipement ${logDel.designation} effectué avec succes!`);
                window.location.reload();
                setLoading(false);
                setShowComfirmDel(false);
            }
        }).catch(err => {
            setLoading(false);
            toast.warning(`Erreur lors de la suppression de de l'equipement ${logDel.designation}!`)
        })
        
    }

    return (
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-4">
                        <div className="d-md-flex justify-content-between">
                            <div className="input-group input-group-lg _inputForm w-25">
                                <input type="text"  value={searchText} onChange={handleChange} name="table_search" className="form-control float-right input-lg _inputSearch" placeholder="Search" />

                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-default">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                            <button className="btn btn-primary btn-lg float-left _btnAdd" onClick={handleShow} >
                                <i className='fas fa-plus pr-2'></i>
                                Ajouter logement
                            </button>

                            <Modal
                                size="xl"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                show={show}
                                onHide={handleClose}
                                
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Add logement</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{height: "auto"}}>
                                    <AddLogement
                                        adress={adress}
                                        designation={designation}
                                        description={description}
                                        nombrePersonneMax={nombrePersonneMax}
                                        nombrePieceTotal={nombrePieceTotal}
                                        quartier={quartier}
                                        setAdress={setAdress}
                                        setDesignation={setDesignation}
                                        setDescription={setDescription}
                                        setNombrePersonneMax={setNombrePersonneMax}
                                        setNombrePieceTotal={setNombrePieceTotal}
                                        setQuartier={setQuartier}
                                        equipement={equipement}
                                        setEquipement={setEquipement}
                                        quartiers={quartiers}
                                        equipements={equipements}
                                        createLogement={createLogement}
                                        uploadImage={uploadImage}
                                        image={image}
                                        loyer={loyer}
                                        setLoyer={setLoyer}
                                        contact={contact}
                                        propri={propri}
                                        setContact={setContact}
                                        setPropri={setPropri}
                                        removeImageNew={removeImageNew}
                                    />
                                </Modal.Body>
                                <Modal.Footer>

                                    <button type="button" onClick={createLogement} className="btn btn-primary">Enregistrer</button>
                                
                                </Modal.Footer>
                            </Modal>
                            {/* <div className="modal fade" id="modal-lg">
                                <div className="modal-dialog modal-lg" style={{width: "1200px"}}>
                                    
                                </div>
                            </div> */}
                            <div className="modal fade" id="modal-xl">
                                <div className="modal-dialog modal-xl" style={{width: "1200px !important"}}>
                                    <UpdateLogement
                                        loadingUp={loadingUp}
                                        adress={adr}
                                        designation={desig}
                                        description={descr}
                                        nombrePersonneMax={nombrePersonneM}
                                        nombrePieceTotal={nombrePieceT}
                                        quartier={quart}
                                        setAdress={setAdr}
                                        setDesignation={setDesig}
                                        setDescription={setDescr}
                                        setNombrePersonneMax={setNombrePersonneM}
                                        setNombrePieceTotal={setNombrePieceT}
                                        setQuartier={setQuart}
                                        setProprio={setProprio}
                                        setContactP={setContactP}
                                        contactP={contactP}
                                        proprio={proprio}
                                        equipement={equipem}
                                        setEquipement={setEquipem}
                                        quartiers={quartiers}
                                        equipements={equipements}
                                        createLogement={createLogement}
                                        uploadImage={uploadImage}
                                        image={image}
                                        imge={imge}
                                        qId={qId}
                                        logItem={logItem}
                                        loyer={location}
                                        setLoyer={setLocation}
                                        contact={contactP}
                                        propri={proprio}
                                        setContact={setContactP}
                                        setPropri={setProprio}
                                    />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                {
                    loading ? (
                        <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0}/>
                    ):(
                        <>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="d-flex col-md-6">
                                                <label htmlFor="">Afficher par</label>
                                                <select id="inputState" onChange={(e)=>setLogementPerPage(e.target.value)} className="form-control w-auto ml-2">
                                                    <option value="10">10</option>
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                    <option value="75">75</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="card-body table-responsive p-0">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Code</th>
                                                        <th scope="col">Adresse</th>
                                                        <th scope="col">Quartier</th>
                                                        <th scope="col">Nombre pièce</th>
                                                        <th scope="col">Nombre Personnel</th>
                                                        <th scope="col">Designation</th>
                                                        <th scope="col">Loyer par nuit</th>
                                                        {/* <th scope="col">Description</th> */}
                                                        <th scope="col">Proprietaire</th>
                                                        <th scope="col">Contact du proprietaire</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    currentLog && currentLog.length ? (
                                                        currentLog.filter((data: any) => data.status && data.status.designation === "active").map((loge: LogementType | any) => (
                                                                    
                                                            <tr key={loge.id}>
                                                                <th scope="row">{loge.id}</th>
                                                                <td>{loge.adresse}</td>
                                                                <td>{loge.quartier ? loge.quartier.designation : "_"}</td>
                                                                <td>{loge.nombrePieceTotal}</td>
                                                                <td>{loge.nombrePersonneMax}</td>
                                                                <td>{loge.designation}</td>
                                                                <td>{loge.loyer}</td>
                                                                {/* <td>{loge.description}</td> */}
                                                                <td>
                                                                    {loge.nom}
                                                                </td>
                                                                <td>
                                                                    {loge.contact}
                                                                </td>
                                                                <td>{loge.status ? loge.status.designation : '_'}</td>
                                                                <td>
                                                                    <div>
                                                                        
                                                                        {
                                                                            loge.status && loge.status.designation === "active" ? (
                                                                                <>
                                                                                <button type="button" onClick={() => getLogementUp(loge.id)} data-toggle="modal" data-target="#modal-xl" className="btn btn-sm" data-placement="top" title="Modifier">
                                                                                    <i className='fas fa-edit text-success'></i>
                                                                                </button>
                                                                                <Link to={`${RouterPath.logementDetail}/${loge.id}/details`} type="button" className="btn btn-sm" data-toggle="tooltip" data-placement="top" title="Consulter">
                                                                                    <i className='fas fa-eye text-primary'></i>
                                                                                </Link>
                                                                                <button onClick={(e: any) => handleShowDel(loge)} type="button" className="btn btn-sm" data-placement="top" title="Désactivé">
                                                                                    <i className='fas fa-trash text-danger'></i>
                                                                                </button>
                                                                                </>
                                                                            ):(
                                                                                <button onClick={(e: any) => handleShowUp(loge)} type="button" className="btn btn-sm" data-placement="top" title="Activé">
                                                                                    <AiOutlineCheckCircle size="1.5em" className="text-success"/>
                                                                                </button>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        
                                                        ))
                                                    ):(
                                                        <tr>
                                                            <td style={{textAlign: "center"}} colSpan={8}>Pas de donnée</td>
                                                        </tr>
                                                    ) 
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="card-footer">
                                            <div className="float-right">
                                                <Paginations
                                                    postPerPage={logementPerPage} 
                                                    totalPages={logements.length}
                                                    paginate={paginate}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showConfirmDel}
                onHide={handleCloseDel}
            >
                <DeleteComfirmation actionOK={(e: Event) => deleteLogement()}/>
            </Modal>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showConfirmUp}
                onHide={handleCloseUp}
            >
                <div>
                    <Modal.Header closeButton>
                        <Modal.Title>Comfirmation de l'activation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h1>Comfirmation </h1>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" onClick={activeLogement} className="btn btn-danger">Comfirmer</button>
                    </Modal.Footer>
                </div>
            </Modal>
        </section>
    )
}

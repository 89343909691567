import React, { useContext, useState } from 'react'
import { CategoryType } from '../../../../utils/interface/CategoryTYpe';
import { DeleteComfirmation } from '../../../DeleteComfirmation/DeleteComfirmation';
import ReactLoading from 'react-loading';
import { AddCategory } from '../Add/AddCategory';
import { CategoryContext } from '../../../../Context/AppContext';
import { Form, InputGroup, Modal } from 'react-bootstrap';
import { AddComfirmation } from '../../../AddConfirmation/AddComfirmation';
import { toast } from 'react-toastify';
import { Authorization } from '../../../../utils/headers/Authorization';
import { baseUrl } from '../../../../services/Services';
import axios from 'axios';
import { Paginations } from '../../../pagination/Paginations';
interface Props {
    loading: boolean,
    designation: CategoryType | any,
    setDesignation: any,
    valeur: CategoryType | any,
    setValeur: any,
    createCategory: any
    rang: any,
    setRang: any,
    setLoading: any
}

export const ListCategorie: React.FC<Props> = ({
    loading,
    designation,
    setDesignation,
    valeur,
    setValeur,
    createCategory,
    rang,
    setRang,
    setLoading
}) => {

    const categories = useContext(CategoryContext);

    const [searchText, setSearchText] = useState<string | any>('');
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [catPerPage, setCatPerPage] = useState<any>(10);

    const [showAdd, setShowAdd] = useState(false);
    const [showUpD, setShowUpd] = useState(false);

    const [showConfirmAdd, setShowComfirmAdd] = useState<boolean>(false);
    const [showConfirmUp, setShowComfirmUp] = useState<boolean>(false);
    const [showConfirmDel, setShowComfirmDel] = useState<boolean>(false);

    const [rangUp, setRangUp] = useState<string | any>("");
    const [designationUp, setDesignationUp] = useState<string | any>("");
    const [valeurUp, setValeurUp] = useState<string | any>("");
    const [catId, setCatId] = useState<string | any>("");

    const [catDel, setDelCoategory] = useState<any>([]);

    const [catItem, setCatItem] = useState<any>([])

    const [loadingDev, setLoadingdev] = useState<boolean>(false);

    const handleCloseAdd = () => setShowAdd(false);
    const handleShowAdd = () => setShowAdd(true);
    const handleCloseUp = () => setShowUpd(false);
    const handleCloseDel = () => setShowComfirmDel(false);

    const handleCloseComfirm = () => {
        setShowComfirmAdd(false);
        setShowAdd(false);
    }

    const handleShowUp = () => {
        setShowUpd(true);
    }

    const handleCloseComfirmUp = () => {
        setShowComfirmUp(false);
        setShowUpd(false);
    }

    /**
     * @author Freddy
     * @description get category by ID
     * @param id
     */
    const getCategory = (id: number) => {
        setLoadingdev(true);
        handleShowUp();
        axios.get(`${baseUrl}api/categorie/${id}`,{
          headers: Authorization
        }).then((response: any) => {

            setCatItem(response.data.data.rows);
            setRangUp(response.data.data.rows.rang)
            setDesignationUp(response.data.data.rows.designation)
            setValeurUp(response.data.data.rows.value);
            setCatId(response.data.data.rows.id);
            setLoadingdev(false);
        }).catch((err: any) => {
            setLoadingdev(false);
        });
    }

    
  

    /**
     * @author Freddy
     * @description show comfirm modal on save
     */
    const handleShowComfirm = () => {
        if(rang === "" && designation === "" && valeur === "") {
            setLoading(false);
            setShowComfirmAdd(false);
            toast.error("Les champs sont vide, veuillez les remplire SVP!");
        }else {
            setShowComfirmAdd(true);
            setShowAdd(false);
        }
        
    }

    /**
     * @author Freddy
     * @description show comfirm modal on save
     */
    const handleShowComfirmUp = () => {
        if(rang === "" && designation === "" && valeur === "") {
            setLoading(false);
            setShowComfirmUp(false);
            toast.error("Les champs sont vide, veuillez les remplire SVP!");
        }else {
            setShowComfirmUp(true);
            setShowUpd(false);
        }
        
    }

    const updateCategory = (e: any) => {
        e.preventDefault();
    
        setLoading(true);
    
        axios.put(`${baseUrl}api/categorie/update/${catId}`, {
            designation: designationUp,
            value: valeurUp,
            rang: parseInt(rangUp)
        }, {headers: Authorization}).then((response: any) => {
    
            setLoading(false);
            setShowComfirmUp(false);
            window.location.reload();
            setDesignationUp("");
            setValeurUp("");
            setRangUp("");
    
        }).catch((error: any) => {
            setShowComfirmUp(false);
            setShowUpd(true);
            setLoading(false);
    
        });
    
    }

    const handleShowDel = (data: any) => {
        setShowComfirmDel(true);
        setDelCoategory(data);
    }

    /**
     * @author
     * @description delete devise
     * @param e 
     */

    const deleteCategory = (e: any) => {
        setLoading(true);
        e.preventDefault();
    
        setLoading(true);
    
        axios.put(`${baseUrl}api/categorie/update/${catDel.id}`, {
            status: 2
        }, {headers: Authorization}).then((response: any) => {
    
            setLoading(false);
            window.location.reload();    
        }).catch((error: any) => {
            setShowComfirmDel(false);
            setShowUpd(true);
            setLoading(false);
    
        });


    }

    const dataFilter = categories.filter((name: any) => {
        return (name.designation && name.designation.toLowerCase().includes(searchText.toLowerCase())) ||
        (name.value && name.value.toLowerCase().includes(searchText.toLowerCase()))
    });

    /**
     * Pagination
     */
    const indexOfLastPage = currentPage * catPerPage;
    const IndexOfFirstPage = indexOfLastPage - catPerPage;
    const currentcat: any = dataFilter.slice(IndexOfFirstPage, indexOfLastPage);

    const paginate = (numberPage: any)=> {
        setCurrentPage(numberPage)
    }

    const handleChange = (e: any) => {
        e.preventDefault();
        setSearchText(e.target.value);
    };

    return (
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-4">
                        <div className="d-md-flex justify-content-between">
                            <div className="input-group input-group-lg _inputForm w-25">
                                <input type="text"  value={searchText} onChange={handleChange} name="table_search" className="form-control float-right input-lg _inputSearch" placeholder="Search" />

                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-default">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>

                            <button className="btn btn-primary btn-lg float-left _btnAdd" onClick={handleShowAdd}>
                                <i className='fas fa-plus pr-2'></i>
                                Ajouter categorie
                            </button>

                            <Modal
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                show={showAdd}
                                onHide={handleCloseAdd}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Ajout devise</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form noValidate>

                                        <Form.Group controlId="validationCustomUsername" className='mb-4'>
                                            <Form.Label>Rang</Form.Label>
                                            <InputGroup hasValidation>
                                                
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Rang"
                                                    aria-describedby="inputGroupPrepend"
                                                    value={rang}
                                                    onChange={(e: any) => setRang(e.target.value)}
                                                    required
                                                />
                                                {/* <InputGroup.Text id="inputGroupPrepend">
                                                    <FaMailBulk/>
                                                </InputGroup.Text> */}
                                                <Form.Control.Feedback type="invalid">
                                                    Please choose a email adress.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                            
                                        <Form.Group controlId="validationCustomUsername" className='mb-4'>
                                            <Form.Label>Designation</Form.Label>
                                            <InputGroup hasValidation>
                                                
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Designation"
                                                    aria-describedby="inputGroupPrepend"
                                                    value={designation}
                                                    onChange={(e: any) => setDesignation(e.target.value)}
                                                    required
                                                />
                                                {/* <InputGroup.Text id="inputGroupPrepend">
                                                    <FaMailBulk/>
                                                </InputGroup.Text> */}
                                                <Form.Control.Feedback type="invalid">
                                                    Please choose a email adress.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group controlId="validationCustomUsername" className='mb-4'>
                                            <Form.Label>Valeur</Form.Label>
                                            <InputGroup hasValidation>
                                                
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Valeur"
                                                    aria-describedby="inputGroupPrepend"
                                                    value={valeur}
                                                    onChange={(e: any) => setValeur(e.target.value)}
                                                    required
                                                />
                                                {/* <InputGroup.Text id="inputGroupPrepend">
                                                    <FaMailBulk/>
                                                </InputGroup.Text> */}
                                                <Form.Control.Feedback type="invalid">
                                                    Please choose a email adress.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="button" onClick={handleCloseAdd} className="btn btn-danger">Annuler</button>
                                    <button type="button" onClick={handleShowComfirm} className="btn btn-primary">Enregistrer</button>
                                </Modal.Footer>
                            </Modal>
                            
                        </div>
                    </div>
                </div>
                {
                    loading ? (
                        <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0}/>
                    ):(
                        <>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        
                                        <div className="card-body table-responsive p-0">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">ID</th>
                                                        <th scope="col">Designation</th>
                                                        <th scope="col">Valeur</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                {
                                                currentcat ? (
                                                    <>
                                                        {
                                                            currentcat.filter((data: any) => data.status && data.status.designation ==="active").map((cat: any) => (
                                                                <tbody key={cat.id}>
                                                                    <tr>
                                                                        <th scope="row">{cat.id}</th>
                                                                        <td>{cat.designation}</td>
                                                                        <td>{cat.value}</td>
                                                                        <td>
                                                                            <div>
                                                                                <button onClick={()=>handleShowDel(cat)} type="button" className="btn btn-sm" data-placement="top" title="Supprimer">
                                                                                    <i className='fas fa-trash text-danger'></i>
                                                                                </button>
                                                                                <button type="button" onClick={()=>getCategory(cat.id)} className="btn btn-sm" data-placement="top" title="Modifier">
                                                                                    <i className='fas fa-edit text-success'></i>
                                                                                </button>
                                                                                {/* <button type="button" className="btn btn-sm" data-toggle="tooltip" data-placement="top" title="Consulter">
                                                                                    <i className='fas fa-eye text-primary'></i>
                                                                                </button> */}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    
                                                                </tbody>
                                                            ))
                                                        }
                                                    </>
                                                ):(
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-danger">
                                                                Vide
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                ) 
                                            }
                                            </table>
                                        </div>
                                        <div className="card-footer">
                                            <div className="float-right">
                                                <Paginations
                                                    postPerPage={catPerPage} 
                                                    totalPages={categories.length}
                                                    paginate={paginate}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showUpD}
                onHide={handleCloseUp}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Modification categorie</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        loadingDev ? (
                            <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0}/>
                        ):(
                            <Form noValidate>

                            <Form.Group controlId="validationCustomUsername" className='mb-4'>
                                <Form.Label>Rang</Form.Label>
                                <InputGroup hasValidation>
                                    
                                    <Form.Control
                                        type="number"
                                        placeholder="Rang"
                                        aria-describedby="inputGroupPrepend"
                                        value={rangUp}
                                        onChange={(e: any) => setRangUp(e.target.value)}
                                        required
                                    />
                                    {/* <InputGroup.Text id="inputGroupPrepend">
                                        <FaMailBulk/>
                                    </InputGroup.Text> */}
                                    <Form.Control.Feedback type="invalid">
                                        Please choose a email adress.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                                
                            <Form.Group controlId="validationCustomUsername" className='mb-4'>
                                <Form.Label>Designation</Form.Label>
                                <InputGroup hasValidation>
                                    
                                    <Form.Control
                                        type="text"
                                        placeholder="Designation"
                                        aria-describedby="inputGroupPrepend"
                                        value={designationUp}
                                        onChange={(e: any) => setDesignationUp(e.target.value)}
                                        required
                                    />
                                    {/* <InputGroup.Text id="inputGroupPrepend">
                                        <FaMailBulk/>
                                    </InputGroup.Text> */}
                                    <Form.Control.Feedback type="invalid">
                                        Please choose a email adress.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group controlId="validationCustomUsername" className='mb-4'>
                                <Form.Label>Valeur</Form.Label>
                                <InputGroup hasValidation>
                                    
                                    <Form.Control
                                        type="text"
                                        placeholder="Valeur"
                                        aria-describedby="inputGroupPrepend"
                                        value={valeurUp}
                                        onChange={(e: any) => setValeurUp(e.target.value)}
                                        required
                                    />
                                    {/* <InputGroup.Text id="inputGroupPrepend">
                                        <FaMailBulk/>
                                    </InputGroup.Text> */}
                                    <Form.Control.Feedback type="invalid">
                                        Please choose a email adress.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>


                        </Form>
                        )
                    }
                    
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={handleCloseUp} className="btn btn-danger">Annuler</button>
                    <button type="button" onClick={handleShowComfirmUp} className="btn btn-primary">Enregistrer</button>
                </Modal.Footer>
            </Modal>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showConfirmAdd}
                onHide={handleCloseComfirm}
            >
                <AddComfirmation actionOK={createCategory}/>
            </Modal>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showConfirmUp}
                onHide={handleCloseComfirmUp}
            >
                <AddComfirmation actionOK={updateCategory}/>
            </Modal>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showConfirmDel}
                onHide={handleCloseDel}
            >
                <DeleteComfirmation actionOK={(e: Event) => deleteCategory(e)}/>
            </Modal>
        </section>
    )
}

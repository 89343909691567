import React from 'react'
import { Form, InputGroup } from 'react-bootstrap';
import { FaKey, FaMailBulk } from 'react-icons/fa';
import { Link } from 'react-router-dom';

interface Props {
    validated: boolean,
    setValidated: any,
    handleSubmit: any,
    email: string | any,
    password: string | any,
    setEmail: any,
    setPassword: any
}

export const LoginComponent: React.FC<Props> = ({
    validated,
    setValidated,
    handleSubmit,
    email,
    password,
    setEmail,
    setPassword
})=> {
  return (
    <>
        <div className="login-box">
            <div className="login-logo">
                <Link to="../../index2.html"><b>RESA</b> ADMIN</Link>
            </div>
            <div className="card">
                <div className="card-body login-card-body">
                    <p className="login-box-msg">Authentification</p>

                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        
                        <Form.Group controlId="validationCustomUsername" className='mb-4'>
                            <Form.Label>Adresse email</Form.Label>
                            <InputGroup hasValidation>
                                
                                <Form.Control
                                    type="email"
                                    value={email}
                                    onChange={(e: any) => setEmail(e.target.value)}
                                    placeholder="Adresse email"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                />
                                <InputGroup.Text id="inputGroupPrepend">
                                    {/* <span className="fas fa-lock"></span> */}
                                    <FaMailBulk/>
                                </InputGroup.Text>
                                <Form.Control.Feedback type="invalid">
                                    Please choose a email adress.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="validationCustomUsername" className='mb-4'>
                            <Form.Label>Mot de passe</Form.Label>
                            <InputGroup hasValidation>
                                
                                <Form.Control
                                    type="password"
                                    value={password}
                                    onChange={(e: any) => setPassword(e.target.value)}
                                    placeholder="Mot de passe"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                />
                                <InputGroup.Text id="inputGroupPrepend">
                                    {/* <span className="fas fa-lock"></span> */}
                                    <FaKey/>
                                </InputGroup.Text>
                                <Form.Control.Feedback type="invalid">
                                    Please choose a email adress.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <div className="row">
                            <div className="col-6">
                                {/* <div className="icheck-primary">
                                    <input type="checkbox" id="remember" />
                                    <label htmlFor="remember">
                                        Remember Me
                                    </label>
                                </div> */}
                            </div>
                            <div className="col-6">
                                <button type="submit" className="btn btn-primary btn-block">Se connecter</button>
                            </div>
                        </div>
                    </Form>

                
                </div>
            </div>
        </div>
    </>
  )
}

import axios from 'axios'
import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { baseUrl } from '../../../../services/Services'
import { VilleType } from '../../../../utils/interface/LogementType'
interface Props {
    ville: VilleType | any,
    setVille: any,
    item: VilleType | any
}
export const UpdateVille: React.FC<Props> = ({
    ville,
    setVille,
    item
}) => {
    console.log(item);

    const updateVilleAction = async(e: any) => {
        e.preventDefault();
        await axios.put(`${baseUrl}api/ville/${item.id}`, {
            designation: ville
        })
        .then((response: any) => {
            console.log(response);
            window.location.reload();
        })
        .catch((error: any) => {
            console.log(error)
        });
    }
    return (
        <div>
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Modification ville</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form noValidate  >
                                
                            <Form.Group controlId="validationCustomUsername" className='mb-4'>
                                <Form.Label>Ville</Form.Label>
                                <InputGroup hasValidation>
                                    
                                    <Form.Control
                                        type="text"
                                        placeholder="Ville"
                                        aria-describedby="inputGroupPrepend"
                                        value={ville}
                                        onChange={(e)=>setVille(e.target.value)}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please choose a email adress.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        
                        </Form>
                    </div>
                    <div className="modal-footer justify-content-between">
                        <button type="button" className="btn btn-default" data-dismiss="modal">Fermer</button>
                        <button type="button" id="addBtn" onClick={(e) => updateVilleAction(e)}  className="btn btn-primary">Enregistrer</button>
                    </div>
                </div>
        </div>
    )
}

import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ContactComponents } from '../../Components/ContactComponent/ContactComponents'
import { ContactContext } from '../../Context/AppContext';
import { baseUrl } from '../../services/Services';
import { Authorization } from '../../utils/headers/Authorization';

export default function ContactPages() {

    const [contacts, setContacts] = useState<[] | any>([]);
    const [loading, setLoading] = useState<boolean | any>(false);

    useEffect(() => {
        setLoading(true);
        const getReservations = async() => await axios.get(`${baseUrl}api/proprietaire`, {headers: Authorization})
        .then((response: any) => {

            setLoading(false);
            setContacts(response.data.data.rows);

        }).catch((error: any) => {

        setLoading(false);
        console.log(error);

        });
        getReservations()
    }, []);

    console.log(contacts)
    return (
        <ContactContext.Provider value={contacts}>
            <ContactComponents/>
        </ContactContext.Provider>
    )
}

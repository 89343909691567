import React from 'react'
import { DeviseComponent } from '../../Components/DeviseComponent/DeviseComponent'

export default function DevisePage() {
  return (
    <div>
      <DeviseComponent/>
    </div>
  )
}

import React from 'react'
import { UserType } from '../../utils/interface/UserTytpe'
import { ListUsers } from './Components/ListUsers/ListUsers'
interface Props {
    users: UserType
    loading: boolean | any,
    paginate: any,
    createUser: any,
    totalPage: any
    setUserLists: any,
    searchText: string | any,
    handleChange: any,
    setUserPerPage: any,
    userPerPage: any,
    setEmail: any,
    setPassword: any,
    setNom: any,
    setPrenom: any,
    setAdresse: any,
    setTelephone: any,
    changeStatus : any,
    password: UserType | any
}
export const UserComponent: React.FC<Props> = ({
    users,
    loading,
    paginate,
    createUser,
    totalPage,
    setUserLists,
    searchText,
    handleChange,
    setUserPerPage,
    userPerPage,
    setEmail,
    setPassword,
    setNom,
    setPrenom,
    setAdresse,
    setTelephone,
    changeStatus,
    password
}) => {
  return (
    <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Utilisateurs</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="index.html">Utilisateurs</a></li>
                            <li className="breadcrumb-item active">Utilisateurs v1</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <ListUsers
              users={users}
              loading={loading}
              paginate={paginate}
              createUser={createUser}
              totalPage={users}
              setUserLists={setUserLists}
              searchText={searchText}
              handleChange={handleChange}
              setUserPerPage={setUserPerPage}
              userPerPage={userPerPage} 
              setEmail={setEmail} 
              setPassword={setPassword} 
              setNom={setNom} 
              setPrenom={setPrenom}
              password={password}
              setAdresse={setAdresse} 
              setTelephone={setTelephone}    
              changeStatus={changeStatus}    />
    </div>
  )
}

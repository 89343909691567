import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import { baseUrl } from '../../services/Services';
import { Authorization } from '../../utils/headers/Authorization';
import { ListDevise } from './Components/ListDevise';
import { ListParametraGeFrais } from './Components/ListParametraGeFrais';

export const DeviseComponent: React.FC = () => {

    const [devise, setDevise] = useState<any>([]);

    const [fraisService, setFraisService] = useState<any>([]);


    const [loading, setLoading] = useState<boolean>(false);

    const [key, setKey] = useState('devise');

    

    useEffect(()=> {
        setLoading(true);

        const getAllDevise = async() => axios.get(`${baseUrl}api/devise`, {headers: Authorization}).then((response: any) => {

            setLoading(false);

            setDevise(response.data.data.rows);
            

        }).catch((error: any) => {

            setLoading(false);

        });

        const getAllFrais = async() => axios.get(`${baseUrl}api/frais-service`).then((response: any) => {

            setLoading(false);

            setFraisService(response.data.data.rows);
            

        }).catch((error: any) => {

            setLoading(false);

        });

        getAllDevise();
        getAllFrais();

        // const interval = setInterval(()=>{
        //     getAllDevise();
        //     getAllFrais();
        // },500)
       
       
        // return()=>clearInterval(interval)
    }, []);

    return (
        <div className="content-wrapper">
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0"></h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="index.html">Devise</a></li>
                                <li className="breadcrumb-item active">Devise v1</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="pl-2 pr-2">
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k: any) => setKey(k)}
                    className="mb-3"
                    >
                    <Tab eventKey="devise" title="Devise">
                        <ListDevise devise = {devise} setLoading={setLoading} loading = {loading}/>
                    </Tab>
                    <Tab eventKey="frais" title="Frais de service">
                        <ListParametraGeFrais setLoading={setLoading} loading = {loading} data={fraisService} />
                    </Tab>
                    
                </Tabs>
                </div>
            </div>
            
        </div>
    )
}

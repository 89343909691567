import moment from 'moment';
import React, { useContext } from 'react'
import { imgPath } from '../../../../services/Services';
import { EquipementType } from '../../../../utils/interface/EquipementType';
import { LogementType } from '../../../../utils/interface/LogementType';
interface Props {
    logement: LogementType | any,
}
export const LogeDetailCompo: React.FC<Props> = ({logement})=> {
    

    let dn = moment(logement && logement.createdAt).format('YYYY-MM-DD, h:mm:ss');
    console.log(dn)
    
    // let ye = dn.getFullYear();
    // let mo = dn.getMonth();
    // let da = dn.getDay();
    // console.log(`${da}-${mo}-${ye}`);


    
    let equipementItem = logement.equipements && logement.equipements.map((data: any, i: any) => {
        return (
            <li key={i}>{data && data.designation}</li>
        )
    })
    
    return (
        <section className="content">
            <div className="container-fluid">
                <div className='row'>
                    <div className="col-12 col-md-6">
                        <div className="card"   style={{height: "83vh"}}>
                            <div className="card-header">
                                <h4>Details de logement <b>{logement && logement.id}</b></h4>
                            </div>
                            <div className="card-body table-responsive p-2">
                                
                                <table style={{width:"100%", border: "1px solid black", borderCollapse: "collapse"}}>
                                    <tr>
                                        <th style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>ID:</th>
                                        <td style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>{logement.id}</td>
                                    </tr>
                                    <tr>
                                        <th style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>Adresse:</th>
                                        <td style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>{logement.adresse}</td>
                                    </tr>

                                    <tr>
                                        <th style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>Quartier:</th>
                                        <td style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>{logement.quartier ? logement.quartier.designation : '_'}</td>
                                    </tr>
                                    <tr>
                                        <th style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>Nombre de piece:</th>
                                        <td style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>{logement.nombrePieceTotal ? logement.nombrePieceTotal : '0'} {logement.nombrePieceTotal > 1 ? 'pièces' : 'pièce'}</td>
                                    </tr>
                                    <tr>
                                        <th style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>Nombre de personne max:</th>
                                        <td style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>{logement.nombrePersonneMax ? logement.nombrePersonneMax : "0"} {logement.nombrePersonneMax > 1 ? 'personnes' : 'personne'}</td>
                                    </tr>
                                    <tr>
                                        <th style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>Designation:</th>
                                        <td style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>{logement.designation}</td>
                                    </tr>
                                    <tr>
                                        <th style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>Description:</th>
                                        <td style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>{logement.description}</td>
                                    </tr>

                                    <tr>
                                        <th style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>Equipements:</th>
                                        <td style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>
                                            <ul style={{margin: "0px"}}>
                                                {equipementItem}
                                            </ul>
                                        </td>
                                    </tr>
                                    

                                    <tr>
                                        <th style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>Date de creation:</th>
                                        <td style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>{moment(logement && logement.createdAt).format('YYYY-MM-DD à hh:mm:ss')}</td>
                                    </tr>
                                    <tr>
                                        <th style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>Status:</th>
                                        <td style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>{logement.status ? logement.status.designation : '_'}</td>
                                    </tr>
                                </table>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className='w-auto'>
                            <div className="card"   style={{height: "83vh"}}>
                                <div className="card-header">
                                    <h4>Les images du logement</h4>
                                </div>
                                <div className="card-body">
                                    <div className='imgBox_ mb-4'>
                                    {
                                        logement.files && logement.files.length > 0 ? (
                                            logement.files.map((img: any) => {
                                                return(
    
                                                    <figure  key={img.id}>
                                                        <img className='_imgS' style={{margin: "0.5rem"}} src={`${imgPath}${img.path}`} alt={img.name} />
                                                        <figcaption>{img.name}</figcaption>
                                                    </figure>
                                                    
                                                        
                                                    
                                                )
                                            })
                                        ):(
                                            <div>
                                                <h4>Pas d'image</h4>
                                            </div>
                                        )
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

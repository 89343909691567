import moment from 'moment'
import React from 'react'
interface Props {
    itemRes: any
}
export const DetailRes: React.FC<Props> = ({ itemRes }) => {
    
    console.log("ITEM", itemRes)
    return (
        <section className="content">
            <div className="container-fluid">
                <div className='row'>
                    <div className="col-12 col-md-12">
                        <div className="card"   style={{height: "83vh"}}>
                            <div className="card-header">
                                <h4>Détails de la reservation <b>{itemRes && itemRes.reference} | Paiement: { itemRes.paiement && itemRes.paiement.montant } Ariary</b></h4>
                            </div>
                            <div className="card-body table-responsive p-2">
                                <ul>
                                    <li>Date de la réservation: <strong>{moment(itemRes && itemRes.date).format('DD-MM-YYYY à h:mm:ss')}</strong> </li>
                                    <li>Date debut: <strong>{moment(itemRes.reservationLogement && itemRes.reservationLogement.dateDebut).format('DD-MM-YYYY à h:mm:ss')}</strong></li>
                                    <li>Date fin: <strong>{ moment(itemRes.reservationLogement && itemRes.reservationLogement.dateFin).format('DD-MM-YYYY à h:mm:ss')}</strong></li>
                                </ul>
                                <hr />
                                <div>
                                    <h3>Client</h3>
                                    <ul>
                                        <li>Nom et prénom: <strong>{ itemRes.user && itemRes.user.nom } { itemRes.user && itemRes.user.prenom }</strong></li>
                                        <li>Adresse email: <strong>{ itemRes.user && itemRes.user.email }</strong></li>
                                        <li>Adresse: <strong>{ itemRes.user && itemRes.user.adresse }</strong></li>
                                        <li>Téléphone: <strong>{itemRes.user && itemRes.user.telephone ? itemRes.user.telephone : "_"}</strong></li>
                                        <li>Nombre de voyageurs: <strong>{ itemRes.reservationLogement && itemRes.reservationLogement.nombreVoyageur ? itemRes.reservationLogement.nombreVoyageur + " personnes" : "_" }</strong></li>
                                    </ul>
                                </div>
                                <hr />
                                <div>
                                    <h3>Logement <b>{itemRes.reservationLogement && itemRes.reservationLogement.logement.reference}</b></h3>
                                    <table style={{width:"100%", border: "1px solid black", borderCollapse: "collapse"}}>
                                        
                                        <tr>
                                            <th style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>Adresse:</th>
                                            <td style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>{itemRes.reservationLogement && itemRes.reservationLogement.logement.adresse}</td>
                                        </tr>

                                        <tr>
                                            <th style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>Proprietaire:</th>
                                            <td style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>{itemRes.reservationLogement && itemRes.reservationLogement.logement.nom}</td>
                                        </tr>

                                        <tr>
                                            <th style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>Contact du proprietaire:</th>
                                            <td style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>{itemRes.reservationLogement && itemRes.reservationLogement.logement.contact}</td>
                                        </tr>
                                        <tr>
                                            <th style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>Loyer par nuit:</th>
                                            <td style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>{itemRes.reservationLogement && itemRes.reservationLogement.logement.loyer}</td>
                                        </tr>
                                        <tr>
                                            <th style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>Personne maximum:</th>
                                            <td style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>{itemRes.reservationLogement && itemRes.reservationLogement.logement.nombrePersonneMax} personnes</td>
                                        </tr>
                                        <tr>
                                            <th style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>Nombre piece:</th>
                                            <td style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>{itemRes.reservationLogement && itemRes.reservationLogement.logement.nombrePieceTotal} pièces</td>
                                        </tr>

                                        <tr>
                                            <th style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>Designation:</th>
                                            <td style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>{itemRes.reservationLogement && itemRes.reservationLogement.logement.designation}</td>
                                        </tr>

                                        <tr>
                                            <th style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>Description:</th>
                                            <td style={{padding: "5px", textAlign: "left", border: "1px solid black"}}>{itemRes.reservationLogement && itemRes.reservationLogement.logement.description}</td>
                                        </tr>
                                        
                                        
                                    </table>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    )
}

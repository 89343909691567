import React, { useContext, useState } from 'react';
import { UserContext } from '../../../../Context/AppContext';
import { UserType } from '../../../../utils/interface/UserTytpe';
import { DeleteComfirmation } from '../../../DeleteComfirmation/DeleteComfirmation';
import { AddUser } from '../AddUser/AddUser';
import ReactLoading from 'react-loading';
import { Paginations } from '../../../pagination/Paginations';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { ValueStatus } from '../../../../utils/utils';
import axios from 'axios';
// import './index.css';
import { baseUrl } from '../../../../services/Services';
import { ApiUrl } from '../../../../utils/apiUrl';
import { Authorization } from '../../../../utils/headers/Authorization';
interface Props {
    users: UserType
    loading: boolean | any,
    paginate: any,
    createUser: any,
    totalPage: any
    setUserLists: any,
    searchText: string | any,
    handleChange: any,
    setUserPerPage: any,
    userPerPage: any,
    setEmail: any,
    setPassword: any,
    setNom: any,
    setPrenom: any,
    setAdresse: any,
    setTelephone: any,
    password: any,
    changeStatus : any

}
export const ListUsers: React.FC<Props> = ({
    users,
    loading,
    paginate,
    createUser,
    totalPage,
    setUserLists,
    searchText,
    handleChange,
    setUserPerPage,
    userPerPage,
    setEmail,
    setPassword,
    setNom,
    setPrenom,
    password,
    setAdresse,
    setTelephone,
    changeStatus,
}) => {
    const userLists = useContext(UserContext);

    console.log(userLists)

    // const changeStatus = (id: number, checked: boolean) => {

    //     let status: number = 0;

    //     checked ? status = ValueStatus.active : status = ValueStatus.desactive
    //     // alert(status)

    //     axios.put(`${baseUrl}${ApiUrl.user}/${id}`, {

    //         status: status
    //     }, {
    //         headers: Authorization
    //     }).then((response) => {
    //         console.log(response);
    //     });

    // }
    return (
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-4">
                        <div className="d-md-flex justify-content-between">
                            <div className="input-group input-group-lg _inputForm w-25">
                                <input type="text" value={searchText} onChange={handleChange} name="table_search" className="form-control float-right input-lg _inputSearch" placeholder="Search" />

                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-default">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                            <button className="btn btn-primary btn-lg float-left _btnAdd" data-toggle="modal" data-target="#modal-lg">
                                <i className='fas fa-plus pr-2'></i>
                                Ajouter utilisateur
                            </button>
                            <div className="modal fade" id="modal-lg">
                                <div className="modal-dialog modal-lg">
                                    <AddUser email={users.email} password={password} nom={users.nom} prenom={users.prenom} adresse={users.adresse} telephone={users.telephone} setEmail={setEmail} setPassword={setPassword} setNom={setNom} setPrenom={setPrenom} setAdresse={setAdresse} setTelephone={setTelephone} createUser={createUser} />
                                </div>
                            </div>
                            {/* <div className="modal fade" id="modal-del">
                                <div className="modal-dialog modal-del">
                                    <DeleteComfirmation />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                {
                    loading ? (
                        <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0} />
                    ) : (
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="d-flex col-md-6">
                                            <label htmlFor="">Afficher par</label>
                                            <select id="inputState" onChange={(e) => setUserPerPage(e.target.value)} className="form-control w-auto ml-2">
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="75">75</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="card-body table-responsive p-0" style={{ height: "63vh" }}>
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Nom et prénoms</th>
                                                    <th scope="col">Adresse email</th>
                                                    <th scope="col">Adresse</th>
                                                    <th scope="col">Téléphone</th>
                                                    <th scope="col">Role</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userLists ? (
                                                    <>
                                                        {
                                                            userLists.map((user: UserType) => (

                                                                <tr key={user.id}>
                                                                    <th scope="row">{user.id}</th>
                                                                    <td>{user.nom} {user.prenom}</td>
                                                                    <td>{user.email}</td>
                                                                    <td>{user.adresse}</td>
                                                                    <td>{user.telephone}</td>
                                                                    <td>{user.role.designation}</td>
                                                                    <td>
                                                                        <BootstrapSwitchButton
                                                                            checked={user.status.id === ValueStatus.active ? true : false}
                                                                            onlabel='Activé'
                                                                            offlabel='Désactivé'
                                                                            onstyle="success"
                                                                            size="xs"
                                                                            width={80}
                                                                            onChange={(checked: boolean) => changeStatus(user.id, checked)}
                                                                        /></td>
                                                                    <td>
                                                                        <div>
                                                                            <button type="button" data-toggle="modal" data-target="#modal-lg" className={user.status.id === ValueStatus.desactive ? 'btn btn-sm event-none' : 'btn btn-sm'} data-placement="top" title="Modifier">
                                                                                <i className='fas fa-edit text-success'></i>
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>


                                                            ))
                                                        }
                                                    </>
                                                ) : (
                                                    <tr>
                                                        <td className="text-danger">
                                                            Vide
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>

                                        </table>
                                    </div>
                                    <div className='card-footer'>
                                        <div className="float-right">
                                            <Paginations
                                                postPerPage={userPerPage}
                                                totalPages={totalPage.length}
                                                paginate={paginate}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </section>
    )
}
